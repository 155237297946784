.cd-gift-form {
  padding: 15px 0 0;
  .right-form-group {
    textarea {
      height: 87px;
    }

    @include MQ(L) {
      text-align: right;
    }
  }
}