
body.summary {
  .jumbotron-summary {
    color: getColor(txt, lightest);
    position: relative;
    background: {
      size: cover;
      position: bottom center;
    }
    @include MQ(mS) {
      padding: 20px 0;
    }
    background-image: url('#{$assets_path}/img/bg/reservation/xl.jpg');
    @include MQ(mL) {
      background-image: url('#{$assets_path}/img/bg/reservation/l.jpg');
    }
    @include MQ(mM) {
      background-image: url('#{$assets_path}/img/bg/reservation/m.jpg');
    }
    @include MQ(mS) {
      background-image: url('#{$assets_path}/img/bg/reservation/s.jpg');
    }
  }
  #actions {
    padding: 0;
    font-weight: 300;
    #contacts {
      background: getColor(danger, base);
      padding: 20px 20px;
      height: 310px;
      h3 {
        color: getColor(txt, lightest);
        padding-bottom: 10px;
      }
      h4 {
        font-size: 16px;
        color: getColor(txt, lightest);
      }
      .group {
        padding: 5px 0;
      }
      a {
        color: getColor(txt, lightest);
        font-size: 17px;
        padding-left: 1px;
        display: inline-block;
        @include MQ(Lm) {
          font-size: 15px;
        }
        &.phone {
          padding-left: 45px;
        }
      }
      i {
        font-size: 24px;
        vertical-align: middle;
        margin-right: 5px;
        color: getColor(txt, lightest);
        width: 39px;
        display: inline-block;
        text-align: center;
      }
    }
    #reminder {
      background: getColor(info, medium);
      padding: 20px 40px;
      height: 310px;
      h3 {
        color: getColor(info, darker);
        padding-bottom: 10px;
      }
      h4 {
        font-size: 16px;
        color: getColor(info, darker);
      }
      a {
        display: block;
        color: getColor(txt, lightest);
        background: getColor(info, base);
        text-align: center;
        padding: 20px 10px;
        width: 45%;
        float: left;
        margin-top: 40px;
        font-size: 16px;
        height: 100px;
        @include MQ(S) {
          height: 80px;
        }
        @include MQ(M) {
          height: auto;
        }
        @include MQ(L) {
          width: auto;
          padding: 20px 15px;
          height: auto;
        }
        @include MQ(XL) {
          width: 45%;
        }
        &:hover {
          background: getColor(info, hover);
        }
        &#sendMail {
          margin-left: 10px;
        }
      }
    }
  }

}

body.summary, body.vacation-summary {

  .container {
    padding: 0;
  }


  h1 {
    font: {
      size: 53px;
      weight: 300;
    }
    padding-bottom: 10px;
    color: getColor(txt, lightest);
  }
  h2 {
    font: {
      size: 37px;
      weight: 300;
    }
  }
  .h3, .h4,
  h3, h4 {

    color: getColor(info, darker);
    padding: 0;
  }
  h4 {
    color: getColor(neutral, search);
    font: {
      size: 16px;
      weight: 300;
    }
  }

  h5 {
    color: darken(getColor(neutral, medium), 5%);
    padding-left: 10px;
    padding-bottom: 10px;
    font: {
      size: 18px;
      weight: 400;
    }
  }

  > section {
    background: getColor(bg, light);
  }

  table {
    .nowrap {
      white-space: nowrap;
    }
    .tag {
      font: {
        size: 14px;
        weight: 400;
      }
      text-transform: none;
      padding: 3px 10px;
      margin-right: 10px;
      color: getColor(txt, lightest);

      &.checkin {
        background: darken(getColor(success, base), 27%);
      }
      &.person {
        background: getColor(danger, base);
      }
      &.online {
        background: getColor(info, base);
      }
    }
  }
  .tooltip {
    .tooltip-inner {
      background: lighten(getColor(info, regular), 26%);
      color: darken(getColor(neutral, medium), 5%);
    }
    .tooltip-arrow {
      border-right-color: lighten(getColor(info, regular), 26%);
    }
  }
  .cd-process-steps {
    margin-bottom: 25px !important;
  }

  #extra-info {
    cursor: pointer;
  }

  .question {
    color: lighten(getColor(info, regular), 22%);
    cursor: pointer;
  }

  .table {
    font: {
      weight: 400;
    }
  }
  tr {
    &:last-child {
      td {
        &.label, &.price, &.description { /* &.cancellation,  */
          border-bottom: none;
        }
      }
    }
  }
  td {
    display: table-cell;
    text-align: left;
    border-top: none;
    font-size: 16px;
    padding: 10px 20px;
    overflow: hidden;
    &.label {
      background: getColor(success, light);
      vertical-align: middle;
      color: getColor(info, darker);
      padding-left: 50px;
      border-bottom: 5px solid getColor(neutral, bg);
      h5 {
        font-size: 22px;
        padding-left: 0;
        padding-bottom: 5px;
        padding-top: 12px;
      }
      h6 {
        font: {
          size: 18px;
          weight: 300;
        }
        color: getColor(info, darker);
        margin: 5px 0;
      }
    }
    &.price {
      background: getColor(bg, light);
      vertical-align: middle;
      color: getColor(neutral, bgDark);
      text-align: center;
      padding: 10px 40px;
      border-bottom: 5px solid getColor(neutral, bg);
      &.empty {
        background: getColor(success, light);
      }
    }
    &.description {
      background: getColor(warning, base);
      vertical-align: middle;
      color: getColor(txt, light);
      padding-left: 40px;
      border-bottom: 5px solid getColor(neutral, bg);
    }
    &.date {
      .circle {
        width: 68px;
        height: 68px;
        background: getColor(success, base);
        border-radius: 60px;
        text-align: center;
        position: absolute;
        margin-left: -10px;
        margin-top: 45px;
        span {
          &.until {
            position: relative;
            top: 50%;
            transform: translateY(-50%);
            color: getColor(txt, lightest);
            font: {
              size: 12px;
              weight: 300;
            }
            display: block;
            margin-top: -10px;
          }
          &.day {
            position: relative;
            @include center();
            font: {
              size: 16px;
              weight: 600;
            }
            display: block;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 1200px) {
    .cd-process-steps {
      margin-bottom: 25px !important;
    }
  }

  @media only screen and (max-width: 992px) {
    #actions #contacts a.phone {
      padding-left: 7px;
    }
    #actions {
      h3, h4 {
        text-align: center;
      }
      #reminder a {
        font-size: 15px;
        vertical-align: middle;
      }
      #contacts {
        height: auto;
        .group {
          display: block;
          clear: both;
          width: 50%;
          margin: 0 auto;
          text-align: center;
          i, a {
            display: block;
            clear: both;
          }
          i {
            @include MQ(mL) {
              margin: 0 auto;
            }
          }
        }
      }
    }
  }
  .plus-accordion {
    .panel-title {
      a {
        padding-right: 15px;
      }
    }
  }
}

#boat-reserve {
  padding-top: 0;
  .important {
    color: black;
  }
  > .container {
    overflow: hidden;
    background: transparent;
    position: relative;
    padding: 0;
    > .row {
      @include MQ(L) {
        display: flex;
        margin: 0;
      }
      .vacation, .boat {
        @include MQ(L) {
          flex: 1;
          margin: 0;
          padding: 0 0 15px;
        }
        background: getColor(bg, light);
        overflow: hidden;
        font-size: 18px;

        .h3, h3 {
          padding: 20px 0 20px 15px;
          margin: 0 0 15px 0;
          color: getColor(info, darker);
        }
        > .row {
          padding: 0 15px;
        }
      }

      > div:last-child {
        border-left: 1px solid getColor(neutral, bg);
        background: lighten($colorInfo, 48%);
        margin-right: -1px;
        h3 {
          border-bottom: 1px solid rgba(black, .1);
        }
      }
      > div:first-child {
        h3 {
          border-bottom: 1px solid getColor(neutral, bg);
        }
      }

    }
  }
  aside {
    color: getColor(neutral, lighter);
  }

  picture, img {
    width: 100%;
  }

  dl {
    padding-top: 10px;
    font: {
      size: 18px;
      weight: 300;
    }
  }
  dt {
    color: getColor(neutral, lighter);
    font-weight: 400;
  }
  dd {
    color: darken(getColor(neutral, medium), 5%);
    padding-bottom: 10px;
  }
}

#quotation {
  .box {
    background: getColor(neutral, bgLight);
    color: getColor(neutral, medium);
    padding: 10px 15px;
    .h4, h4, .h5, h5 {
      padding: 0 0 10px;
      font: {
        size: 22px;
        weight: 400;
      }
      color: getColor(info, dark);
    }
    p {
      margin-bottom: 20px;
      &.help-inline {
        border-bottom: 0;
        padding-bottom: 0;
        margin: 5px 0;
      }
    }

    .total {
      white-space: nowrap;
    }

    @media only screen and (max-width: 1200px) {
      .table td {
        font-size: 18px;
      }
      .table tr.coupon td, .table tr.coupon input {
        font-size: 13px;
      }
    }

    @media only screen and (max-width: 992px) {
      .table tr.coupon td, .table tr.coupon input {
        font-size: 14px;
      }
    }
  }
}
