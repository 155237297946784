body.wizard {
  &.success {
    background: getColor(neutral, bg);
  }
  section {
    &.jumbotron-success {
      min-height: 760px;
      height: auto;
      padding: 156px 0 70px;
      text-align: center;
      position: relative;
      color: getColor(txt, lightest);
      background: {
        size: cover;
        position: bottom center;
        attachment: scroll;
      }
      &:not(.imgix-fluid) {
        background-image: url('#{$assets_path}/img/bg/wizard/success.jpg');
      }
      > .container {
        position: relative;
      }
    }
    &.padded-top-lg {
      @include MQ(M) {
        padding-top: 84px;
      }
    }
    &#success-form {
      padding-bottom: 60px;
      @include MQ(mM) {
        padding-bottom: 40px;
      }
    }
    &.jumbotron {
      height: auto;
      padding: 156px 0 70px;
      text-align: center;
      position: relative;
      color: getColor(txt, lightest);
      background: {
        size: cover;
        position: bottom center;
        attachment: scroll;
      }
      background-image: url('#{$assets_path}/img/bg/wizard/bg-xl.jpg');
      @include MQ(mL) {
        background-image: url('#{$assets_path}/img/bg/wizard/bg-l.jpg');
      }
      @include MQ(mM) {
        background-image: url('#{$assets_path}/img/bg/wizard/bg-m.jpg');
      }
      @include MQ(mS) {
        background-image: url('#{$assets_path}/img/bg/wizard/bg-s.jpg');
      }
      > .container {
        position: relative;
      }
      .title-container {
        margin: 0 auto 80px !important;
      }
      .btn-go-on {
        border-color: getColor(info, base);
        padding: 13px 20px 11px;
        font-weight: 400;
        background: rgba(black, .3);
        &:hover {
          background: white;
          color: getColor(info, light);
          text-shadow: none;
        }
        .icon {
          i {
            margin: 0;
            line-height: 50px;
            font-size: 35px;
          }
          color: getColor(info, light);
          width: 50px;
          height: 50px;
          border: 1px solid getColor(info, light);
        }
      }
    }
    &#tabs-menu {
      @include MQ(M) {
        height: 84px;
      }
    }
    &#tabs {
      background: white;
      margin-top: -84px;
      &.affix {
        position: relative;
        @include MQ(L) {
          position: fixed;
          margin-top: 0;
          top: 0;
          left: 0;
          width: 100%;
          z-index: 1000;
        }
      }
      @include MQ(mM) {
        margin-top: 0;
      }
      ul {
        background: getColor(bg, light);
      }
      li {
        cursor: pointer;
        @include MQ(M) {
          border-left: 1px solid getColor(neutral, base);
        }
        a {
          border: 0;
          border-radius: 0;
          background: transparent;
          background-color: transparent;
        }
        i {
          line-height: 67px;
          font-size: 48px;
          color: getColor(info, base);
          float: left;
          width: 30%;
          @include MQ(mL) {
            font-size: 38px;
            line-height: 52px;
            padding-right: 5px;
          }
          @include MQ(mM) {
            width: 15%;
          }
        }
        .txt {
          white-space: nowrap;
          text-align: left;
          display: inline-block;
          vertical-align: middle;
          width: 70%;
          @include MQ(mM) {
            width: 85%;
          }
          .hdr {
            color: getColor(neutral, base);
            margin: 5px 0 0;
            font: {
              size: 18px;
              weight: 400;
            }
          }
          .ftr {
            margin: -5px 0 0;
            color: getColor(info, darker);
            font: {
              size: 28px;
              weight: 200;
            }
            @include MQ(mL) {
              font-size: 19px;
            }
          }
        }

        &:hover {
          background: getColor(neutral, bg);
        }
        &:last-child {
          @include MQ(M) {
            border-right: 1px solid getColor(neutral, base);
          }
        }
        &.active { // li
          background: getColor(info, base);
          &:hover {
            background: getColor(info, dark);
          }
          i, .ftr {
            color: getColor(txt, lightest);
          }
          .hdr {
            color: getColor(neutral, bgDark);
          }
        }
      }
    }
    .sub-header {
      min-height: 448px;
      height: 448px;
      position: relative;
      text-align: center;
      display: table;
      padding-top: 30px;
      width: 100%;
      background: {
        size: cover;
        position: bottom center;
        attachment: scroll;
      }
      .container.with-titles {
        display: table-cell;
        vertical-align: middle;
        width: 100%;
      }
    }
    &.trip-details {
      .sub-header {
        background-image: url('#{$assets_path}/img/bg/wizard/map-xl.jpg');
        @include MQ(mL) {
          background-image: url('#{$assets_path}/img/bg/wizard/map-l.jpg');
        }
        @include MQ(mM) {
          background-image: url('#{$assets_path}/img/bg/wizard/map-m.jpg');
        }
        @include MQ(mS) {
          background-image: url('#{$assets_path}/img/bg/wizard/map-s.jpg');
        }
      }
    }
    #calendar {
      background: white;
      margin: 0 0 15px;
      border: 1px solid getColor(neutral, base);
      font-size: 18px;
    }
    .result {
      cursor: default;
      &:hover {
        background: getColor(info, base) !important;
        box-shadow: none !important;
      }
    }
    .change-date {
      @include MQ(mM) {
        display: block;
      }
      &:hover {
        background: transparent !important;
        box-shadow: none !important;
      }
    }
    &.contacts {
      .sub-header {
        background-image: url('#{$assets_path}/img/bg/wizard/contacts-xl.jpg');
        @include MQ(mL) {
          background-image: url('#{$assets_path}/img/bg/wizard/contacts-l.jpg');
        }
        @include MQ(mM) {
          background-image: url('#{$assets_path}/img/bg/wizard/contacts-m.jpg');
        }
        @include MQ(mS) {
          background-image: url('#{$assets_path}/img/bg/wizard/contacts-s.jpg');
        }
      }
    }
    &.manager {
      .items {
        a {
          display: block;
          height: 315px;
          position: relative;
          margin: 0 0 25px;
          overflow: hidden;
          font-size: 22px;
          line-height: 60px;
          @include MQ(mL) {
            height: 100px;
          }
          * {
            transition: .1s;
          }
          &.active {
            @include MQ(mL) {
              background: getColor(info, base);
              .ftr {
                background: transparent;
                span {
                  color: getColor(txt, lightest);
                }
              }
            }
            .hvr {
              bottom: 0;
            }
            .hdr {
              position: relative;
              &:before {
                content: '';
                position: absolute;
                opacity: .8;
                left: 0;
                z-index: 10;
                width: 100%;
                height: 100%;
                background: getColor(info, base);
              }
              span {
                text-align: center;
                position: absolute;
                display: block;
                width: 100%;
                left: 0;
                color: white;
                z-index: 15;
                top: 40%;
                @include MQ(mL) {
                  top: 30%;
                }
                i {
                  margin: 0 auto;
                  display: block;
                  font-size: 48px;
                }
              }
            }
          }
          &:hover {
            @include MQ(L) {
              .hvr {
                bottom: 0;
              }
            }
          }
          .hdr {
            height: 252px;
            position: relative;
            z-index: 10;
            @include MQ(mL) {
              height: 100px;
              width: 100px;
              overflow: hidden;
              position: relative;
              display: inline-block;
              vertical-align: middle;
              float: left;
            }
            img {
              position: absolute;
              top: 0;
              left: 0;
              min-width: 100%;
              min-height: 100%;
              max-height: 100%;
              @include MQ(mM) {
                min-width: 0%;
                max-height: 100%;
                height: 100%;
              }
            }
          }
          .ftr {
            height: 65px;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            background: white;
            z-index: 11;
            @include MQ(mL) {
              position: relative;
              display: inline-block;
              vertical-align: middle;
              width: calc(100% - 100px);
              float: left;
              height: 100px;
              line-height: 35px;
            }
            span {
              padding: 16px 0 0 15px;
              color: getColor(txt, darkest);
              max-width: 50%;
              white-space: nowrap;
              text-overflow: ellipsis;
              @include MQ(mL) {
                display: block;
                max-width: 100%;
              }
            }
            .flags {
              float: right;
              margin-right: 10px;
              @include MQ(mL) {
                margin: 0 0 0 15px;
                float: none;
              }
              img {
                margin-right: 5px;
              }
            }
          }
          .hvr {
            height: 65px;
            position: absolute;
            bottom: -65px;
            left: 0;
            width: 100%;
            z-index: 12;
            background: getColor(info, base);
            color: getColor(txt, lightest);
            text-align: center;
            @include MQ(mL) {
              display: none;
            }
          }
          &.not-sure {
            .hdr {
              width: 100%;
              display: table;
              text-align: center;
              background: rgba(black, .07);
              @include MQ(mL) {
                display: inline-block;
                float: left;
                width: 100px;
                text-align: center;
              }
              i {
                display: table-cell;
                vertical-align: middle;
                font-size: 88px;
                @include MQ(mL) {
                  font-size: 70px;
                  display: block;
                  margin: 15px auto 0;
                }
              }
            }
            .hvr {
              display: none;
            }
            .ftr {
              text-align: center;
              @include MQ(mL) {
                display: inline-block;
                float: left;
                width: calc(100% - 100px);
                text-align: left;
              }
              span {
                padding: 0;
                @include MQ(mL) {
                  line-height: 100px;
                  padding: 0 0 0 15px;
                }
              }
            }
            &.active {
              * {
                color: getColor(txt, lightest);
                background: getColor(info, base) !important;
              }
              @include MQ(L) {
                .ftr {
                  display: table;
                  width: 100%;
                  span {
                    display: table-cell;
                  }
                }
              }
            }
          }
        }
      }
    }
    &.boat-types {
      .sub-header {
        background-image: url('#{$assets_path}/img/bg/wizard/boat-xl.jpg');
        @include MQ(mL) {
          background-image: url('#{$assets_path}/img/bg/wizard/boat-l.jpg');
        }
        @include MQ(mM) {
          background-image: url('#{$assets_path}/img/bg/wizard/boat-m.jpg');
        }
        @include MQ(mS) {
          background-image: url('#{$assets_path}/img/bg/wizard/boat-s.jpg');
        }
      }
    }
    &:last-child {
      @include MQ(mS) {
        padding-bottom: 30px;
      }
    }
  }
  .container.with-titles {
    position: relative;
    .title-container {
      position: relative;
      max-width: 800px;
      width: 100%;
      margin: 0 auto 0;
      display: table;
      height: 340px;
      .titles {
        display: table-cell;
        vertical-align: middle;
        .h1, h1 {
          line-height: 44px;
          color: getColor(txt, lightest);
          padding-left: 15%;
          padding-right: 15%;
          padding-bottom: 20px;
          font-size: 53px;
          @include MQ(mM) {
            font-size: 45px;
          }
          @include MQ(mS) {
            font-size: 38px;
          }
        }
        .h2, h2 {
          color: getColor(txt, lightest);
          font-weight: 200;
          margin: 20px 10px 0;
          font-size: 28px;
          @include MQ(mM) {
            font-size: 25px;
          }
          @include MQ(mS) {
            font-size: 22px;
          }
        }
      }
      .icon-main {
        font-size: 88px;
        display: block;
        position: absolute;
        margin: 0 auto 20px;
        width: 100%;
        left: 0;
        color: getColor(txt, lightest);
      }
      .l {
        position: absolute;
        border-color: getColor(info, base);
        border-style: solid;
        &.right, &.left {
          height: 40%;
          top: 0;
          width: 27%;
        }
        &.right {
          right: 15%;
          @include MQ(mS) {
            right: 10%;
          }
          border-width: 3px 3px 0 0;
        }
        &.left {
          left: 15%;
          @include MQ(mS) {
            left: 10%;
          }
          border-width: 3px 0 0 3px;
        }
        &.btm {
          height: 10%;
          bottom: 0;
          border-width: 0 3px 3px 3px;
          left: 15%;
          width: 70%;
          @include MQ(mS) {
            width: 80%;
            left: 10%;
          }
        }
      }
    }
  }
  .btn-go-on {
    margin: 20px 0 0;
    border-color: getColor(info, base);
    padding: 16px 20px 14px;
    color: getColor(txt, lightest);
    @include MQ(mS) {
      width: 90%;
      margin: 20px 5% 30px;
    }
    font: {
      weight: 400;
      size: 18px;
    }
    .icon {
      i {
        line-height: 26px;
        font-size: 19px;
        margin: 0 0 0 1px;
      }
      color: white;
      display: inline-block;
      vertical-align: middle;
      width: 30px;
      height: 30px;
      text-align: center;
      overflow: hidden;
      border-radius: 100%;
      margin: 0 15px 0 0;
      &.brd {
        border: 2px solid white;
      }
    }
  }
  .ico-block {
    padding-top: 35px;
    padding-bottom: 35px;
    @include MQ(mS) {
      padding-top: 15px;
      padding-bottom: 15px;
    }
    &:after {
      content: '';
      clear: both;
      display: table;
    }
    .control-label {
      padding-top: 6px;
    }
    input {
      margin-bottom: 15px;
    }
    .btn {
      border: 0;
      padding: 18px 25px;
      margin: 0 auto 15px;
      &:active, &:focus {
        background: white;
      }
      &.active {
        background: getColor(info, base);
        color: getColor(txt, lightest);
        box-shadow: none;
        &:hover {
          background: getColor(info, dark);
          color: getColor(txt, lightest);
          box-shadow: 0 3px 7px rgba(black, .3);
        }
      }
      &:hover {
        background: white;
        box-shadow: 0 3px 7px rgba(black, .3);
      }
      &[type=submit]:hover {
        background: getColor(info, dark);
        box-shadow: 0 3px 7px rgba(black, .3);
      }
    }
    aside {
      float: left;
      width: 70px;
      text-align: left;
      i {
        font-size: 48px;
        color: getColor(info, base);
      }
    }
    article {
      padding: 0;
      margin: 0 0 0 80px;
      @include MQ(mM) {
        margin: 0 0 0 75px;
      }
      @include MQ(mS) {
        margin: 0;
      }
      header {
        display: block;
        padding: 0 0 20px;
        color: getColor(neutral, medium);
        @include MQ(mS) {
          padding: 0 0 10px 75px;
        }
        p {
          margin: 0;
          padding: 0 0 7px;
          font-size: 18px;
          line-height: 20px
        }
        .h2 {
          margin: 0;
          padding: 0;
        }
      }
      .cd-select {
        margin: 15px 0 0;
      }
    }
  }
}
