html {
  box-sizing: border-box;
}
html * {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body, .body {
  padding: 0;
  overflow-x: hidden;
  line-height: 1.4;
  font: {
    size: 14px;
    family: Helvetica, 'Century Gothic', Arial;
    weight: 400;
  }
}

.fonts-loaded {
  body, .body {
    font: {
      family: $basic_font;
    }
  }
}

html, .body {
  min-height: 100%;
}
*, *:before, *:after {
  box-sizing: inherit;
}

.fonts-loaded {
  p {
    font: {
      family: $basic_font;
    }
  }
}

p {
  margin: 10px 0;
  color: $main-text-color;
  font: {
    size: $main-text-size;
    weight: $main-text-weight;
  }
  &.small {
    font-size: $main-text-size * .85;
  }
  &.larger {
    margin: 20px 12px;
    font-size: 22px;
    line-height: 29px;
  }
  &.gray-txt {
    color: $h4-color;
  }
}
.text-xl {
  font-size: 38px;
}
.text-xxl {
  font-size: 46px;
}
.bold {
  font-weight: 600;
}
small {
  font-size: 13px;
  font-weight: 400;
  color: $small;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  padding: 0;
}

.fonts-loaded {
  h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    font: {
      family: $basic_font;
    }
  }
}

h1, .h1 {
  color: $h1-color;
  font: {
    size: $h1-size;
    weight: $h1-weight;
  }
  margin: 20px 0;
}
h2, .h2 {
  color: $h2-color;
  font: {
    size: $h2-size;
    weight: $h2-weight;
  }
  margin: 18px 0;
}
h3, .h3 {
  color: $h3-color;
  font: {
    size: $h3-size;
    weight: $h3-weight;

  }
  margin: 15px 0;
}
h4, .h4 {
  color: $h4-color;
  font: {
    size: $h4-size;
    weight: $h4-weight;
  }
  margin: 15px 0;
}

a, .link {
  color: getColor(etc, link);
  // color: getColor(info, base);

  &:hover, &:focus, &:active {
    color: getColor(etc, linkHover);
    text-decoration: none;
  }

  &[disabled] {
    pointer-events: none;
    color: getColor(neutral, lighter);

    &:hover, &:focus, &:active {
      color: getColor(neutral, lighter);
    }
  }
}
cite {
  font-style: italic;
}
.txt-style-one, .txt-style-two, .txt-style-three {
  font-size: 18px;
}
.txt-style-one {
  font-weight: 400;
  color: $txt-style-one;
}
.txt-style-two {
  font-weight: 400;
  color: $txt-style-two;
}
.txt-style-three {
  font-weight: 300;
  color: $txt-style-three;
}
.p-style-one, .p-style-two, .p-style-three {
  font-weight: 400;
}
.p-style-one {
  font-size: 18px;
  line-height: 30px;
  color: $p-style-one;
}
.p-style-two {
  font-size: 18px;
  line-height: 30px;
  color: $p-style-two;
}
.p-style-three {
  font-size: 16px;
  line-height: 22px;
  color: $p-style-three;
}
blockquote {
  font-style: italic;
  &:before {
    content: '"';
  }
}
.break-word {
  word-wrap: break-word;
}
