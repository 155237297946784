.navbar {
  &.no-background {
    background: transparent !important;
  }
  @include MQ(mL) {
    background: transparent none;
  }
  @include MQ(M) {
    > .container .navbar-brand,
    > .container-fluid .navbar-brand {
      margin-left: 0;
    }
  }
}
.navbar-tagline {
  @include MQ(mS) { //480
    display: none;
  }
  @include MQ(mL) {
    margin-left: 18px;
    font-size: 15px;
  }
}
.navbar-toggle {
  height: 59px;
  margin: 0;
  padding-top: 7px;
  min-width: 60px;
  color: getColor(txt, lightest);
  font-size: 40px;
  line-height: 1;
  background: getColor(info, base);
  border: 0;
  border-radius: 0;
  &:hover, &:focus {
    background-color: getColor(info, dark) !important;
  }
  .open {
    display: none;
  }
  .menu-label {
    display: block;
    text-transform: uppercase;
    font-size: 12px;
  }
  &.navbar-link {
    background: transparent;
    padding: 10px 0 0;
    &:hover, &:focus, &[aria-expanded='true'] {
      background: getColor(info, dark);
      color: getColor(txt, lightest);
    }
  }
}
.responsive-navbar .navbar-collapse {
  margin-right: -15px;
}
.navbar-collapse {
  float: right;
  position: relative;
  z-index: $nav_zindex;
  @include MQ(M) {
    border-top: 0;
    display: table;
  }
  .btn {
    height: 60px;
    line-height: 60px;
    display: table-cell;
    vertical-align: middle;
    padding: 0 15px;
    color: getColor(neutral, color) !important;
    font: {
      size: 14px;
      weight: 400;
    }
    background-color: getColor(neutral, bg);
    border: 0;
    border-left: 1px solid getColor(bg, light);
    border-radius: 0;
    @include MQ(mM) {
      display: block;
    }
    @include MQ(mS) {
      width: 100%;
    }
    &:focus {
      outline: 0 none;
    }
    i {
      margin-right: 2px;
      font-size: 17px;
      color: getColor(neutral, color);
      vertical-align: middle;
    }
    &:hover {
      color: getColor(neutral, search);
      background-color: getColor(neutral, bgEven);
    }
  }
  .navbar-conversations {
    i {
      margin-right: 0;
      font-size: 14px;
    }
    .conversations {
      font-size: 11px;
      line-height: 1;
    }
  }
  .navbar-more {
    i {
      margin-right: 5px;
      font-size: 23px;
    }
  }
  .navbar-emphasized { // last item with blue bg
    color: getColor(txt, lightest) !important;
    background: getColor(info, base);
    font-weight: bold;
    i {
      color: getColor(txt, lightest) !important;
    }
    &:hover {
      color: getColor(txt, lightest);
      background-color: getColor(info, hover);
    }
  }
  .navbar-user { // item with user avatar and name
    position: relative;
    width: 172px;
    padding: 0 3px 0 60px !important; // we decrease common .btn paddings for small resolutions
    white-space: normal;
    line-height: 1.1;
    text-align: left;
    text-overflow: ellipsis;
    img {
      position: absolute;
      left: 10px;
      @include vCenterBlock;
      max-width: 43px;
      height: auto;
    }
  }
  .navbar-additional-links {
    display: none;
    position: absolute;
    top: 60px;
    right: 0;
    width: 267px;
    z-index: 20;
    border: 1px solid getColor(bg, light);
    @include MQ(mL) {
      width: 267px;
    }
    .no-js & {
      display: block!important
    }
    .btn {
      display: block;
      height: auto;
      line-height: 1;
      padding: 10px 15px;
      text-align: left;
      background-color: getColor(txt, lightest);
      border: 0;
      border-bottom: 1px solid getColor(border, base);
      i {
        display: inline-block;
        width: 30px;
        margin-right: 0;
      }
      &:last-child {
        border-bottom: 0;
      }
      &:hover {
        background-color: getColor(neutral, bg);
      }
    }
  }
}
.land {
  .navbar {
    button { 
      display: none !important; 
    }
    .container { 
      background: none; 
    }
  }
  .jumbotron {
    @include MQ(mM) {
      margin-top: 0 !important;
      padding-top: 30px;
      height: auto !important;
    }
  }
}
// .navbar-relative-top {
//   .container {
//     position: relative;
//     .navbar-collapse {
//       padding-left: 0;
//       padding-right: 0;
//     }
//   }
// }
