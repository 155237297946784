.flatForm {
  font-size: 18px;
  // button[type="submit"] {
    // font-size: 18px;
    // padding: 25px 15px;
    // @include MQ(M) {
      // font-size: 16px;
      // padding: 15px;
    // }
  // }
  .btn-is-full {
    width: 100%;
  }
  label, .control-label, .label {
    font-weight: 400;
    font-size: 18px;
    color: getColor(neutral, medium);
    text-align: left;
    @include MQ(M) {
      font-size: 16px;
      padding-right: 0;
    }
  }
  .label {
    margin: 0;
    padding-top: 7px;
    padding-left: 15px;
    white-space: normal;
  }
  legend {
    border-bottom: 0;
    font-size: 28px;
    font-weight: 300;
    color: getColor(info, dark);
    padding-bottom: 15px;
  }
  .form-control {
    border-radius: 0;
    box-shadow: 0 0 0;
    border: 1px solid getColor(neutral, base);
    height: auto;
    padding: 8px 12px;
    font-size: 18px;
    font-weight: 400;
    &:focus {
      border: 1px solid getColor(neutral, focus);
      box-shadow: 0 0 0;
    }
    &.card-num {
      float: left;
      display: inline-block;
      margin-right: 10px;
      margin-bottom: 3px;
      ~ .cd-img-cards {
        display: inline-block;
        position: absolute;
        right: 20px;
        top: 3.5px;
        img {
          height: 28px;
        }
      }
    }
    &.exp-date {
      max-width: 150px;
    }
    &.ccv-code {
      max-width: 80px;
      display: inline-block;
      float: left;
      margin-right: 10px;
      ~ .cd-img-cards {
        margin-top: 0 !important;
        right: 0 !important;
        img {
          @include MQ(M) {
            height: 30px;
          }
        }
      }
    }
    @include MQ(L) {
      padding: 6px 12px;
      font-size: 16px;
    }
  }
  .form-group {
    clear: left;
    .help-block {
      font-size: 14px;
      &.has-min-h {
        @include MQ(L) {
          min-height: 60px;
        }
      }
    }
    .help-inline {
      color: getColor(error, light);
      margin-top: 0;
      margin-bottom: 10px;
      display: inline-block;
      float: left;
      min-height: 19px;
      width: 100%;
      font-size: 14px;
    }
    &.has-error {
      .form-control, .form-control:focus, .cd-check > .outer {
        border: 1px solid getColor(error, base);
        box-shadow: 0 0 0;
      }
    }
    &.btm-margin {
      margin-bottom: 35px;
    }
  }
  .date-form-group {
    input {
      width: 32%;
      float: left;
      margin-right: 2%;
      &:nth-child(4) {
        margin-right: 0;
      }
    }
  }
  .vertical-form-group {
    label {
      padding-bottom: 8px;
    }
  }
  .intl-tel-input {
    width: 100%;
  }
  .cd-select {
    position: relative;
    &::after {
      content: '\f107';
      font-family: 'FontAwesome';
      @include vCenterBlock();
      right: 15px;
      display: block;
      pointer-events: none;
      color: getColor(txt, base);
    }
    select {
      appearance: none;
      transition-duration: .25s;
      margin: 0;
      cursor: pointer;
      /*color: getColor(info, darker);*/
      font: {
        weight: 400;
      }
      &:hover, &:active, &:focus {
        outline: none;
        background: getColor(bg, lightEven);
      }
    }
    &.icon {
      select {
        padding-left: 30px;
      }
      [class^="icon-"], [class^="fa"] {
        position: absolute;
        @include center(y);
        left: 10px;
      }
    }
  }
  .fileUpload {
    position: relative;
    overflow: hidden;
    margin: 0;

    input.upload {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      margin: 0;
      padding: 0;
      font-size: 20px;
      cursor: pointer;
      opacity: 0;
      filter: alpha(opacity=0);
    }
  }
  .nameFile {
    color: getColor(info, dark);
    padding-top: 10px;
    clear: left;
    a {
      color: getColor(info, dark);
    }
    &.inactive a {
      text-decoration: line-through;
    }
    .btn {
      padding: 2px 5px;
    }
    .name {
      &::before {
        content: '\e639';
        font-family: 'sailogy3';
        font-size: 18px;
        display: inline-block;
        margin-right: 10px;
        color: inherit;
        vertical-align: middle;
      }
    }
    .delFile {
      color: getColor(danger, base);
      font-size: 26px;
      vertical-align: middle;
      margin-left: 5px;

      span {
        vertical-align: middle;
        background-color: getColor(bg, light);
        border-radius: 50%;
        box-shadow: inset 0 0 0 3px getColor(neutral, bg);
      }

      &:hover {
        color: getColor(danger, hover);

        span {
          background-color: getColor(bg, light);
        }
      }
    }
  }
  @include MQ(M) {
    font-size: 16px;
  }
}
.selectBoxForm {
  input[type="radio"] {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
    ~ .selectableBox {
      pointer-events: none;
      border: 1px solid getColor(neutral, medium);
      cursor: pointer;
      padding: 20px 20px 65px;
      .selectedMsg {
        background-color: getColor(success, success);
        border-radius: 20px;
        display: none;
        padding: 5px 30px;
        color: getColor(txt, lightest);
        font-weight: 600;
        font-size: 18px;
        position: absolute;
        @include center(x);
        bottom: -15px;
      }
      .deposit-payments-icons {
        left: 5%;
        width: 90%;
        bottom: 30px;
        padding: 0;
        display: none;
        position: absolute;
      }
    }
    &:checked {
      ~ .selectableBox {
        border: 1px solid getColor(success, success);
        .deposit-payments-icons {
          width: 90%;
          display: block;
        }
        .selectedMsg {
          display: inline-block;
        }
      }
    }
  }
}
.cd-switch {
  width: 70px;
  height: 22px;
  position: relative;
  .switch-input {
    width: 100%;
    height: 22px;
    cursor: pointer;
    z-index: 2;
    position: relative;
    opacity: 0;
    margin-top: 0;
    &.yes-check {
      &:checked {
        display: none;
        & + .no-check {
          display: block;
          width: 100%;
        }
        ~ .switch-selection {
          background-color: getColor(info, base);
        }
        ~ .switch-label-yes {
          opacity: 1;
          right: 12px;
          left: auto;
          color: getColor(info, base);
        }
        ~ .switch-container {
          border-color: getColor(info, base);
        }
      }
    }
    &.no-check {
      &:checked {
       display: none;
       & + .yes-check {
        display: block;
        width: 100%;
      }
      ~ .switch-selection {
        background-color: getColor(danger, base);
        left: 45px;
      }
      ~ .switch-label-no {
        opacity: 1;
        right: auto;
        left: 12px;
        color: getColor(danger, base);
      }
      ~ .switch-container {
        border-color: getColor(danger, base);
      }
    }
  }
}
label {
  opacity: 0;
  position: absolute;
  top: 3px;
  font-size: 12px;
}
.switch-container {
  width: 70px;
  display: inline-block;
  height: 22px;
  border-radius: 15px;
  border: 1px solid black;
  position: absolute;
  top: 0;
  left: 0;
  transition: all .2s ease-in-out;
}
.switch-selection {
  position: absolute;
  border-radius: 50px;
  height: 30px;
  width: 30px;
  left: -5px;
  @include center(y);
  background-color: getColor(txt, darkest);
  z-index: 1;
  user-select: none;
  transition: all .2s ease-in-out;
}
}
.cd-animate-fields {
  position: relative;
  width: 100%;
  float: left;
  margin-bottom: 30px;
  margin-top: 60px;

  .form-control {
    width: 100%;
    border: 1px solid transparent;
    padding: 10px 12px;
    font-size: 1em;
    &:focus {
      border: 1px solid transparent;
      ~ label {
        top: -40px;
        color: white;
      }
    }
  }
  label {
    position: absolute;
    left: 10px;
    top: 0;
    bottom: 0;
    color: getColor(neutral, lighter);
    padding: 10px 0;
    transition: all .2s ease-in-out;
    pointer-events:none;
    font-size: 16px;
    &.on-focus {
      top: -40px;
      color: white;
    }
  }
  button {
    border:1px solid getColor(danger, base);
    font-size: 1em;
    color: white;
    padding: 10px 12px;
    cursor: pointer;
    position: absolute;
    right:0;
    top: 0;
    &:hover, &:focus {
      background-color: getColor(danger, hover);
      border:1px solid getColor(danger, hover);
      color: white;
    }
  }
  @include MQ(S) {
    label {
      font-size: 18px;
    }
  }
}
.cd-check {
  position: relative;
  padding: 0;
  .form-control {
    cursor: pointer;
    opacity: 0;
    width: 100%;
    position: absolute;
    margin-right: 10px;
    float: left;
    &:checked {
      ~ .outer {
        .fa {
          transform: scale(1, 1);
        }
        ~ .check-label {
          color: getColor(info, base);
        }
      }
    }
  }
  .outer {
    display: inline-block;
    position: absolute;
    left: 0;
    top: 0;
    width: 20px;
    height: 20px;
    border: 1px solid getColor(neutral, base);
    user-select: none;
    pointer-events: none;
    vertical-align: middle;
    .fa {
      transition: transform .4s cubic-bezier(.45,1.8,.5,.75);
      transform: scale(0, 0);
      color: getColor(info, base);
      position: absolute;
      top: 2px;
      left: 1px;
    }
  }
  .check-label {
    padding-left: 30px;
  }
}
.cd-inline-radiobox {
  margin-top: 20px;
  margin-bottom: 10px;
  p {
    float: left;
    display: inline-block;
    margin-top: 0;
  }
  .form-group {
    float: left;
    clear: none;
    display: inline-block;
    margin-left: 0;
    clear: left;

    @include MQ(M) {
      margin-left: 50px;
      clear: none;
    }
  }
}
.label-radiobox {
  position: relative;
  margin-right: 15px;
  cursor: pointer;
  input {
    margin-right: 5px;
    opacity: 0;
    &:checked {
      font-weight: 600;
      ~ .outer {
        .inner {
          transform: scale(1,1);
        }
        ~ .text {
          color: getColor(info, base);
        }
      }
    }
  }
  .outer {
    position: absolute;
    left: -2px;
    top: 2px;
    width: 20px;
    height: 20px;
    background: getColor(bg, light);
    border: 1px solid getColor(neutral, base);
    border-radius: 50%;
  }
  .inner {
    transition: transform .4s cubic-bezier(.45,1.8,.5,.75);
    transform: scale(0,0);
    content: "";
    position: absolute;
    top: 3px;
    left: 3px;
    z-index: 1;
    width: 12px;
    height: 12px;
    background: getColor(info, base);
    border-radius: 50%;
  }
  .text {
    padding-left: 5px;

  }
}
.deposit-payments-icons {
  .item {
    width: 30%;
    display: inline-block;
    color: getColor(txt, darkest);
    i {
      font-size: 1.8em;
      float: left;
      margin-right: 7px;
      @include MQ(mM) {
        margin-right: 3px;
        font-size: 20px;
      }
    }
    span {
      opacity: .5;
      line-height: 25px;
      display: inline-block;
      float: left;
      @include MQ(mM) {
        line-height: 20px;
      }
      @include MQ(mS) {
        display: block;
      }
    }
  }
  &.small {
    i {
      font-size: 1.4em;
    }
  }
}
.form-group.form-select {
  select {
    width: 100%;
    padding-right: 35px;
    border: 0;
    border-radius: 0;
    appearance: none;
    height: 41px;
  }
  &:after {
    font-family: 'sailogy3' !important;
    content: '\e607';
    position: absolute;
    transform: rotate(90deg);
    right: 10px;
    opacity: .7;
    top: 0;
    bottom: 0;
    width: 36px;
    height: 41px;
    pointer-events: none;
    text-align: center;
  }
}
