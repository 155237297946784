body.press {
  #content {
    article {
      section {
        &.top-articles {
          .press-img {
            width: 100%;
            max-width: 100%;
          }
        }
      }
    }

    #footer {
      background: white;
      padding: 15px 15px;
      img {
        display: block;
        width: 100%;
        max-width: 200px;
        margin: 0 auto;
      }
    }
  }
}