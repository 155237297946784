body.profile {
  .action-buttons {
    text-align: center;
    .btn {
      display: inline-block;
      width: calc(20% - 10px);
      padding: 15px 0;
      margin: 0 10px 0 0;
      white-space: nowrap;
      @include MQ(mL) {
        width: calc(33% - 10px);
        margin-bottom: 10px;
      }
      @include MQ(mM) {
        width: calc(50% - 10px);
      }
      i {
        display: inline-block;
        margin: 0 10px 0 0;
      }
    }
  }
  .jumbotron-profile {
    height: auto;
    color: getColor(txt, lightest);
    padding-top: 0;
    position: relative;
    background: {
      image: url('#{$assets_path}/img/bg/profile.jpg');
      size: cover;
      position: center;
    }
    .manage-buttons {
      white-space: nowrap;
      text-align: center;
      .btn {
        margin: 0 0 7px 4px;
        &:first-child {
          margin-left: 0;
        }
      }
    }
    .jumbo-header {
      font-size: 17px;
      color: getColor(txt, darkBgInfo);
      .list-inline {
        display: inline-block;
        margin-left: 5px;
        @include MQ(mS) {
          margin-top: 10px;
          margin-left: 0;
        }
      }
      a {
        color: getColor(txt, lightest);
      }
      .contacts-list {
        span {
          @include MQ(mS) {
            display: none;
          }
        }
        li {
          &:first-child {
            margin-bottom: 4px;
          }
        }
      }
      .account-title {
        font-weight: 400;
        color: getColor(txt, lightest);
        font-size: 23px;
        max-width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .account-subtitle {
        font-size: 16px;
      }
      .userpic-place {
        @media only screen and (min-width: 991px) {
          margin-top: -25px;
        }
        img {
          min-width: 70px;
          border-radius: 50%;
          display: block;
          margin: 0 auto 10px;
        }
      }
    }
    label {
      font-weight: normal;
      margin: 0;
      min-width: 60px;
      @include MQ(mS) {
        display: none;
      }
    }
  }
}
#profile-tabs {
  [role=tabpanel] {
    overflow: hidden;
    > .nav {
      > li {
        margin-bottom: -1px;
        padding: 0;
        a {
          display: block;
          border: 0;
          border-radius: 0;
          margin-right: 0;
          color: getColor(txt, dark);
          font-size: 21px;
          padding: 0;
          @include MQ(mM) {
            font-size: 19px;
            min-height: 0;
          }
          @include MQ(mS) {
            min-height: 95px;
          }
          .tbl {
            height: 90px;
          }
        }
        i {
          color: getColor(txt, dark);
        }
        &.active i, &.active:hover i {
          color: getColor(txt, lightest);
        }
        &.active a {
          color: getColor(txt, lightest);
          background: getColor(info, base);
        }
      }
    }
  }
  .details-tab-content {
    padding-top: 0;
    padding-bottom: 0;
  }
  .item-vacation {
    section {
      padding-bottom: 10px;
    }
    .image-details {
      background: getColor(bg, dark);
      overflow: hidden;
      text-align: center;
      height: 240px;
      position: relative;
      .preview {
        position: absolute;
        width: 100%;
        z-index: 8;
        top: 0;
        left: 0;
        img {
          width: 100%;
          min-height: 100%;
          opacity: 0.65;
        }
      }
      .footer {
        position: absolute;
        z-index: 10;
        top: 55%;
        left: 0;
        width: 100%;
        button {
          width: 70%;
          margin: 0 15%;
        }
      }
      header {
        position: absolute;
        z-index: 10;
        top: 25%;
        left: 0;
        width: 100%;
        font-size: 25px;
        color: getColor(txt, lightest);
        font-weight: 300;
      }
    }
  }
}
#vacations-archive > .item-vacation:nth-child(even) {
  background: getColor(neutral, bg);
}
