#insurance_1, #insurance_2 {
  background-color: darken(white, 15%);
  padding: 40px 0;
  .container {
    padding: 0 15px;
  }
  .insurance-box {
    background-color: getColor(txt, lightest);
    padding: 20px 20px 70px;
    border-radius: 10px;
    text-align: center;
    color: getColor(txt, base);
    margin: 20px 10px;
    position: relative;
    p {
      color: getColor(txt, light);
    }
    @include MQ(S) {
      margin: 20px 40px;
    }
  }
  .price-title {
    position: relative;
    text-align: center;
    margin-bottom: 25px;
    .icon-sailogy-check, .icon-sailogy-shield, .price-container {
      padding: 0;
      margin-bottom: 0;
      display: inline-block;
      vertical-align: middle;
    }
    .icon-sailogy-check, .icon-sailogy-shield {
      padding-right: 10px;
    }
  }
  .price-container {
    overflow: hidden;
    text-align: center;
    position: relative;
    display: inline-block;
    vertical-align: middle;
    .icon-sailogy-shield {
      @include MQ(XL) {
        left: 15px;
      }
    }
  }
  .price {
    display: inline-block;
    text-align: center;
    margin: 0 5px 0 5px;
    position: relative;
    h5 {
      font: {
        size: 32px;
        weight: 600;
      }
      padding: 0;
      margin: 0;
    }
  }
  p {
    display: block;
    font: {
      size: 16px;
      weight: 400;
    }
    padding: 0 20px;
  }
  .deposit-payments-icons {
    padding: 0 20px;
  }
  h6, h4 {
    font: {
      size: 20px;
      weight: 600;
    }
    color: getColor(neutral, medium);
    padding: 10px 0;
    text-align: center;
    @include MQ(M) {
      font-size: 24px;
    }
  }
  .type {
    text-align: center;
    font: {
      size: 18px;
      weight: 400;
    }
    @include MQ(L) {
      font-size: 16px;
    }
  }
  .icon-sailogy-check, .icon-sailogy-shield {
    font-size: 55px;
    color: getColor(info, regular);
    display: block;
    text-align: center;
    margin-bottom: 10px;
  }
}
#insurance_2 {
  color: getColor(txt, base);
  font-weight: 400;
  p {
    padding: 10px 0;
    text-align: center;
  }
  .cd-insurance-box {
    margin-top: 40px;
  }
  .icon-sailogy-check {
    position: relative;
    top: auto;
    left: auto;
    vertical-align: middle;
    display: inline-block;
  }
  .price {
    margin: 15px 5px;
  }
  .price-title {
    margin-bottom: 0;
  }
  .price-container {
    display: inline-block;
    vertical-align: middle;
  }
  @include MQ(L) {
    .price {
      margin: 0 5px;
    }
    p {
      padding: 0;
      text-align: left;
    }
  }
}
