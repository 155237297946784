.data-table {

  .table > thead > tr > th, .table > thead > tr > td, .table > tbody > tr > th, .table > tbody > tr > td, .table > tfoot > tr > th, .table > tfoot > tr > td {
    padding: 0;
    border-collapse: collapse;
    border: 1px solid getColor(neutral, base);

    .form-control {
      border: 0;

      &:focus {
        background-color: getColor(neutral, bg);
        @include placeholder(getColor(neutral, focus));
      }

      &[disabled] {
        background-color: getColor(neutral, bgLight);
        @include placeholder(getColor(neutral, lighter));
      }
    }
  }

  td {
    min-width: 180px;
    p {
      padding: 7px 5px 7px 10px;
      margin: 0;
      &.small {
        padding: 4px 5px 4px 10px;
      }
    }
    &.table-check {
      background: getColor(bg, light);
      padding: 5px 0 0 7px !important;
      overflow: hidden;
    }
  }

  .table > thead > tr > th {
    padding: 8px 0;
    border: 0;
    font-size: 16px;
  }

  .table > tr > th {
    padding: 8px 0;
  }

  th {
    color: lighten(getColor(txt, light), 15%);
    font-weight: 400;
  }

  tr > th, tr > td {
    &.minor {
      width: 100px;
      min-width: 100px;
    }
  }
}

table.table {
  td, th {
    color: $table-text-color;
    font: {
      size: $table-text-size;
    }
  }
}

.table-style-one, .table-style-two {
  padding: 20px;
  margin-bottom: 15px;

  .h2 {
    margin-top: 0;
  }

  hr {
    border-color: getColor(neutral, base);
  }

  @include MQ(M) {
    margin-bottom: 0;
  }
}

.table-style-two {
  background-color: getColor(neutral, bgLight);
}

.tbl {
  display: table;
  width: 100%;
  .cell {
    display: table-cell;
    vertical-align: middle;
  }
}
