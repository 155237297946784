// More search results ajax loader at the bottom of search results list

.ajax-load-more-boats {
  margin-top: 30px;
  margin-bottom: 30px;
  padding-top: 0!important;
  clear: both;
  a {
    display: block;
    padding: 10px;
    text-align: center;
    color: getColor(neutral, color);
    font-size: 16px;
    line-height: 1;
    background-color: getColor(bg, filtersHeader);
    border: 1px solid #cacaca;
    &:hover { background-color: darken(getColor(bg, filtersHeader), 10%) }
  }
}
