@media only screen and (max-width: $screen-sm-max) { // mL
  .portletShare,
  .portletSubscribe,
  .portletRecentSearches,
  .portletGoogleMap,
  .compare-list {
    display: none;
  }
  .navbar {
    background: getColor(bg, light);
  }
  .navbar .navbar-brand {
    width: 193px;
  }
  .navbar-tagline {
    display: none;
  }
  #input-people.form-group {
    select {
      padding-left: 35px;
    }
    i {
      left: 10px;
    }
  }
  .portletMainMarinas, .portletBrands {
    td:last-child {
      white-space: nowrap;
    }
    .textcut {
      white-space: normal;
      width: auto;
    }
  }
  .section-search .portal-column-left > .portlet:last-child {
    margin-bottom: 20px!important;
  }
  .portletGoogleMap, .portletGoogleMap.active, .compare-list {
    display: none;
  }
  .portletBoatTypes, .portletTotalPrice, .portletLength, .portletCabins, .portletToilets, .portletYears {
    ul {
      overflow: hidden;
      li {
        position: relative;
        float: left;
        width: 32.33%;
        margin: 0 1% 5px 0;
        label {
          text-align: center;
          padding: 10px;
          border: 1px solid getColor(border, forDark);
          &:hover {
            span {
              color: getColor(info, regular);
            }
          }
          &.selected {
            color: getColor(txt, darkest);
            border: 1px solid getColor(border, base);
            span {
              color: getColor(info, regular);
            }
          }
        }
        input {
          visibility: hidden;
          position: absolute;
          left: -1000px;
        }
      }
    }
  }
  .portletTotalPrice, .portletLength, .portletCabins, .portletToilets, .portletYears {
    label.selected {
      background-color: getColor(neutral, bg);
    }
  }
  .filters-content > .portlet:first-child {
    padding-top: 20px;
  }
  .section-search {
    .header-search .header-search-submit {
      font-size: 18px;
    }
    .portal-column-left > .portlet:last-child {
      margin-bottom: 20px!important;
    }
    .filters-wrapper {
      margin-bottom: 20px;
      margin-top: 20px;
      .filters-header {
        display: block;
        padding: 15px 20px;
        font-size: 16px;
        line-height: 1;
        color: getColor(neutral, color);
        cursor: pointer;
        background-color: getColor(bg, filtersHeader);
        border: 1px solid getColor(border, input);
        &:hover { background-color: darken(getColor(bg, filtersHeader), 5%) }
      }
      &.collapsed {
        .filters-header {
          color: getColor(txt, lightest);
          background-color: getColor(bg, filtersCollapsed);
          border-color: getColor(bg, filtersCollapsed);
        }
      }
      .filters-content {
        background-color: getColor(bg, light);
      }
    }
  }
}
@media only screen and (max-width: $screen-xs-max) { //mM
  .portletLength .btn-group .btn {
    padding: 0 20px;
    font-size: 12px;
  }
  .section-search {
    .no-results-wrapper h1 {
      font-size: 30px;
    }
    .filters-board {
      padding: 10px;
      & > div {
        display: block;
        width: 100%;
      }
      #sort-order select {
        height: 40px;
        line-height: 40px;
      }
      .price-type-selector {
        text-align: left;
        display: block;
        width: 100%;
        @include clearfix;
        .btn-group {
          display: block;
          float: left;
          width: 80%;
          label {
            width: 50%;
            height: 35px;
            line-height: 35px;
            padding: 0;
            border-color: getColor(border, input);
          }
        }
      }
    }
    .sort-order-wrapper {
      margin-bottom: 15px;
    }
    #boats-type-selector label {
      padding-left: 3px;
      padding-right: 3px;
      font-size: 13px;
    }
    .row.ajax-load-more-boats {
      margin-left: 5px;
      margin-right: 5px;
    }
    .panel-group .panel-heading .nearest-marina {
      margin-top: 0;
      br {
        display: inline
      }
    }
  }
}
@media only screen and (min-width: $screen-sm-min) { // 768px
  .section-search .search-results-wrapper .col3 .dates-list {
    display: block!important;
  }
}
