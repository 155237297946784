.video-play-link { /* Link AFTER video thumbnail, no image inside  */
  display: block;
  position: relative;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  text-align: center;
  color: getColor(attention, base);

  &:before {
    @include vCenterBlock;
    @include center();
    content: '';
    content: "\e617";
    position: absolute;
    width: 100%;
    left: 50%;
    font-family: 'sailogy3';
    font-size: 90px;
    z-index: 1;
    border-radius: 7px;
  }

  &:after {
    @include center();
    content: '';
    display: inline-block;
    width: 50px;
    height: 50px;
    background: white;
    position: absolute;
    z-index: 0;
  }
  &:hover {
    color: getColor(attention, hover) !important;
  }
}
