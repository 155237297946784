/*.adv-block-wrapper {
  @include MQ(S) {
    margin-bottom: 25px;
  }
}*/
.adv-block-flight {
  @include clearfix;
  display: table;
  width: 100%;
  font-size: 14px;
  background: darken(getColor(neutral, bg), 5%);
  @include MQ(mM) {
    padding: 5px 20px;
    font-size: 12px;
  }
  @include MQ(mS) {
    padding: 5px 0;
    font-size: 11px;
  }
  & > div {
    display: table-cell;
    padding: 5px;
    vertical-align: middle;
    @include MQ(mS) {
      display: block;
    }
  }
  .col-text {
    text-align: center;
    @include MQ(S) {
      text-align: left;
    }
  }
  .col-icon, .col-label {
    @include MQ(S) {
      width: 1%;
    }
  }
  .col-icon {
    @include MQ(mM) {
      display: none;
    }
  }
  p {
    margin: 0;
    padding-top: 11px;
    line-height: 1.2;
  }
  em {
    font-style: normal;
    font-weight: 600;
  }
  span {
    float: left;
    position: relative;
    height: 40px;
    line-height: 40px;
    margin-right: 20px;
    padding: 0 35px 0 10px;
    color: getColor(info, base);
    font-size: 28px;
    background-color: getColor(neutral, bg);
    @include MQ(mM) {
      margin-right: 0;
      padding: 0 20px 0 10px;
      font-size: 20px;
    }
    &:after {
      content: '';
      position: absolute;
      right: 0;
      top: 0;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 20px 0 20px 20px;
      border-color: darken(getColor(neutral, bg), 5%) darken(getColor(neutral, bg), 5%) darken(getColor(neutral, bg), 5%) transparent;
      -webkit-transform: rotate(360deg); // Chrome antialiasing
      transform: rotate(0.0005deg); // FF border antialiasing
    }
  }
  a {
    display: block;
    position: relative;
    height: 40px;
    line-height: 40px;
    padding: 0 25px 0 55px;
    white-space: nowrap;
    font-weight: 600;
    color: getColor(info, base);
    background-color: getColor(txt, lightest);
    @include MQ(mM) {
      padding: 0 15px 0 30px;
    }
    @include MQ(mS) {
      padding-right: 10px;
    }
    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 20px 0 20px 20px;
      border-color: transparent transparent transparent darken(getColor(neutral, bg), 5%);
      -webkit-transform: rotate(360deg); // Chrome antialiasing
      transform: rotate(0.0005deg); // FF border antialiasing
    }
    &:hover {
      background-color: getColor(info, regular);
      color: getColor(txt, lightest);
    }
    &:after {
      margin-left: 10px;
      content: '›';
    }
  }
}
