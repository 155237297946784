// We might need this on other dropdowns later, so let's keep it in form of mixin
@mixin hoverDropdownOnDesktop() {
  @include MQ(M) {
    .dropdown {
      .dropdown-menu li:hover .sub-menu {
          visibility: visible;
      }
      &:hover .dropdown-menu {
          display: block;
      }
    }
  }
}

header#main-header {
  nav {
    margin: 0;
    &#main-nav {
      height: 59px;
      border-radius: 0;
      border: 0;
      background: getColor(bg, light);
      z-index: 1100;
      #logo {
        color: getColor(danger, base);
        font-size: 35px;
        line-height: 54px;
        &:hover {
          color: getColor(danger, hover);
        }
        @include MQ(mM) {
          margin-left: 10px;
        }
      }
      .navbar-text {
        font-size: 16px;
        padding: 0 0 0 9px;
        border-left: 1px solid getColor(danger, base);
        margin: 9px 0 3px 30px;
        line-height: 20px;
        .h1, h1 {
          margin: 0;
          padding: 0;
          color: inherit;
          font-size: inherit;
          line-height: inherit;
        }
        a {
          display: block;
          color: lighten(black, 22%);
          &:hover {
            color: black;
          }
        }
      }
      @include MQ(mM) {
        .navbar-header {
          box-shadow: 0 1px 10px rgba(black, .6);
          margin: 0;
        }
      }
      ul.navbar-right {
        > li {
        }
      }
      #main-menu {
        z-index: 999;
        padding-right: 0;
        width: auto;
        // display: block;
        margin: 0;
        @include MQ(mM) {
          @include list-unstyled;
          margin-left: -5px;

          > li {
            display: inline-block;
            padding-left: 5px;
            padding-right: 5px;
          }
          width: 100%;
          overflow: visible;
          background: getColor(neutral, bgLight);
          & > li {
            float: left;
            width: 25%;
            padding: 0;
            > a {
              padding-top: 15px;
              padding-bottom: 15px;
              &:hover, &:focus {
                text-decoration: none;
              }
            }
            &.login {
              width: 50%;
              @include MQ(mM) {
                background: linear-gradient(darken(white, 30%), white 12%);
              }
            }

          }
          .navbar-header {
            margin: 0 !important;
          }
        }
        & > li {
          border-right: 1px solid rgba(black, .1);
          padding: 0;
          min-height: 60px;
          &:first-child {
            border-left: 1px solid rgba(black, .1);
            border-right: 0;
          }
          @include MQ(mM) {
            position: static;
          }
          &.call {
            width: 100%;
            height: 60px;
            overflow: hidden;
            background: linear-gradient(darken(white, 30%), white 12%);
            a {
              padding: 0;
              line-height: 29px;
              display: block;
            }
            .icon {
              vertical-align: top;
              display: inline-block;
              color: getColor(info, hover);
              width: 45px;
              font-size: 36px;
              margin: 13px 0 0 7px;
              float: left;
            }
            .txt {
              vertical-align: top;
              padding: 11px 0 6px;
              line-height: 21px;
              display: inline-block;
              margin: 0;
              .hdr {
                font-size: 16px;
                opacity: .6;
                color: black;
              }
              .ftr {
                font-size: 19px;
                color: getColor(neutral, color);
                @include MQ(mS) {
                  font-size: 17px;
                }
              }
            }
          }
          /*&.wallet {
            @include MQ(mM) {
              position: absolute;
              right: -1px;
              top: 0;
              width: calc(30% + 1px);
            }
            @include MQ(mS) {
              width: calc(40% + 1px);
            }
            a {
              line-height: 17px;
              padding: 0 15px;
              background: getColor(info, regular);
              color: white;
              @include MQ(mM) {
                padding: 0 5px;
                height: 60px;
                background: linear-gradient(darken(getColor(info, regular), 10%), getColor(info, regular) 12%);
              }
              header, footer {
                height: 59px;
                vertical-align: top;
                display: inline-block;
              }
              header {
                line-height: 62px;
                font-size: 25px;
                padding: 0 5px 0 0;
                @include MQ(mS) {
                  display: none;
                }
              }
              footer {
                padding: 8px 0 0 0;
                line-height: 20px;
                @include MQ(mS) {
                  width: 100%;
                  padding-left: 0;
                  text-align: center;
                }
              }
              &:hover {
                background: getColor(info, base);
                @include MQ(mM) {
                  background: linear-gradient(darken(getColor(info, regular), 15%), darken(getColor(info, regular), 10%) 12%);
                }
              }
            }
            &.zero {
              a {
                background: getColor(danger, base);
                @include MQ(mM) {
                  background: linear-gradient(darken(getColor(danger, base), 10%), getColor(danger, base) 12%);
                }
                &:hover {
                  background: getColor(danger, hover);
                  @include MQ(mM) {
                    background: linear-gradient(darken(getColor(danger, base), 15%), darken(getColor(danger, base), 10%) 12%);
                  }
                }
              }
            }
          }*/

          &.dropdown {
            background: transparent;
            @include MQ(mM) {
              background: linear-gradient(darken(white, 30%), white 12%);
            }
            &.open {
              > a {
                background: rgba(getColor(info, base), .1);
                box-shadow: 0 1px 10px rgba(black, .2);
                .icon {
                  @include rotate(180deg);
                }
              }
            }
            &.login {
              > a {
                padding: 0;
                height: 59px;
                display: table;
                @include MQ(M) {
                  min-width: 180px;
                }
                .img, .user, .icon {
                  display: table-cell;
                  float: none;
                  vertical-align: middle;
                }
                .img {
                  img {
                    margin: 0 10px;
                  }
                  @include MQ(mS) {
                    display: none;
                  }
                }
                .icon {
                  padding: 0 15px;
                }
              }
              .user {
                float: left;
                line-height: 20px;
                @include MQ(mM) {
                  width: 100%;
                }
                @include MQ(mS) {
                  padding-left: 5px;
                }
              }
              img {
                margin: 5px 10px 0 10px;
                border-radius: 50%;
                width: 35px;
                height: 35px;
                float: left;
              }
              .dropdown-menu {
                width: 100%;
                left: 0;
                right: auto;
                @include MQ(M) {
                  min-width: 180px;
                  width: auto;
                }
                li {
                  a {
                    position: relative;
                    padding-left: 65px;
                    @include MQ(M) {
                      padding-left: 70px;
                    }
                    @include MQ(mS) {
                      padding-left: 45px;
                    }
                  }
                }
              }
            }

          }
          .dropdown-menu {
            min-width: 100%;
            padding: 0;
            margin-top: -1px;
            border-radius: 0;
            right: auto;
            border: {
              top: 1px solid darken(white, 5%);
              left: 0;
              right: 0;
            }
            @include MQ(mM) {
              position: absolute;
              top: 100%;
              left: 0;
              width: 100%;
              background: white;
            }
            li {
              border: {
                color: darken(white, 10%);
                top: 1px solid darken(white, 10%);
              }
              &:first-child {
                border-top: 0;
              }
              a {
                background: white;
                line-height: 45px;
                padding-left: 15px;
                padding-right: 15px;
                @include MQ(mM) {
                  line-height: 52px;
                }
                &:hover {
                  background: darken(white, 5%);
                }
                img.flag {
                  margin: 7px 10px 0 0; //-8
                }
              }
              &.active {
                a {
                  background: rgba(getColor(info, base), .1);
                  &:hover {
                    background: rgba(getColor(info, base), .2);
                  }
                }
              }
            }
          }
          > a {
            padding-top: 14px;
            padding-bottom: 13px;
          }
          a {
            transition-duration: .2s;
            line-height: 30px;
            font-size: 18px;
            color: getColor(info, darker);
            &:hover {
              background: rgba(getColor(info, base), .05);
            }
            img.flag {
              margin: 0 10px 0 0;
              @include MQ(mS) {
                margin: 0;
              }
            }
            .icon {
              font-size: 9px;
              float: right;
              margin: 11px 0 0 10px;
              @include MQ(mS) {
                margin: 11px 0 0 0;
              }
            }
            .txt {
              margin-right: 10px;
            }
            .icon-left {
              font-size: 19px;
              position: absolute;
              left: 13px;
              top: 21px;
              width: 40px;
              text-align: center;
              @include MQ(M) {
                top: 16px;
              }
              @include MQ(mS) {
                width: 30px;
              }
            }
            @include MQ(mL) {
              padding-left: 10px;
              padding-right: 10px;
            }
            @include MQ(mM) {
              &.dropdown-toggle {
                min-height: 60px;
                .txt {
                  width: 1px;
                  overflow: hidden;
                  float: left;
                }
              }
            }
          }
        }
      }
    }

    &#sub-nav {
      background: getColor(info, base);
      border: 0;
      border-radius: 0;
      float: none;
      padding: 0;
      z-index: 998;
      overflow-y: visible !important;
      > .container {
        position: relative;
      }
      @include MQ(M) {
        background: linear-gradient(getColor(info, dark), getColor(info, base) 15%);
        display: block !important;
        width: 100%;
      }

      ul {
        padding: 0;
        margin: 0;
        clear: both;
        li {
          border-right: 1px solid rgba(black, .1);
          &:first-child {
            border-left: 1px solid rgba(black, .1);
          }
          &:last-child {
            @include MQ(mM) {
              float: none;
              border-left: 0;
            }
          }
          &.with-footer {
            a {
              padding-top: 10px;
              padding-bottom: 7px;
              i {
                padding-top: 7px;
                display: inline-block;
                vertical-align: top;
                @include MQ(mM) {
                  margin-top: -5px;
                  padding-top: 0;
                }
              }
              span {
                line-height: 19px;
                position: relative;
                display: inline-block;
                footer {
                  white-space: nowrap;
                  font-size: 12px;
                  opacity: .7;
                }
              }
            }
          }
          @include MQ(mM) {
            border-bottom: 1px solid rgba(black, .1);
          }
          a {
            font-size: 18px;
            color: white;
            line-height: 25px;
            min-height: 55px;
            background: transparent;
            padding: 15px 10px;
            @include MQ(mXL) {
              font-size: 15px;
            }
            @include MQ(S) {
              @include MQ(mL) {
                font-size: 16px;
                padding-left: 8px;
                padding-right: 8px;
              }
            }
            @include MQ(mM) {
              text-align: center;
              padding-left: 5px;
              padding-right: 5px;
              min-height: 0;
              line-height: 34px;
              font-size: 18px;
            }
            &:hover, &:active, &:focus {
              background: rgba(black, .07);
            }
          }
          aside {
            display: inline-block;
            float: left;
            vertical-align: middle;
            @include MQ(mM) {
              float: none;
            }
          }
          i {
            font-size: 24px;
            float: left;
            margin: 0;
            @include MQ(mL) {
              @include MQ(M) {
                font-size: 20px;
              }
            }
            @include MQ(mM) {
              float: none;
            }
          }
          .small-icon {
            font-size: 9px;
            float: right;
            margin: 8px 0 0 10px;
            @include MQ(mM) {
              margin: 11px 10px 0 -10px;
              font-size: 12px;
            }
          }
          span {
            padding-left: 10px;
            @include MQ(mL) {
              padding-left: 5px;
            }
          }
          ul {
            &.dropdown-menu {
              right: auto;
              border: {
                bottom: 0;
                right: 0;
                left: 0;
                top: 1px solid rgba(black, .1);
              }
              border-radius: 0;
              background: getColor(info, base);
              @include MQ(M) {
                min-width: 230px;
              }
              @include MQ(mM) {
                background: rgba(black, .1);
              }
              li {
                border: {
                  left: 0;
                  right: 0;
                  top: 0;
                  bottom: 1px solid rgba(black, .1);
                }
                &:last-child {
                  border-bottom: 0;
                }
                a {
                  padding: 15px 10px;
                  span {
                    padding-right: 15px;
                  }
                }
              }
            }
          }
        }
        &:last-child {
          @include MQ(M) {
            position: absolute;
            right: 0;
          }
        }
      }
      @include MQ(mM) {
        min-height: 0;
      }
    }
  }

  @include hoverDropdownOnDesktop();
}

.navbar-boat {
  .navbar-header {
    background-color: getColor(danger, base);
    @include MQ(M) {
      background-color: transparent;
    }
  }
}
.header-settings {
  position: absolute;
  @include center(y);
  right: 70px;
  color: getColor(txt, lightest);
  font-size: 35px;
  &:hover, &:focus, &:active {
    opacity: .8;
    color: getColor(txt, lightest);
  }
}
.landing-header {
  height: 55px;
  background-color: rgba(black, .5);
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
  .container {
    position: relative;
    height: inherit;
  }
  #logo-landing {
    position: absolute;
    @include center(y);
    left: 15px;
    color: white;
    font-size: 32px;
    padding: 0;
    a {
      color: white;
    }
  }
  h2 {
    color: white;
    font: {
      size: 18px;
      weight: 300;
    }
    position: absolute;
    @include center(y);
    right: 0;
    margin: 0;
  }
  @include MQ(M) {
    #logo-landing {
      left: 0;
    }
    h1 {
      position: absolute;
      @include center(y);
      left: 0;
    }
  }
}
.header-bck {
  min-height: 120px;
  background: {
    image: none;
    position: center;
    size: cover;
  }
  color: getColor(txt, lightest);
  padding: 40px 0;
  h2 {
    font: {
      size: 52px;
      weight: 300;
    }
    color: white;
    text-transform: capitalize;
  }
  .container {
    padding: 0 15px;
    background-color: transparent !important;
  }
  @include MQ(M) {
    min-height: 330px;
    padding: 120px 0;
  }
}
