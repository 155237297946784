// Vacation page style

.wrapper {
  // min-height: 100%;
}

.defSec {
  padding: 25px 0 50px;

  .container {
    padding: 0 15px;
  }
}

.green-alert {
  background-color: getColor(timeline, light);
  color: getColor(info, dark);
  font-size: 20px;
  padding: 15px;
  margin: 0 -15px 15px -15px;

  .text-left, .text-right {
    display: inline-block;
  }

  .text-left {
    width: 78%;
  }

  .text-right {
    width: 20%;
  }

  @include MQ(M) {
    margin: 0 0 15px 0;
  }
}
.cd-btn-bottom {
  .btn-is-info {
    display: inline-block;
    width: 100%;
    margin-bottom: 10px;
    font-size: 18px;
    padding: 25px 15px;
    &:last-child {
      float: right;
    }
    @include MQ(M) {
      font-size: 16px;
      padding: 15px;
      width: 46%;
      margin-bottom: 0;
    }
  }
  .btn-delete, .btn-restore {
    width: 100%;
  }
}
.txt-is-blue {
  color: getColor(info, base) !important;
}
.radius-thumb {
  width: 60px;
  height: 60px;
  border-radius: 50px;
  display: inline-block;
  background-color: getColor(neutral, medium);
  overflow: hidden;
  img {
    height: 100%;
  }
}
.header-bck {
  min-height: 120px;
  background: {
    image: none;
    position: center;
    size: cover;
  }
  color: getColor(txt, lightest);
  padding: 40px 0;
  h2 {
    font: {
      size: 52px;
      weight: 300;
    }
    text-transform: capitalize;
  }
  .container {
    padding: 0 15px;
    background-color: transparent !important;
  }

  @include MQ(M) {
    min-height: 330px;
    padding: 120px 0;
  }
}

#cd-overview {
  .container {
    background-color: getColor(bg, light);
  }
  h3 {
    font: {
      size: 28px;
      weight: 300;
    }
    color: getColor(info, dark);
    margin: 10px 0;
  }
  p, .small {
    font-size: 16px;
  }
  .header-bck {
    background-image: url("#{$assets_path}/img/sailogy-overview.jpg");
  }
  .tab-gallery {
    margin: 0 0 25px;
  }
  .tab-gallery-vacation {
    padding-right: 0;
    padding-left: 0;
  }
  .gallery-head {
    background-color: getColor(neutral, bg);
    padding: 15px;
    .section-head {
      padding: 0 0 10px 0;
      margin: 0;
    }
    .features-inline-list {
      margin: 0;
      li {
        padding-left: 0;
        padding-right: 0;
        &:last-child {
          margin-left: 15px;
        }
      }
    }
    @include MQ(L) {
      padding: 15px 25px;
      .features-inline-list {
        float: right;
        padding: 14px 0 10px 0;
      }
    }
  }
  .details-tab {
    > li {
      text-align: center;
      font-size: 24px;
      margin-bottom: 0;
      background-color: getColor(neutral, bg);
      color: getColor(info, base);
      border-right: 1px solid getColor(border, input);
      border-bottom: 1px solid getColor(border, input);
      a {
        color: getColor(info, base);
        font-weight: 400;
        font-size: 18px;
        border-color: transparent;
        padding: 20px 10px;
        &:hover {
          border-color: transparent;
          color: getColor(txt, lightest);
          background-color: getColor(info, base);
        }
      }
      &:hover, &:focus {
        background-color: getColor(txt, lightest);
        a {
          color: getColor(info, base);
          background-color: getColor(txt, lightest);
        }
      }
      &.active {
        box-shadow: 0 0 0;
        border-right: 1px solid getColor(border, input) !important;
        background-color: white;
        a {
          color: getColor(info, base);
          border-color: transparent;
          background-color: white;
          border-radius: 0;
          &:hover {
            color: getColor(info, base);
            background-color: getColor(bg, light);
          }
        }
      }
      &:last-child {
        border-right: 0 !important;
      }
    }
  }
  .details-tab-content {
    padding: 35px 15px;
    @include clearfix;
    .tab-pane {
      @include clearfix;
    }
    p {
      font-size: 16px;
    }
    h5 {
      font-size: 16px;
      font-weight: 400;
      margin: 10px 0;
    }
    .bottom-btn {
      margin-top: 15px;
    }
  }
  .users-list {
    background-color: lighten(getColor(neutral, bg), 3%);
    margin: 0 -30px;
    padding: 15px 30px 30px;
    .cd-list {
      border-bottom: 1px solid getColor(neutral, base);
      margin-bottom: 15px;
      margin-top: 15px;
    }
    .friends-list {
      margin-top: 0;
      margin-bottom: 0;
      font-size: 18px;
      li {
        margin: 0 0 10px 0;
      }
    }
    .u-status {
      display: inline-block;
      min-width: 110px;
      font-size: 16px;
      font-weight: 600;
      &.join {
        color: getColor(success, medium);
      }
      &.waiting {
        color: getColor(neutral, base);
      }
    }
    .u-name {
      display: inline-block;
    }
  }
  .docs-list {
    padding-top: 15px;
    padding-bottom: 15px;
    margin-bottom: 0;
    color: getColor(info, base);
    a {
      font-size: 16px;
      color: getColor(info, base);
    }
    li {
      margin: 10px 0;
    }
  }
  .contact-manager-box {
    background-color: lighten(getColor(neutral, bg), 3%);
    margin: 0 -15px;
    padding: 15px 15px 30px;
    .manager-data {
      background-color: getColor(neutral, bg);
      margin: 15px -15px;
      padding: 15px 15px;
      .small {
        display: block;
        color: getColor(neutral, lighter);
        font-size: 16px;
      }
    }
    .manager-lang {
      margin: 15px -30px;
      padding: 0 30px;
      font-size: 16px;
      .small {
        color: getColor(neutral, lighter);
      }
    }
    .star-user {
      color: getColor(info, base);
      font-size: 16px;
    }
  }
  .cd-contact {
    margin: 0 -15px;
    padding: 15px;
    background-color: getColor(danger, base);
    color: getColor(txt, lightest);
    @include clearfix;
    font-size: 16px;
    h3, a {
      color: getColor(txt, lightest);
    }
    a:hover {
      color: getColor(txt, lightest);
      text-decoration: underline;
    }
    .cd-contact-data {
      margin-top: 25px;
    }
    .group {
      margin-bottom: 10px;
      [class^=icon-] {
        display: inline-block;
        width: 26px;
        @include MQ(M) {
          width: 30px;
        }
      }
      .phone {
        display: inline-block;
        width: 100%;
        padding-left: 30px;
        &.first {
          width: calc(100% - 32px);
          padding-left: 0;
        }
        @include MQ(M) {
          padding-left: 40px;
          &.first {
            width: calc(100% - 40px);
          }
        }
      }
    }
  }
  .cd-booking-code {
    border-top: 1px solid getColor(neutral, bg);
    .section-head {
      margin: 10px 0 15px;
    }
    .help-block {
      font-size: 16px;
    }
  }
  @include MQ(M) {
    .cd-booking-code {
      margin-right: -15px;
      margin-left: -15px;
      .lightgray-bg-box {
        padding-left: 15spx;
        padding-right: 15spx;
      }
    }
    .firstRow {
      height: 160px;
    }
  }
}
#overview-sidebar {
  .cd-social {
    display: block !important;
    padding-left: 0;
    padding-top: 0;
    ul {
      width: 100%;
      clear: left;
      margin-left: 0;
    }
    li {
      font-size: 28px;
    }
    .section-head {
      margin: 10px 15px 40px;
      padding: 0;
    }
    @include MQ(M) {
      padding-top: 0 !important;
      margin: 0 -15px;
      .section-head {
        margin-left: 25px;
        margin-bottom: 20px;
      }
    }
    @include MQ(XL) {
      .section-head {
        margin: 10px 15px 40px 25px;
      }
    }
  }
}
.cd-location-carousel {
  position: relative;
  .caption-location {
    position: absolute;
    left: 0;
    top: 10px;
    width: auto;
    min-width: 150px;
    display: inline-block;
    background-color: rgba(black, .5);
    color: getColor(txt, lightest);
    z-index: 2;
    padding: 10px 15px;
    span {
      display: block;
      font-size: 18px;
      font-weight: 300;
      &:first-child {
        font-size: 22px;
      }
    }
    @include MQ(M) {
      top: 40px;
      padding: 15px 20px;
      min-width: 270px;
      span {
        font-size: 22px;
        &:first-child {
          font-size: 26px;
        }
      }
    }
  }
}
#cd-customization {
  padding: 0 0 50px;
  .container {
    padding: 0 15px;
  }
  .header-bck {
    background-image: url("#{$assets_path}/img/sailogy-customization.jpg");
  }
}
#cd-trip {
  .container {
    padding: 0 15px;
  }
  .header-bck {
    background-image: url("#{$assets_path}/img/sail-trip-preparation.jpg");
  }
  .cd-voucher {
    padding-bottom: 0;
    padding-top: 0;
    margin-bottom: 0;
    [class^="col-"] {
      padding-left: 0;
      padding-right: 0;
    }
    .container {
      padding: 0;
    }
    .cd-link {
      margin: 25px auto 35px;
      .btn {
        width: 100%;
        max-width: 260px;
      }
    }
    .section-head {
      margin-top: 25px;
    }
    .add-brd {
      border-bottom: 1px solid getColor(neutral, base);
    }
    @include MQ(L) {
      margin-top: 0;
      [class^="col-"] {
        padding: 20px 50px;
      }
      .add-brd {
        border-right: 1px solid getColor(neutral, base);
        border-bottom: 0;
      }
    }
  }
}
#cd-map-location, .cd-map-location {
  .cd-map {
    margin-left: -30px;
    margin-right: -30px;
    overflow: hidden;
    img {
      width: 100%;
    }
  }
  .cd-fly-section {
    background-color: getColor(info, fly);
    color: getColor(txt, lightest);
    margin: 10px -30px 0;
    padding: 15px 25px;
  }
  @include MQ(M) {
    .cd-map, .cd-fly-section {
      margin-left: -15px;
      margin-right: -15px;
    }
  }
  @include MQ(L) {
    .cd-map {
      padding-right: 0;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 15px;
      right: 0;
      height: 100%;
      margin-right: 15px;
      img {
        height: 100%;
      }
    }
    .cd-fly-section {
      margin-top: 10px;
      margin-left: -30px;
      margin-right: -30px;
    }
  }
}
.row.inactive {
  label, input, textarea, select, .inactive-content {
    @include opacity(.5);
  }
  .inactive-del {
    text-decoration: line-through;
  }
}
.invite-on-board .services-included aside div {
    vertical-align: top;  // icon position when error is shown
}
