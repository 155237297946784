#input-checkin.form-group,
#input-checkout.form-group,
#input-people.form-group,
#sort-order {
  position: relative;
  overflow: hidden;
  border: 1px solid getColor(border, input);
}
.header-search {
  background: rgba(getColor(info, base), .8);
  & > .container:first-child {
    padding: 12px 20px;
  }
  .form {
    width: 100%;
    @include clearfix;
    overflow: visible!important;
  }
  .form-group {
    position: relative;
    float: left;
    height: 43px;
    margin: 0 1.3% 0 0;
    box-sizing: border-box;
    border: 1px solid getColor(border, input);
    &:last-child {
      margin-right: 0;
    }
    i.left, i.right {
      position: absolute;
      top: 10px;
      left: 18px;
      z-index: 10; // need for selectpicker
      color: lighten(getColor(neutral, medium), 8.5%);
      font-size: 20px;
      pointer-events: none;
    }
    input, select, .calendar_text {
      font: {
        size: 16px;
        weight: 400;
      }
      position: absolute;
      height: 41px;
      padding-left: 50px;
      color: getColor(neutral, color);
      border: 0;
      border-radius: 0;
      background: getColor(bg, light);
      background: rgba(white, .95);
      appearance: none;
    }
    select {
      line-height: 22px;
    }
    input, select, .calendar_text, button {
      &:focus {
        background-color: getColor(txt, even);
      }
    }
    .bootstrap-select .selectpicker {
      position: static;
    }
    .dropdown-menu {
      .selectpicker {
        padding: 0;
        height: auto!important;
        .text { margin-right: 5px }
      }
    }
    .calendar_text {
      line-height: 31px;
      font-size: 14px;
      cursor: pointer;
    }
    &#input-destination {
      width: 31%;
    }
    &#input-checkin,
    &#input-checkout {
      width: 19.5%;
    }
    &#input-people {
      width: 20.5%;
      .bootstrap-select {
        height: 41px;
        padding: 0;
        border: 0;
        border-radius: 0;
        background-color: transparent;
        .selectpicker {
          height: 41px;
          width: 233px;
          .caret {
            position: absolute;
            right: 0;
            top: 0;
            height: 41px;
            width: 36px;
            background: getColor(neutral, light) url('#{$assets_path}/img/selectpicker-arrow.png') no-repeat center center;
            border: 0;
            border-left: 1px solid getColor(neutral, base);
          }
        }
      }
    }
  }
  .header-search-submit {
    width: 100%;
    height: 43px;
    padding: 0;
    font-size: 22px;
    color: getColor(info, base);
    background: getColor(bg, light) none;
    border-radius: 0;
    @include MQ(M) {
      width: 4%;
    }
    span {
      display: block;
      @include MQ(M) {
        display: none;
      }
    }
    &:focus {
      background-color: getColor(txt, even);
    }
  }
  @media only screen and (max-width: $screen-xs-max) {
    & > .container:first-child {
      padding: 20px;
      background-color: getColor(bg, light);
    }
    .form-group {
      float: none;
      margin: 0 0 20px 0;
      width: 100%!important;
    }
    .header-search-submit {
      height: auto;
      padding: 18px;
      line-height: 1;
      color: getColor(txt, lightest);
      background: getColor(info, base);
      font: {
        size: 20px;
        weight: 400;
      }
      &:hover {
        background-color: darken(getColor(info, base), 10%);
      }
      &:before {
        content: '';
      }
      span {
        display: inline;
      }
    }
  }
}
.section-search {
  @include MQ(mM) {
    padding-top: 0;
  }
  .panel-group {
    .panel {
      border-radius: 0;
    }
    .panel-heading {
      position: relative;
      overflow: hidden;
      padding: 20px;
      font-size: 15px;
      cursor: pointer;
      background-color: getColor(txt, lightest);
      &:hover {
        background-color: darken(getColor(bg, light), 10%);
      }
      span {
        font: {
          size: 18px;
          weight: 600;
        }
      }
      .nearest-marina {
        opacity: 0;
        float: right;
        margin-top: 3px;
        padding-right: 40px;
        font-size: 13px;
        line-height: 1;
        br { display: none }
      }
      &:after {
        content: '';
        position: absolute;
        right: 17px;
        top: 24px;
        @include triangleDown(getColor(info, regular));
      }
      &.collapsed {
        &:after {
          @include triangleUp(getColor(info, regular));
        }
        .nearest-marina {
          opacity: 1;
        }
      }
    }
    .panel-heading + .panel-collapse > .panel-body {
      border: 0;
    }
    .panel-body {
      padding: 0;
      table {
        width: 100%;
        font-weight: 300;
        tr {
          th {
            padding: 14px 10px 13px 40px;
            font-size: 15px;
            font-weight: normal;
          }
          td {
            padding: 14px 40px 13px 10px;
            font-size: 13px;
          }
          a {
            color: getColor(txt, base);
            &:hover {
              text-decoration: underline;
            }
          }
        }
        tr:nth-child(even) {
          th, td {
            background-color: getColor(neutral, bgEven);
          }
        }
      }
    }
  }
  .no-results-wrapper {
    margin-bottom: 20px;
    padding: 20px 30px;
    background: {
      color: #4ab2b8;
      image: url('#{$assets_path}/img/bg-sea-with-sailing-ship.jpg');
      repeat: no-repeat;
      position: left top;
      size: 100% 220px;
    }
    h1 {
      width: 75%;
      height: 153px;
      margin: 0;
      font: {
        size: 30px;
        weight: 400;
      }
      line-height: 1.3;
      color: getColor(info, base);
      span, em {
        font-weight: 400;
      }
    }

    h2 {
      margin: 30px 0 20px 0;
      /*color: getColor(txt, lightest);
      font: {
        weight: 400;
        size: 30px;
      }*/
    }
    ul {
      padding: 0;
      list-style: none none;
      li {
        display: block;
        margin-bottom: 20px;
        line-height: 1.2;
        color: getColor(neutral, color);
        font: {
          weight: 600;
          size: 18px;
        }
        a {
          display: block;
          padding: 18px 56px;
          color: getColor(neutral, color);
          background-color: getColor(txt, lightest);
          &:hover {
            background-color: getColor(neutral, base);
          }
        }
        span {
          font: {
            size: 15px;
            weight: 300;
          }
        }
      }
      &:nth-last-of-type(1) li:last-child {
        margin-bottom: 0;
      }
    }
  }
  background: getColor(neutral, bg);
  #filters-box.fixed-box {
    height: auto;
    top: auto;
    overflow: auto;
    position: relative;
    @include MQ(L) {
      position: fixed;
      overflow-x: hidden;
      overflow-y: auto;
    }
  }
  .navbar-scrollable {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1030;
    @include MQ(mM) {
      position: static;
    }
  }
  &.white-label {
    padding-top: 82px;
    footer {
      display: none;
    }
    .navbar {
      margin-top: -80px;
      &.navbar-scrollable {
        top: 0;
      }
    }
  }
  .portal-column-left > .portlet:last-child {
    margin-bottom: 100px!important;
  }
  .filters-header {
    display: none;
  }
  .filters-buttons {
    margin-bottom: 20px;
    padding: 20px 20px 25px 20px;
    background-color: getColor(txt, lightest);
    .btn {
      width: 100%;
    }
  }
  #boats-type-selector { // Wide switcher of boat types
    width: 100%;
    margin: 0 0 23px 0;
    display: table;
    label {
      vertical-align: middle;
      display: table-cell;
      width: 33.33%;
      padding: 0;
      margin: 0;
      color: getColor(neutral, color);
      font: {
        size: 16px;
        weight: 600;
      }
      padding: 16px 2px;
      background: getColor(txt, lightest);
      @include MQ(mS) {
        padding: 0 3px;
        height: 90px;
      }
      float: none;
      border: {
        top: 0;
        bottom: 0;
        left: 0;
        right: 1px solid getColor(border, base);
      }
      &:last-child {
        border-right: 0;
      }
      &:hover, &.active {
        border-right-color: getColor(info, base);
        color: getColor(txt, lightest);
        background: getColor(info, base);
      }
      span {
        font-weight: normal;
        opacity: .8;
      }
    }
    .btn:active, .btn.active {
      box-shadow: none;
    }
  }
  .page-description {
    position: relative;
    margin: 0 0 25px 0;
    font-size: 13px;
    padding-left: 15px;
    @include MQ(mM) {
      display: none;
    }
    h2 {
      display: inline;
      font-size: 16px;
      font-weight: bold;
    }
    .close {
      float: right;
      margin: 0 0 15px 10px;
      cursor: pointer;
      font-size: 23px;
      color: getColor(neutral, search);
      line-height: 1;
      opacity: 1;
      text-shadow: none;
      &:hover { color: getColor(neutral, medium) }
    }
  }
  .skipper-sleep-place { // Filters board
    margin-bottom: 25px;
    padding: 10px;
    text-align: center;
    border: 1px solid getColor(neutral, search);
    border-width: 1px 0;
    h5 {
      margin-right: 1em;
      display: inline;
      font-weight: bold;
    }
    label {
      margin-left: 0.5em;
      font-weight: normal;
    }
  }
  .filters-board {
    display: table;
    width: 100%;
    margin-bottom: 25px;
    padding: 10px 15px 10px 5px;
    border: 1px solid getColor(bg, light);
    background-color: darken(getColor(neutral, bg), 5%);
    & > div {
      display: table-cell;
      width: 33.33%;
      vertical-align: middle;
    }
    .views {
      @include MQ(mM) {
        display: none !important;
      }
      a {
        display: inline-block;
        width: 49%;
        padding-left: 10px;
        white-space: nowrap;
        color: lighten(getColor(neutral, focus), 26%);
        font-size: 13px;
        line-height: 22px;
        &:hover {
          color: lighten(getColor(neutral, medium), 8.5%);
        }
        &.view-gallery {
          padding-right: 10px;
        }
        &.selected {
          font-weight: 600;
          color: lighten(getColor(neutral, medium), 8.5%);
        }
        i {
          vertical-align: middle;
          margin-right: 3px;
          font-size: 22px;
        }
        &.view-list {
          padding-left: 10px;
          @include MQ(mXL) {
            padding-left: 5px;
          }
          i {
            font-size: 26px;
          }
        }
      }
    }
    #sort-order {
      margin-bottom: 0;
      select {
        height: 33px;
        line-height: 33px;
        padding: 0 35px 0 10px;
        font-size: 13px;
      }

      &::after {
        height: 36px;
      }
    }
    .price-type-selector {
      text-align: right;
      .btn-group {
        label {
          padding: 10px 3px;
          color: getColor(neutral, color);
          font-size: 13px;
          line-height: 1;
          background-color: getColor(txt, lightest);
          border-color: getColor(border, base);
          &:hover, &.active {
            color: getColor(txt, lightest);
            background-color: getColor(info, regular);
          }
          &.active {
            font: {
              weight: 600;
            }
            cursor: default;
          }
          @include MQ(XL) {
            &:first-child {
              min-width: 105px;
            }
            &:last-child {
              min-width: 115px;
            }
          }
          span {
            font-weight: normal;
          }
          &:first-child, &:last-child {
            @include MQ(mL) {
              min-width: 0;
              padding-left: 5px;
              padding-right: 5px;
            }
          }
        }
        .btn:active, .btn.active {
          box-shadow: none;
        }
      }
    }
  }
  .search-results-wrapper {
    width: 100%;
    padding: 0 15px;
    .row {
      padding: 0;
      margin-bottom: 22px;
      background-color: getColor(txt, lightest);
    }
    .col1, .col2, .col3 {
      @include MQ(mL) {
        float: left;
      }
      @include MQ(mM) {
        width: auto;
        float: none;
      }
    }
    .col1 {
      padding: 10px 5px 0 15px;
      font-size: 12px;
      color: getColor(neutral, color);
      @include MQ(mL) {
        padding-right: 10px;
        width: 25%;
      }
      @include MQ(mM) {
        width: 100%;
        font-size: 14px;
      }
      h3 {
        position: absolute;
        margin: 2px 0 10px 0;
        white-space: nowrap;
        font: {
          size: 20px;
          weight: 600;
        }
        @include MQ(mM) {
          position: static;
          white-space: normal;
        }
        a {
          color: getColor(attention, base);
          &:hover {
            opacity: .9;
          }
        }
      }
      .logo {
        position: relative;

        @include MQ(mM){
          top: 10px;
          right: 15px;
          position: absolute;
        }

        img {
          max-width: 100%;
          max-height: 100%;
        }
      }
      .location {
        position: relative;
        margin-top: 30px;
        padding: 0 0 10px 18px;
        font-size: 12px;
        border-bottom: 1px solid getColor(border, base);
        @include MQ(mM) {
          margin-top: 0;
          font-size: 14px;
        }
        span {
          position: absolute;
          left: 0;
          top: 0;
          color: getColor(neutral, txt);
          font-size: 15px;
          vertical-align: middle;
        }
      }
      .ship-marks {
        margin: 0;
        padding: 10px 0 0 0;
        overflow: hidden; // clearfix
        list-style: none none;
        border-bottom: 1px solid getColor(border, base);
        li {
          float: left;
          margin-bottom: 8px;
          padding-right: 15px;
          font-size: 12px;
          line-height: 16px;
          white-space: nowrap;
          @include MQ(mM) {
            font-size: 14px;
          }
          i { // icons
            margin-right: 3px;
            color: getColor(info, regular);
            font-size: 16px;
            vertical-align: top;
          }
        }
      }
      .ship-description {
        padding: 8px 0;
        line-height: 1.2;
        border-bottom: 1px solid getColor(border, base);
      }
      .ship-properties {
        margin: 0;
        padding: 20px 0 10px 0;
        overflow: hidden;
        list-style: none none;
        @include MQ(mM) {
          margin-bottom: 20px;
        }
        li {
          float: left;
          box-sizing: border-box;
          padding: 0 10px 10px 0;
          font-size: 12px;
          white-space: nowrap;
          width: 50%; // for outdated IEs
          height: 30px;
          overflow: hidden;
          @include MQ(mM) {
            width: 33.33%!important;
          }
          &:nth-child(odd) {
            width: 65%;
          }
          &:nth-child(even) {
            width: 35%;
          }
          i {
            margin-right: 5px;
            color: getColor(neutral, txt);
            font-size: 16px;
            line-height: 1;
            vertical-align: middle;
          }
          &.ship-type {
            i {
              font-size: 20px;
              @include MQ(mM) {
                font-size: 16px;
              }
            }
          }
        }
      }
    }
    .col2 {
      padding-top: 43px;
      padding-bottom: 5px;
      padding-left: 0;
      @include MQ(mL) {
        width: 41.6666%;
        padding-left: 10px;
      }
      @include MQ(mM) {
        padding-top: 0;
        width: 100%;
        padding-left: 15px;
      }
      // .open-slider {
      //   transition-duration: .2s;
      //   display: block;
      //   padding: 3px 0;
      //   background: getColor(info, base);
      //   color: getColor(txt, lightest);
      //   text-align: center;
      //   font-size: 12px;
      //   &:hover {
      //     opacity: .8;
      //   }
      // }
      // .ship-multimedia-tabs {
      //   font-size: 12px;
      //   border: 0;
      //   li {
      //     width: 50%;
      //     margin: 0;
      //     a {
      //       margin: 0;
      //       padding: 12px 3px;
      //       text-align: left;
      //       white-space: nowrap;
      //       line-height: 16px;
      //       color: getColor(neutral, lighter);
      //       border-radius: 0;
      //       background-color: transparent;
      //       border: 0;
      //       display: table;
      //       width: 100%;
      //       i {
      //         display: table-cell;
      //         padding: 0 3px 0 7px;
      //         vertical-align: bottom;
      //         font-size: 16px;
      //       }
      //     }
      //     a:hover {
      //       color: getColor(neutral, color);
      //     }
      //     &.active a {
      //       color: getColor(neutral, color);
      //       background-color: getColor(neutral, bg);
      //     }
      //   }
      // }
      .ship-multimedia {
        position: relative;
        .slick-slider {
          margin-bottom: 0;
          .slick-slide {
            position: relative;
            @include aspect-ratio(3, 2);
            @include MQ(M) {
              @include aspect-ratio(100, 74);
            }
            @include MQ(L) {
              @include aspect-ratio(100, 74);
            }
            @include MQ(XL) {
              @include aspect-ratio(100, 60);
            }
            .canvas > div {
              .video-play-link {
                &::before {
                  font-size: 50px;
                }
                &::after {
                  width: 20px;
                  height: 20px;
                }
              }

            }
          }
        }
        a {
          &.open-slider {
            width: auto;
            bottom: -43px;
            height: 43px;
            padding: 9px 12px;
          }
        }
        .icon {
          margin: 0;
          float: none;
        }
        .carousel {
          @include MQ(mM) {
            padding-bottom: 43px;
          }
          .slick-counter, .slick-dots {
            height: 43px;
            bottom: -43px;
            padding: 11px 55px 11px 12px;
            @include MQ(mM) {
              padding: 12px;
              margin-bottom: 43px;
            }

            li {
              margin: 0 5px;
            }
          }
          // img {
          //   width: 100%;
          //   cursor: pointer;
          // }
          .slick-prev {
            left: 10px;
            transform: rotate(180deg);
          }
          .slick-next {
            right: 55px;
            @include MQ(mM) {
              right: 10px;
            }
          }
          .slick-prev, .slick-next {
            height: 40px;
            bottom: -40px;
            @include MQ(mM) {
              bottom: 2px;
            }
            &:before {
              content: "\e659";
              font-family: 'sailogy3';
              color: getColor(txt, lightest);
              font-size: 27px;
              opacity: 1;
              @include MQ(mM) {
                font-size: 32px;
              }
            }
            &:hover {
              &:before {
                opacity: .6;
              }
            }
          }
        }
      }
    }
    .col3 {
      padding: 10px 15px 5px 5px;
      @include MQ(mL) {
        width: 33.3333%;
      }
      @include MQ(mM) {
        width: 100%;
        padding-left: 15px;
      }
      .price-block {
        display: block;
        overflow: hidden;
        padding: 8px;
        font-size: 13px;
        line-height: 1;
        color: getColor(txt, base);
        border: 1px solid getColor(neutral, base);
        .caption span {
          padding-top: 34px;
        }
        @include MQ(mL) {
          padding: 7px 5px;
        }
        @include MQ(mM) {
          padding: 10px 12px;
        }
        &:hover {
          background-color: lighten(getColor(info, base), 36%);
        }
        .price {
          display: block;
          padding-top: 5px;
          font: {
            size: 20px;
            weight: 600;
          }
        }
        .discount {
          float: right;
          margin: 0 3px;
          padding: 12px 4px;
          background-color: getColor(txt, lightest);
          border: 1px solid getColor(neutral, base);
          border-radius: 50%;
          width: 42px;
          height: 42px;
          overflow: hidden;
          text-align: center;
          font: {
            size: 15px;
            weight: 600;
          }
          @include MQ(mL) {
            margin-right: 0;
            font-style: 14px;
          }
          &:after {
            content: '%';
            font: {
              weight: 300;
            }
          }
        }
      }
      .alert-block {
        padding: 12px;
        color: getColor(txt, lightest);
        font-size: 14px;
        line-height: 1.3;
        background-color: getColor(attention, base);
        a {
          color: getColor(txt, lightest);
          text-decoration: underline;
        }
      }
      .no-date-best-price {
        display: block;
        position: relative;
        margin: 0 0 7px 0;
        padding: 45px 35px;
        text-align: center;
        font-size: 15px;
        color: getColor(neutral, color);
        line-height: 1.2;
        font-weight: 400;
        border: 1px solid getColor(neutral, base);
        &:hover {
          color: getColor(txt, darkest);
          opacity: .8;
        }
        strong {
          display: block;
          margin: 25px 0 35px 0;
          font: {
            size: 20px;
            weight: 600;
          }
        }
        span {
          font-size: 13px;
          /*border-bottom: 1px solid getColor(neutral, search);*/
        }
      }
      h5 {
        margin: 5px 0;
        display: block;
        text-align: center;
        font-size: 12px;
        font-weight: normal;
        @include MQ(mM) {
          &.as-clickable-heading {
            margin: 10px 0 0 0;
            padding: 12px;
            color: getColor(info, base);
            font-size: 15px;
            text-align: left;
            cursor: pointer;
            border: 1px solid getColor(neutral, base);
            &:hover {
              background-color: getColor(neutral, bg);
            }
          }
        }
        span {
          padding-right: 5px;
          font-size: 9px;
        }
      }
      .dates-list {
        width: 100%;
        padding: 0;
        margin: 0 0 8px 0;
        list-style: none none;
        font-size: 12px;
        @include MQ(mL) {
          font-size: 10px;
        }
        @include MQ(mM) {
          font-size: 13px;
        }
        li {
          display: table;
          width: 100%;
          // margin-bottom: 3px;
          padding: 4px 5px;
          border: 1px solid rgba(black, .2);
          border-top: 0;
          @include MQ(mL) {
            padding: 5px;
          }
          &:first-child {
            border-top: 1px solid rgba(black, .2);
          }
          &:hover {
            background: lighten(getColor(info, base), 36%);
          }
          &.sold-out {
            // color: lighten(getColor(neutral, focus), 25%);
            background: rgba(black, .2);
          }
          & > * {
            display: table-cell;
            vertical-align: middle;
            color: getColor(txt, base);
          }
          .col-dates {
            white-space: nowrap;
          }
          .col-discount {
            padding: 0 2px;
            text-align: center;
          }
          .col-price {
            width: 35%;
            font: {
              size: 1.1em;
              weight: 600;
            }
            text-align: right;
            white-space: nowrap;
          }
        }
      }
      .btn {
        width: 100%;
        i {
          font-size: 15px;
          vertical-align: bottom;
          display: inline-block;
        }
      }
      .btn-viewmore {
        margin-bottom: 7px;
        padding: 12px 0;
        font-size: 14px;
        line-height: 15px;
        @include MQ(mM) {
          margin: 10px 0;
          font-size: 17px;
          line-height: 30px;
          i {
            font-size: 30px;
            margin-right: 10px;
          }
        }
        @include MQ(mS) {
          padding: 9px 0;
        }
      }
      .btn-enquiry {
        font-size: 11px;
        padding: 10px 16px;
        i {
          font-size: 15px;
          color: getColor(info, regular);
        }
        @include MQ(mM) {
          margin: 10px 0;
          font-size: 17px;
          line-height: 30px;
          i {
            font-size: 30px;
            margin-right: 10px;
          }
        }
        @include MQ(mS) {
          padding: 9px 0;
        }
        /*background-color: getColor(txt, lightest);
        @include MQ(mM) {
          display: none;
        }
        &:hover {
          background-color: getColor(neutral, search);
        }*/

      }
    }
  }
  #follow-search {
    .form-control {
      border: 1px solid getColor(neutral, base);
      box-shadow: inset 0 0 0;

      &:focus {
        border: 1px solid getColor(neutral, focus);
        box-shadow: 0 0 0;
      }
    }
  }
  padding-top: 0;
}
table.search-results-list {
  width: 100%;
  th, td {
    padding: 15px;
    text-align: center;
    border-bottom: 11px solid getColor(neutral, bg);
  }
  th {
    font-size: 14px;
    color: getColor(txt, lightest);
    font-weight: normal;
    background-color: getColor(info, base);
    border-right: 1px solid #90cbe4;
  }
  th:last-child,
  td:last-child {
    border-right: 0;
  }
  th.t-col5, th.t-col6, th.t-col7, th.t-col8 {
    font-size: 16px;
  }
  .t-col1, .t-col2, .t-col9 {
    text-align: left;
  }
  .t-col8, .t-col9, .t-col10 {
    white-space: nowrap;
  }
  .t-col5, .t-col6, .t-col7, .t-col8 {
    padding-left: 3px;
    padding-right: 3px;
  }
  td.t-col9 { // price
    font: {
      size: 16px;
      weight: 600;
    }
  }
  td.t-col10 {
    font-size: 19px;
    .icon-sailogy-exchange {
      color: getColor(neutral, search);
      &:hover {
        color: lighten(getColor(neutral, medium), 8.5%);
      }
    }
    .icon-sailogy-view-more {
      color: getColor(info, regular);
      &:hover { color: getColor(info, base); }
    }
  }
  td {
    vertical-align: middle;
    font-size: 12px;
    background-color: getColor(txt, lightest);
    border-right: 1px solid getColor(neutral, txt);
  }
  tr:nth-child(even) td {
    background-color: lighten(getColor(neutral, base), 6%);
  }
  td.t-col1 {
    width: 35%;
    padding: 5px 15px 5px 5px;
    h5 {
      margin: 5px 0 8px 0;
      font: {
        weight: 600;
        size: 14px;
      }
      a {
        color: getColor(txt, base);
        &:hover { color: getColor(attention, base) }
      }
    }
    img {
      float: left;
      margin-right: 15px;
    }
    ul {
      margin: 0;
      list-style: none none;
      li {
        display: inline-block;
        padding: 0 5px 3px 0;
        white-space: nowrap;
        font-size: 12px;
        i {
          margin-right: 2px;
          vertical-align: top;
          color: getColor(info, regular);
          font-size: 14px;
        }
      }
    }
  }
}
.table-container {
  width: 100%;
}
.fixed {
  display: none;
  width: auto!important;
  position: fixed;
  top: 67px;
  th {
    border-bottom: 0!important;
  }
}
#pac-input {
  padding: 8px 12px;
  margin-top: 16px;
  width: 400px;
  border: 1px solid getColor(border, input);
  border-radius: 0;
  height: 40px;
  background-color: getColor(txt, lightest);
  font-size: 16px;
  appearance: none;
  box-shadow: 0 2px 6px rgba(black, .3);
}
#pac-input:focus {
  border-color: lighten(getColor(info, base), 14%);
  margin-left: -1px;
  padding-left: 13px;
  width: 401px;
}
.pac-container {
  z-index: 1041;
}
.main-search-lm {
  margin-top: 15px;
}
#enlarged-map, #map {
  p.loadMap {
    margin-top: 0;
    margin-bottom: 0;
  }
}
.filters-content {

  @include MQ(L) {
    display: block!important;
  }
}


.gdv-partner {
  background: {
    image: url('#{$assets_path}/img/ico/manager.png');
    size: cover;
  }
  position: absolute;
  width: 104px;
  height: 105px;
  left: -4px;
  top: -5px;
  z-index: 1001;
  transform: rotate(270deg);
  span {
    display: block;
    width: 104px;
    height: 105px;
    text-align: center;
    color: white;
    padding-top: 32px;
    transform: rotate(45deg);
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
  }
}
