body.print-voucher, body.print {
  min-height: 100%;
  font-size: $main-text-size * .95;
  .wrapper {
    min-height: 100%;
    position: relative;
  }
  .cd-top {
    display: none;
  }
  p {
    margin: 8px 0;
  }
  .h3 {
    font-weight: bold;
    font-size: 20px;
    color: black;
    margin: 25px 0 20px;
  }
  .table-container {
    display: table;
    width: 100%;
    > * {
      display: table-cell;
      vertical-align: middle;
    }
  }
  .container {
    width: 210mm;
  }
  table {
    td {
      padding-bottom: 5px;
      width: 50%;
      p {
        font-size: 14px;
        margin: 0;
      }
    }
  }
  hr {
    margin: 10px 0;
  }
  header#main {
    white-space: nowrap;
    margin-bottom: 10px;
    #logo {
      padding-top: 15px;
      font-size: 50px;
      color: $colorDanger;
    }
    .h1 {
      padding-top: 17px;
      font: {
        weight: 600;
        size: 25px;
      }
    }
    .hdr-container {
      display: table;
      width: 100%;
      height: 100px;
      margin-left: 15px;
      .hdr {
        display: table-cell;
        vertical-align: middle;
        color: black;
        text-align: left;
        line-height: 26px;
        font: {
          weight: 600;
          size: 26px;
        }
      }
    }
    #code {
      padding-top: 10px;
      header {
        font: {
          weight: 200;
          size: 17px;
        }
      }
      section {
        font: {
          weight: 600;
          size: 35px;
        }
      }
    }
    #qr-code {
      img {
        width: 100px;
      }
    }
  }
  .hello {
    p {
      font-size: 14px;
      line-height: 18px;
      padding: 0;
      margin: 0 0 8px 0;
    }
  }
  section {
    &.data-table {
      table.table {
        width: 100%;
      }

      .table-container {
        height: 50px;
        > * {
          padding: 4px 5px 4px 10px;
          font-size: 13px;
          color: black;
        }
      }
    }
    &.booking {
      page-break-inside: avoid;
      .booking-table {
        font-size: 13px;
        padding-bottom: 20px;
        .row {
          border: 1px solid black;
          margin: 8px 0 8px 27px;
          > * {
            border-right: 1px solid black;
            &:last-child {
              border-right: 0;
            }
          }
        }
        .table-container {
          height: 100px;
        }
        aside {
          font-size: 15px;
          padding-left: 35px;
          .date {
            position: absolute;
            left: -28px;
            top: 26px;
            border-radius: 50%;
            border: 1px solid black;
            background-color: white!important;
            width: 55px;
            height: 55px;
            text-align: center;
            .inside {
              line-height: 12px;
              header {
                font-size: 12px;
                font-weight: 200;
              }
              footer {
                font-weight: 600;
                font-size: 14px;
              }
            }
          }
        }
        section {
          line-height: 15px;
          font-size: 15px;
          padding: 0 0 0 10px;
        }
        .hdr {
          font-weight: bold;
          padding: 0 0 5px;
          white-space: nowrap;
        }
      }
    }
    &.equipments {
      header {
        font-size: 18px;
        padding: 0 0 10px;
      }
      ul {
        margin: 0;
        li {
          font-size: 14px;
          padding: 3px 0 3px 0px;
        }
      }
    }
    &.main-info {
      img {
        margin-bottom: 25px;
      }

      ul {
        margin: 0;
        li {
          padding: 3px 0;
          font-size: 14px;
          span {
            min-width: 45px;
            display: inline-block;
            float: left;
            padding-right: 5px;
            hite-space:nowrap;
            &:after {
              content: ':';
              display: inline-block;
            }
          }
          b {
            display: inline-block;
          }
        }
      }
    }
    &#description {
      margin-bottom: 15px;
      article {
        white-space: nowrap;
        margin: 15px 0;
      }
    }
    &#payments, &.payments {
      padding-bottom: 15px;
      p {
        margin: 5px 0;
      }
      .tag {
        border: 1px solid black;
        background: rgba(black, .05);
        padding: 2px 4px;
        margin: 5px 0 0 5px;
        display: inline-block;
        font-size: 13px;
      }
    }
    &#footer {
      page-break-inside: avoid;
      .h4 {
        @extend .bold;
        padding: 0;
        margin: 0 0 5px 0;
        color: black;
        font-size: $main-text-size * 1.2;
      }
      p {
        font-size: $main-text-size * .8;
        margin: 0;
      }
    }
  }
  @page {
    size: 21cm 29.7cm;
    margin: 20mm 35mm 0mm 35mm;
  }
}
@media print {
  body.print-voucher, body.print {
    width: 210mm;
    height: 297mm;
  }
}
