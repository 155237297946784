// flags : flag-sprites.com

.flag {
  width: 32px;
  height: 32px;
  background: url('#{$assets_path}/img/ico/flags.png') no-repeat;
  &.flag-ad {background-position: -32px 0}
  &.flag-al {background-position: -64px 0}
  &.flag-at {background-position: -96px 0}
  &.flag-ba {background-position: -128px 0}
  &.flag-be {background-position: -160px 0}
  &.flag-bg {background-position: -192px 0}
  &.flag-by {background-position: -224px 0}
  &.flag-ch {background-position: 0 -32px}
  &.flag-cz {background-position: -32px -32px}
  &.flag-de {background-position: -64px -32px}
  &.flag-dk {background-position: -96px -32px}
  &.flag-ee {background-position: -128px -32px}
  &.flag-en {background-position: -32px -64px}  /* same as gb */
  &.flag-es {background-position: -160px -32px}
  &.flag-fi {background-position: -192px -32px}
  &.flag-fo {background-position: -224px -32px}
  &.flag-fr {background-position: 0 -64px}
  &.flag-gb {background-position: -32px -64px}
  &.flag-gi {background-position: -64px -64px}
  &.flag-gr {background-position: -96px -64px}
  &.flag-hr {background-position: -128px -64px}
  &.flag-hu {background-position: -160px -64px}
  &.flag-ie {background-position: -192px -64px}
  &.flag-il {background-position: -224px -64px}
  &.flag-is {background-position: 0 -96px}
  &.flag-it {background-position: -32px -96px}
  &.flag-li {background-position: -64px -96px}
  &.flag-lt {background-position: -96px -96px}
  &.flag-lu {background-position: -128px -96px}
  &.flag-lv {background-position: -160px -96px}
  &.flag-mc {background-position: -192px -96px}
  &.flag-md {background-position: -224px -96px}
  &.flag-me {background-position: 0 -128px}
  &.flag-mk {background-position: -32px -128px}
  &.flag-mt {background-position: -64px -128px}
  &.flag-nl {background-position: -96px -128px}
  &.flag-no {background-position: -128px -128px}
  &.flag-pl {background-position: -160px -128px}
  &.flag-pt {background-position: -192px -128px}
  &.flag-ro {background-position: -224px -128px}
  &.flag-rs {background-position: 0 -160px}
  &.flag-ru {background-position: -32px -160px}
  &.flag-se {background-position: -64px -160px}
  &.flag-si {background-position: -96px -160px}
  &.flag-sk {background-position: -128px -160px}
  &.flag-sm {background-position: -160px -160px}
  &.flag-tr {background-position: -192px -160px}
  &.flag-ua {background-position: -224px -160px}
  &.flag-us {background-position: 0 -192px}
  &.flag-va {background-position: -32px -192px}
}
