.cd-gray {
  background-color: getColor(neutral, bg);
  padding: 30px 0;
  .container {
    padding-left: 15px;
    padding-right: 15px;
  }
  h2 {
    color: getColor(info, dark);
    font: {
      size: 28px;
      weight: 300;
    }
    margin: 20px 0;
  }
  p, .p {
    margin: 10px 0;
    font-size: 16px;
    color: getColor(txt, light);
  }
  @include MQ(M) {
    padding: 50px 0;
  }
}
.cd-white {
  padding: 30px 0;
  .container {
    padding-left: 15px;
    padding-right: 15px;
  }
  p, .p {
    margin: 10px 0;
    font-size: 16px;
    color: getColor(txt, light);
  }
  header {
    margin-bottom: 25px;
  }
  h2 {
    color: getColor(info, dark);
    font: {
      size: 28px;
      weight: 300;
    }
    margin: 20px 0;
  }
  h3 {
    color: getColor(info, dark);
    font: {
      size: 22px;
      weight: 400;
    }
    margin: 10px 0;
  }
  @include MQ(M) {
    padding: 50px 0;
  }
}
.main-container {
  position:relative;
  min-height: 100%;
}
.padded-bottom-sm {
  padding-bottom: 6px;
}
.padded-bottom-md {
  padding-bottom: 12px;
}
.padded-bottom-lg {
  padding-bottom: 24px;
}
.padded-bottom-xl {
  padding-bottom: 48px;
}
.padded-top-sm {
  padding-top: 6px;
}
.padded-top-md {
  padding-top: 12px;
}
.padded-top-lg {
  padding-top: 24px;
}
.padded-top-xl {
  padding-top: 48px;
}
.padded-left-sm {
  padding-left: 6px;
}
.padded-left-md {
  padding-left: 12px;
}
.padded-left-lg {
  padding-left: 24px;
}
.padded-left-xl {
  padding-left: 48px;
}
.padded-right-sm {
  padding-right: 6px;
}
.padded-right-md {
  padding-right: 12px;
}
.padded-right-lg {
  padding-right: 24px;
}
.padded-right-xl {
  padding-right: 48px;
}
.padded-hor-mob {
  @include MQ(mM) {
    padding-left: 15px;
    padding-right: 15px;
  }
}

// sections

.bg-light {
  background-color: white;
}
.listed-darker2 {
  background: darken(getColor(neutral, bg), 10%);
}
.bg-neutral, .bg-grey, .listed {
  background: getColor(neutral, bg);
}
.bg-dark {
  background: darken(getColor(neutral, bg), 5%);
}
.bordered-top {
  border-top: 1px solid getColor(neutral, bg);
}
.bordered-bottom {
  border-bottom: 1px solid getColor(neutral, bg);
}

.row {
  &.hor-collapsed {
    @include MQ(mL) {
      margin-left: 0;
      margin-right: 0;
    }
  }
}

// Boxes
// taken from https://css-tricks.com/snippets/sass/maintain-aspect-ratio-mixin/
@mixin aspect-ratio($width, $height) {
  position: relative;
  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: ($height / $width) * 100%;
  }
  > .canvas {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

@mixin centerer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin image-block($width, $height, $background) {
  background: $background;
  position: relative;

  @include aspect-ratio($width, $height);

  .canvas > div {
    position: relative;
    width: 100%;
    height: 100%;

    img {
      max-width: 100%;
      max-height: 100%;
      @include centerer;
    }

    .videoWrapper {
      width: 100%;
      @include centerer;
    }

  }
}
