// Center block of any height vertically
@mixin vCenterBlock($vtop: 50%) {
  position: absolute;
  top: $vtop; // move up/down from center if needed
  transform: translateY(-50%);
}

// Center elements
@mixin center($xy:xy) {
  @if $xy == xy {
    left: 50%;
    top: 50%;
    bottom: auto;
    right: auto;
    transform: translateX(-50%) translateY(-50%);
  }
  @else if $xy == x {
    left: 50%;
    right: auto;
    transform: translateX(-50%);
  }
  @else if $xy == y {
    top: 50%;
    bottom: auto;
    transform: translateY(-50%);
  }
}

@mixin rotate($angle) {
  transform: rotate($angle);
}

@mixin clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

@mixin triangleUp($color: #2d2d2d) {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 7.5px 9px 7.5px;
  border-color: transparent transparent $color transparent;
  -webkit-transform: rotate(360deg);    // Chrome antialiasing
          transform: rotate(0.0005deg); // FF border antialiasing
}

@mixin triangleDown($color: #2d2d2d) {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 9px 7.5px 0 7.5px;
  border-color: $color transparent transparent transparent;
  -webkit-transform: rotate(360deg);    // Chrome antialiasing
          transform: rotate(0.0005deg); // FF border antialiasing
}

@mixin no-select {
  user-select: none;
}

// keyframes mixin
@mixin keyframes($name) {
  @keyframes #{$name} {
    @content;
  }
}

// Animation mixin
@mixin animations($delay, $duration, $animation, $fill) {
  animation-delay: $delay;
  animation-duration: $duration;
  animation-name: $animation;
  animation-fill-mode: $fill; /* this prevents the animation from restarting! */
}

// Colors
@function getColor($array, $color) { // getColor(success, light)
  @return map-get(map-get($colors, $array), $color);
}

// Layout Mixin
@mixin MQ($canvas) {
  @if $canvas == XS {
    @media only screen and (min-width: $XS) { @content; }
  }
  @else if $canvas == S {
    @media only screen and (min-width: $S) { @content; }
  }
  @else if $canvas == M {
    @media only screen and (min-width: $M) { @content; }
  }
  @else if $canvas == L {
    @media only screen and (min-width: $L) { @content; }
  }
  @else if $canvas == XL {
    @media only screen and (min-width: $XL) { @content; }
  }
  @else if $canvas == XXL {
    @media only screen and (min-width: $XXL) { @content; }
  }
  @else if $canvas == mS {
    @media only screen and (max-width: $S - 1) { @content; }
  }
  @else if $canvas == mM {
    @media only screen and (max-width: $M - 1) { @content; }
  }
  @else if $canvas == mL {
    @media only screen and (max-width: $L - 1) { @content; }
  }
  @else if $canvas == mXL {
    @media only screen and (max-width: $XL - 1) { @content; }
  }
  @else if $canvas == mXXL {
    @media only screen and (max-width: $XXL - 1) { @content; }
  }
}

// font size pixel to rem
@function calculateRem($size) {
  $remSize: $size / 16px;
  @return #{$remSize}rem;
}

@mixin font-size($size) {
  font-size: $size; //Fallback in px
  font-size: calculateRem($size);
}

// Width and height size
@mixin size($width, $height: $width) {
  width: $width;
  height: $height;
}
