body.magazine {
  .wrapper > section {
    font-size: 17px;
    h1, h2, h3, h4 {
      margin-left: 10px;
      margin-right: 10px;
    }
    h2 {
      font: {
        weight: 300;
        size: 28px;
      }
      margin-bottom: 10px;
      margin-top: 26px;
    }
    p {
      margin: 20px 12px;
      font-size: 18px;
      color: $h4-color;
      line-height: 29px;
      img {
        display: block;
        margin: 0 auto;
        max-width: 100%;
      }
      &.img-caption {
        padding-top: 10px;
        padding-bottom: 20px;
        color: getColor(txt, light);
      }
    }
    &.jumbotron {
      background: {
        repeat: no-repeat;
        size: cover;
        position: bottom center;
        attachment: scroll;
      }

      &:not(.imgix-fluid) {

        background-image: url('#{$assets_path}/img/bg/magazine/xl.jpg');
        @include MQ(mL) {
          background-image: url('#{$assets_path}/img/bg/magazine/l.jpg');
        }
        @include MQ(mM) {
          background-image: url('#{$assets_path}/img/bg/magazine/m.jpg');
        }
        @include MQ(mS) {
          background-image: url('#{$assets_path}/img/bg/magazine/s.jpg');
        }
      }

      @include MQ(mM) {
        padding-left: 0;
        padding-right: 0;
      }

      .icon-main {
        @include MQ(mM) {
          margin-top: 20px;
        }
      }
      #share {
        font: {
          weight: 200;
          size: 28px;
        }
        position: absolute;
        top: 80px;
        width: 100%;
        z-index: 15;
        @include MQ(mM) {
          display: none;
        }
        .title {
          margin-top: -4px;
        }
        * {
          color: getColor(txt, lightest);
        }
        li {
          padding-left: 25px;
        }
        a {
          font-size: 29px;
          &:hover {
            opacity: .8;
          }
        }
      }
    }
    &#foot-btn {
      padding: 0 0 10px;
      @include MQ(mM) {
        padding: 0 15px 10px;
      }
    }
    &.article {
      ul, ol {
        padding: 10px 0 0 75px;
        @include MQ(M) {
          padding: 10px 0 0 35px;
        }
        li {
          bottom: 0 0 7px 0;
          &:before {
            content: '\2022';
            float: left;
            margin: 0 10px 0 0;
            opacity: .7;
          }
        }
      }
      .destination-list {
        padding: 40px 15px 0;
      }
    }
    &#filters, &.filters {
      background: getColor(bg, light);
    }
  }
  .cd-slick-carousel {
    position: relative;
    font-size: 14px !important;
  }
  .carousel {
    img {
      max-height: 85vh;
    }
    .cd-slick-carousel {
      position: relative;
    }
  }
}

section {
  &#filters, &.filters {
    form {
      position: relative;
      margin: 10px 0;
      .form-group {
        position: relative;
        width: 100%;
        .form-control {
          padding: 0;
        }
        input[type=search] {
          padding: 0 50px 0 40px;
          display: block;
          width: 100%;
          line-height: 55px;
        }
        button {
          position: absolute;
          right: 0;
          top: 0;
          margin-left: -5px;
          line-height: 35px;
          @include MQ(mM) {
            padding: 10px 15px;
          }
        }
        .icon {
          position: absolute;
          left: 12px;
          top: 19px;
          opacity: .5;
        }
      }
    }
  }
}
