.gray-body {
  background-color: getColor(neutral, bg);
}

.bg-is-gray {
  background-color: getColor(neutral, bg);
}

.editorial-article {
  padding: 25px;
  background-color: white;
  margin-top: 0;

  .author {
    font-size: 14px;
    margin: 15px 0 25px;
  }

  @include MQ(M) {
    .author {
      float: right;
      margin: 0;
    }
  }
}

.article-aside {
  article {
    margin-top: 0;
  }
  .side-title {
    text-align: center;
    text-transform: uppercase;
    margin: 0;
    padding: 15px 0;
    background-color: white;
  }
}
.defSec {
  padding: 25px 0;
}
.has-top-mg {
  margin-top: 25px;
}
.no-margin {
  margin: 0 !important;
}
.no-padding {
  padding: 0 !important;
}
.h-padding {
  padding: 0 15px !important;
}
.margin-top {
  margin-top: 20px;
}
.margin-top-40 {
  margin-top: 40px;
}
.margin-top-10 {
  margin-top: 10px;
}
.block-center {
  position: absolute;
  @include center();
}
.section-head {
  margin: 15px 0;
  position: relative;
  h3, h4 {
    padding: 0 !important;
    margin: 10px 0;
    text-align: left !important;
  }
  h4 {
    font-weight: 400 !important;
    font-size: 18px !important;
  }
  h3 {
    font: {
      size: 28px;
      weight: 300;
    }
  }
}
.dark-separator {
  border-color: getColor(neutral, base);
}
.cd-separator {
  margin: 30px 0;
  height: 1px;
  display: block;
  width: 100%;
  clear: both;
  float: left;
  border-color: transparent;
}
.warning-bg-box {
  background-color: getColor(warning, base);
}

.gray-bg-box {
  background-color: getColor(neutral, bgLight);
  margin: 0 -15px 15px;
  padding: 15px;
  h4, .h4 {
    font: {
      size: 22px;
      weight: 400;
    }
    color: getColor(info, dark);
  }
  .container {
    padding: 0 15px;
  }
  @include MQ(M) {
    margin: auto;
  }
}
.lightgray-bg-box {
  @extend .gray-bg-box;
  background-color: lighten(getColor(neutral, bg), 3%);
}

.tab-type {
  color: getColor(txt, base);
  clear: both;
  font-size: 16px;
  width: 100%;
  display: table;
  &:not(.compact) {
    @include MQ(mL) {
      display: block;
      aside, section {
        display: block;
        width: 100%;
      }
      aside {
        padding: 8px 0 0;
      }
      section {
        padding: 0 0 8px;
      }
    }
  }
  aside, section {
    padding: 8px 0;
    display: table-cell;
    vertical-align: top;
    .icon {
      display: inline-block;
      padding-right: 7px;
    }
  }
  aside {
    color: getColor(neutral, lighter);
    width: 30%;
    &.wide {
      white-space: nowrap;
      width: 45%;
    }
  }
  section {
    &:not(.text-left) {
      text-align: right;
    }
  }
  .time {
    text-align: center;
    border: 1px solid getColor(neutral, lighter);
    padding: 0 20px;
    display: inline-block;
    width: 100%;
    cursor: pointer;

    &:hover, &:focus {
      border: 1px solid darken(getColor(neutral, lighter), 15%);
    }
  }
}
.text-muted {
  color: getColor(neutral, lighter);
}
.discount {
  display: inline-block;
  border: 1px solid getColor(danger, base);
  padding: 0 5px;
  border-radius: 15px;
  font-weight: 600;
  font-size: 14px;
  color: getColor(danger, base);
  margin-right: 5px;
}
.flexRow {
  @include MQ(L) {
    display: flex;
  }
}
.help-block {
  font-size: 16px;
  line-height: 22px;
  color: $help;
}
.strongFocus {
  color: getColor(info, fly);
  font-weight: 400;
}
.bookCode {
  font-size: 24px !important;
}
.inline-block-md {
  @include MQ(L) {
    display: inline-block;
  }
}
.more-indicator {
  display: inline-block;
  border-radius: 50px;
  width: 50px;
  height: 50px;
  border: 1px solid getColor(info, base);
  background-color: getColor(bg, light);
  position: absolute;
  right: 0;
  cursor: pointer;
  @include center(y);
  transition: background-color .2s;

  span {
    width: 50%;
    height: 2px;
    background-color: getColor(info, base);
    display: block;
    position: absolute;
    top: 24px;
    right: 12px;
    transition: all .2s;

    &.line-one {
      transform: rotate(90deg);
    }

    &.line-two {
      opacity: 1;
    }
  }

  &.is-minus {
    span {
      &.line-one {
        transform: rotate(180deg);
      }

      &.line-two {
        opacity: 0;
      }
    }
  }
}


.boat-related {
  height: 380px;
  max-width: 500px;
  transition: .1s;
  position: relative;
  background: white;
  overflow: hidden;
  margin: 0 auto 25px;
  @include MQ(mL) {
    height: 170px;
  }
  &:hover {
    @include MQ(L) {
      box-shadow: 0 5px 10px darken(white, 35%);
    }
  }

  .caption-discount {
    /*right: 10px;*/
    transform: rotate(-90deg);
    top: -20px;
    left: -20px;
    right: auto;

    @include MQ(L) {
      top: -15px;
      right: -15px;
      left: auto;
      transform: none;
    }

    span {
      padding-top: 27px;
      font-size: 18px;
    }
  }

  header {
    height: 200px;
    overflow: hidden;
    position: relative;
    background: {
      color: darken(white, 18%);
      image: url('#{$assets_path}/img/ico/boat-placeholder.png');
      position: center;
      repeat: no-repeat;
    }
    img, picture {
      min-width: 100%;
      min-height: 100%;
      max-height: 100%;
    }
    @include MQ(mL) {
      display: inline-block;
      width: 170px;
      height: 170px;
      vertical-align: top;
    }
  }
  footer {
    transition: .2s;
    position: absolute;
    background: white;
    top: 200px;
    left: 0;
    width: 100%;
    text-align: left;
    min-height: 100%;
    @include MQ(mL) {
      width: calc(100% - 170px);
      left: 170px;
      top: 0;
    }
    ul {
      margin: 0;
      li {
        display: table;
        width: 100%;
        color: getColor(neutral, bgDark);
        padding: 5px 0 5px 0;
        min-height: 40px;

        @include MQ(mM) {
          padding: 0 0 0 3px;
          &:nth-child(n+4) {
            display: none;
          }
        }
        &:nth-child(2) {
          height: 50px;
          display: block;
          overflow: hidden;
          padding: 3px 0 8px 0;
          @include MQ(mL) {
            padding-left: 3px;
          }
          aside {
            vertical-align: top;
            padding: 3px 0 0 0;
          }
          section {
            vertical-align: top;
          }
        }
        &:first-child {
          height: 70px;
          min-height: 70px;
          padding: 0 0 0 12px;
          @include MQ(mL) {
            height: 60px;
            min-height: 60px;
          }
          h3, .h3 {
            padding: 0;
            font-size: 22px;
            color: getColor(neutral, bgDark);
            margin: 0;
            display: table-cell;
            vertical-align: middle;
            @include MQ(mL) {
              font-size: 18px;
            }
            @include MQ(mS) {
              font-size: 16px;
            }
          }
        }
        &.price {
          height: 65px;
          min-height: 65px;
          aside {
            vertical-align: middle;
          }
          section, .section {
            font-size: 20px;
            vertical-align: middle;
            @include MQ(mL) {
              font-size: 18px;
              line-height: 20px;
            }
          }
        }
        aside {
          display: table-cell;
          vertical-align: middle;
          padding: 1px 0 0;
          text-align: center;
          width: 40px;
          @include MQ(mM) {
            width: 30px;
          }
          @include MQ(mXS) {
            width: 25px;
          }
        }
        section, .section {
          vertical-align: middle;
          font-size: 15px;
          line-height: 17px;
          display: table-cell;
          padding-right: 3px;
          @include MQ(mL) {
            padding-right: 0;
            font-size: 14px;
            line-height: 15px;
          }
        }

        &:not(.price) {
          padding-right: 8px;
        }
      }
    }
  }
  &:hover {
    footer {
      top: 0;
    }
  }
  &.sale {
    &:hover {
      ul {
        li {
          &.price {
            @include MQ(L) {
              background: getColor(danger, base);
              color: white;
            }
          }
        }
      }
    }
    ul {
      li {
        &.price {
          background: transparent;
          color: getColor(danger, base);
          padding: 0;
          transition: .1s;
          @include MQ(mL) {
            padding-left: 3px;
          }
          aside {
            vertical-align: top;
            padding-top: 18px;
            @include MQ(S) {
              padding-top: 15px;
              display: table-cell;
            }
          }
          section, .section {
            display: block;
            .old {
              font-size: 16px;
              opacity: .7;
              text-decoration: line-through;
              display: block;
              padding: 12px 0 5px 0;
              @include MQ(mM) {
                padding: 12px 0 0 0;
              }
            }
            .new {
              font-size: 20px;
              @include MQ(mM) {
                font-size: 18px;
              }
              @include MQ(mS) {
                font-size: 16px;
              }
            }
          }
        }
      }
    }
  }
}


.boat-fleet {
  height: 320px;
}

.featsItems {
  position: relative;
  .itemImg {
    height: 170px;
    overflow: hidden;
    background: getColor(bg, darkEven);
    z-index: 1;
    img, picture {
      width: 100%;
    }
  }
  .itemDetails {
    .num {
      display: block;
      text-align: center;
      margin-top: 15px;
      span {
        display: inline-block;
        @include size(50px, 50px);
        background-color: white;
        border-radius: 50%;
        line-height: 47px;
        border: 3px solid getColor(border, base);
        color: getColor(txt, light);
        font-weight: 600;
      }
    }
  }
}

.availabilityCalendar {
  margin-top: 10px;

  h4 {
    font: {
      size: 22px;
      weight: 300;
    }
    padding-bottom: 5px;
    border-bottom: 1px solid getColor(neutral, base);
  }

  .listCalendar {
    margin-top: 10px;

    li {
      color: getColor(info, base);
      font-size: 16px;
      margin: 5px 0;

      a {
        color: getColor(info, base);

        &:hover, &:focus {
          color: getColor(info, dark);
        }
      }

      &.busy {
        a {
          color: getColor(neutral, lighter);
          pointer-events: none;
        }
      }
    }
  }
  @include MQ(M) {
    .listCalendar {
      li {
        font-size: 14px;
      }
    }
  }
}
.info-question-mark {
  display: inline-block;
  width: 35px;
  height: 35px;
  line-height: 35px;
  color: getColor(info, fly);
  border-radius: 50px;
  border: 1px solid getColor(info, fly);
  font: {
    size: 24px;
    weight: 600;
  }
  text-align: center;
  vertical-align: middle;
  margin-left: 5px;
  &:hover, &:focus {
    color: getColor(info, base);
    border: 1px solid getColor(info, base);
  }
  @include MQ(M) {
    width: 18px;
    height: 18px;
    line-height: 18px;
    font-size: 14px;
    margin-left: 0;
  }
}
.hidden-data {
  display: none !important;
}
.rating-symbol.glyphicon {
  font-size: 18px;
  color: getColor(info, base);
  padding: 2px;
}
.cd-main-feats {
  text-align: center;
  .cd-icon {
    position: relative;
    padding-top: 25px;
    text-align: center;
    .icon {
      position: relative;
      display: inline-block;
      @include size(65px, 65px);
      border: 1px solid getColor(neutral, medium);
      border-radius: 50%;
    }
    span {
      font-size: 38px;
      line-height: 63px;
    }
  }
  @include MQ(M) {
    text-align: left;
  }
  @include MQ(L) {
    .cd-icon {
      .icon {
        font-size: 40px;
        @include size(75px, 75px);
      }
      span {
        line-height: 73px;
      }
    }
  }
}
.lg-img {
  width: 100%;
  height: 250px;
  -o-object-fit: cover;
  -o-object-position: 50% 50%;
  object-fit: cover;
  object-position: 50% 50%;
}

// Toastmessages style
.toast-item {
  p {
    color: white;
  }
}

.slick-slide {
  position: relative;
}

@include MQ(L) {
  .col-lg-2-5 {
    width: 20%;
  }
}
@include MQ(M) { // min 992
  .col-md-2-5 {
    width: 20%;
  }
}
@include MQ(S) {
  .col-sm-2-5 {
    width: 20%;
  }
}
@include MQ(XS) {
  .col-xs-2-5 {
    width: 20%;
  }
}
img[src*="bat.bing.com"] {
  width: 1px;
  height: 1px;
  display: none;
}

.caption {
  background: {
    image: url('#{$assets_path}/img/ico/manager.png');
    size: cover;
  }
  position: absolute;
  width: 104px;
  height: 105px;
  right: -4px;
  top: -5px;
  z-index: 1001;

  span {
    display: block;
    width: 104px;
    height: 105px;
    text-align: center;
    color: white;
    padding-top: 32px;
    transform: rotate(45deg);
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
  }
}

.goldenpartner {
  background-color: rgba(getColor(info, base), .9);
  position: absolute;
  height: 40px;
  padding: 3px 10px;
  left: 0;

  @include MQ(mL) {
    bottom: 0;
    font-size: 12px;
    top: auto;
  }

  bottom: auto;
  top: 0;

  /*z-index:9;*/
  font-size: 16px;
  line-height: 30px;
  padding-left: 50px;
  color: getColor(bg, light);
  &:before{
    position: absolute;
    right: -25px;
    top: 0;
    content: '';
    width: 0; height: 0;
    border-left: 25px solid rgba(getColor(info, base), .9);
    border-right: 5px solid transparent;
    border-bottom: 24px solid transparent;
  }
  &:after{
    position: absolute;
    right: -25px;
    bottom: 0;
    content: '';
    width: 0; height: 0;
    border-left: 25px solid rgba(getColor(info, base), .9);
    border-right: 5px solid transparent;
    border-top: 24px solid transparent;
  }
  i{
    position: absolute;
    left: 10px;
    top: 4px;
    font-size: 30px;
  }
}
