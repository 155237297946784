body.register {
  color: getColor(txt, lightest);
  > .wrapper {
    min-height: 80%;
    background: {
      image: url(#{$assets_path}/img/bg/register.jpg);
      size: cover;
      repeat: no-repeat;
      position: left center;
    }
    @include MQ(mM) {
      min-height: initial;
    }
    > section {
      position: relative;
    }
  }
  .table-row {
    display: table;
    width: 100%;
    height: 100%;
    margin: 0;
    min-height: 70vh;
    & > div {
      display: table-cell;
      height: 100%;
      float: none;
      vertical-align: top;
      &:first-child {
        vertical-align: middle;
      }
    }
  }
  .header-bck {
    * {
      color: getColor(txt, lightest);
    }
  }
  .form-register {
    padding-bottom: 15px;
    .help-inline {
      text-align: left;
      text-shadow: 0 1px 2px getColor(error, light);
      color: getColor(txt, lightest);
    }
    a {
      color: getColor(txt, lightest);
      font-weight: 400;
    }
    .btn {
      text-decoration: none;
      font-weight: 600;
    }
    p {
      color: getColor(txt, lightest);
    }
    h2 {
      font-weight: 600;
      color: getColor(txt, lightest);
    }
    .footer {
      font-size: 14px;
      a {
        text-decoration: underline;
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}
