@import "compass-mixins/lib/compass/css3/filter";
@import "modules/bootstrap";

$fa-font-path: "../../bower_components/fontawesome/fonts";
@import "fontawesome/scss/font-awesome";

// All third-party libs
@import "../../bower_components/seiyria-bootstrap-slider/dist/css/bootstrap-slider.css";
@import "../../bower_components/slick.js/slick/slick.css";
@import "../../bower_components/bootstrap-rating/bootstrap-rating.css";

@import "../../fonts/icon-font/style.css";
@import "../../css/jquery.toastmessage.css";
@import "../../bower_components/intl-tel-input/build/css/intlTelInput.css";

// Import modules
// @import "partials/fonts"; // We generate a separate file with fonts
@import "modules/basic";
@import "modules/utilities";
@import "modules/flags";
@import "modules/video";
@import "modules/form";
@import "modules/lists";
@import "modules/accordion";
@import "modules/buttons";
@import "modules/timeline";
@import "modules/tabs";
@import "modules/payments";
@import "modules/layout";
@import "modules/carousel";
@import "modules/alert";
@import "modules/responsive-embed";
@import "modules/thumbs";
@import "modules/map";
@import "modules/typeahead";
@import "modules/datepicker";
@import "modules/load_indicator";
@import "modules/inrecruiting-smart_default";
@import "modules/pagination";
@import "modules/aside-menu";
@import "modules/jumbotron";
@import "modules/modals";
@import "modules/table";
@import "modules/typography";
@import "modules/photos";
@import "modules/conversations";
@import "modules/print";
@import "modules/comparsion_list";
@import "modules/responsive";
@import "modules/pickmeup.theme";
@import "modules/sections";
//@import "modules/cookies";
@import "modules/go-top";
@import "modules/chatlio";
@import "modules/insurance";

@import "pages/summary";
@import "pages/vacation";
@import "pages/compare";
@import "pages/boat";
@import "pages/gift";
@import "pages/landing";
@import "pages/magazine";
@import "pages/vacation-summary";
@import "pages/press";
@import "pages/login";
@import "pages/register";
@import "pages/infopage";
@import "pages/spec-dest";
@import "pages/lux";
@import "pages/wizard";
@import "pages/payment";
@import "pages/homepage";
@import "pages/profile";
@import "pages/search";
@import "pages/destinations";
@import "pages/shop";
@import "pages/bonus";
@import "pages/sailor";
@import "pages/feedback";
@import "pages/special-offers";
@import "pages/weekend";

// Guideline
@import "guideline";
