body > footer, .body > footer {
  section {
    .row {
      margin-left: 0;
      margin-right: 0;
    }
    ul {
      li {
        font-size: 18px;
        color: getColor(info, darker);
        line-height: 20px;
        margin-bottom: 14px;
        label {
          font-weight: normal;
          padding-right: 5px;
        }
      }
      @include MQ(mM) {
        margin: 20px 0 0 0;
        li {
          a {
            color: getColor(info, base);
            line-height: 25px;
          }
        }
      }
    }
    header {
      padding: 33px 0 17px;
      font: {
        size: 24px;
        weight: 200;
      }
      i {
        font-size: 35px;
        color: getColor(danger, base);
      }
      @include MQ(mM) {
        margin-top: 15px;
        padding: 15px 0 0 0;
        border-top: 1px solid getColor(border, base);
        &.logo {
          margin-top: 0;
          padding: 0;
          background: getColor(info, base);
          height: 82px;
          width: 100%;
          display: table;
          @include MQ(mM) {
            margin: -10px -15px 0;
            width: calc(100% + 30px);
          }
          i {
            color: getColor(txt, lightest);
            display: table-cell;
            vertical-align: middle;
            @include MQ(mM) {
              @include MQ(S) {
                padding-left: 15px;
              }
            }
          }
        }
      }
    }
    .icons-list {
      padding-top: 7px;
      @include MQ(mL) {
        padding-top: 20px;
        display: table;
        width: 100%;
        margin: 0;
      }
      li {
        @include MQ(mL) {
          display: table-cell;
          text-align: center;
        }
        a {
          background: getColor(info, base);
          height: 40px;
          width: 40px;
          overflow: hidden;
          border-radius: 50%;
          font-size: 17px;
          color: white;
          text-align: center;
          line-height: 40px;
          &:hover {
            background: getColor(info, dark);
            opacity: 1;
          }
          @include MQ(mL) {
            font-size: 25px;
            line-height: 60px;
            height: 60px;
            width: 60px;
          }
        }
      }
    }
    &.explain {
      background: getColor(bg, light);
      @include MQ(mM) {
        border-top: 1px solid getColor(neutral, bg);
      }
      .tbl {
        height: 200px;
      }
      i {
        font-size: 65px;
        color: getColor(danger, base);
      }
      .txt {
        opacity: .8;
        font-size: 18px;
        color: getColor(txt, darkest);
      }
    }
    &.partners {
      background: getColor(info, dark);
      color: white;
      .tbl {
        height: 140px;
      }
      #footer-partners {
        max-height: 170px;
        overflow: hidden;
      }
      .icon {
        background: url(../img/ico/footer-partners.png) no-repeat;
        display: block;
        margin: 0 auto;
        &:hover {
          opacity: .8;
        }
        &.braintree {
          background-position: 0 0;
          width: 143px;
          height: 42px;
        }
        &.eis {
          background-position: 0 -205px ;
          width: 70px;
          height: 70px;
        }
        &.lastminute {
          background-position: 0 -103px ;
          width: 175px;
          height: 42px;
        }
        &.paypal {
          background-position: 0 -153px ;
          width: 128px;
          height: 42px;
        }
        &.stripe {
          background-position: -1px -53px ;
          width: 92px;
          height: 42px;
        }
        &.quint {
          background-position: 0 -287px ;
          width: 196px;
          height: 42px;
        }
      }
    }

    &.destinations {
      background: {
        color: getColor(info, base);
        image: url(../img/bg/footer-map.png);
        repeat: no-repeat;
        size: auto 100%;
        position: center center;
      }
      color: white;
      & > .container {
        padding-top: 40px;
        padding-bottom: 70px;
      }
      a {
        color: getColor(txt, lightest);
        padding: 5px 0;
        &:hover {
          text-decoration: underline;
        }
      }
      ul {
        li {
          margin-left: 24px;
        }
      }
    }
    &.contacts {
      background: getColor(bg, light);
      color: getColor(info, darker);
      @include MQ(mL) {
        padding-bottom: 70px;
        padding-top: 10px;
      }
      a {
        color: getColor(info, darker);
        transition-duration: .3s;
        &:hover {
          opacity: .7;
        }
      }
      header {
        color: getColor(info, darker);
      }
      aside {
        @include MQ(mL) {
          @include MQ(M) {
            display: inline-block;
            float: none;
            vertical-align: top;
            width: 49%;
          }
        }
      }
      footer {
        padding: 25px 0 20px;
        font-size: 16px;
        opacity: .4;
        background: transparent;
        border: 0;
        color: getColor(txt, darkest);
        @include MQ(mM) {
          text-align: center;
        }
      }
    }
    button {
      &.ftr-slider-control {
        position: absolute;
        bottom: 67px;
        background: transparent;
        border: none;
        color: white;
        font-size: 33px;
        cursor: pointer;
        z-index: 100;
        &:hover {
          opacity: .8;
        }
        &:active, &:focus {
          outline: none;
        }
      }
      &.prev {
        left: -15px;
        transform: scaleX(-1);
      }
      &.next {
        right: 0;
      }
    }
  }
}
.landing-footer {
  padding: 0 !important;
  background: transparent;
  border-top: 0;
  .top-footer {
    padding: 40px 0;
  }
  .bottom-footer {
    background: getColor(info, base);
    padding: 15px 0;
    color: white;
    .container {
      padding: 0 15px;
    }
    small {
      font-size: 14px;
      color: white;
      white-space: nowrap;
    }
  }
  .boats-list {
    text-align: left;
    font-size: 16px;
    a {
      font-size: 16px;
      color: getColor(txt, base);
      &:hover, &:focus {
        color: getColor(info, base);
      }
    }
  }
}
