section {
  &.table-controls {
    .buttons {
      a {
        padding-right: 10px;
        &:visible:last-child {
          padding-right: 0;
        }
        i {
          font-size: 25px;
          line-height: 24px;
        }
      }
    }
  }
  &.destinations, &.boat-types, &.manufacturers {
    #dest-list {
      > .panel {
        border: none;
        background: transparent;
        border-radius: 0;
        margin: 0;
        box-shadow: none;
      }
    }
    .dropdown-menu-container {
      padding: 0;
      [data-toggle='dropdown'] {
        background: white;
        color: black;
        font-size: 18px;
        line-height: 22px;
        float: none !important;
        border-color: #ddd;
        text-align: left;
        line-height: 45px;
        &[aria-expanded=true] {
          .icon {
            transform: rotate(180deg);
          }
        }
        i {
          float: left;
          margin-right: 10px;
          font-size: 31px;
          line-height: 57px;
        }
        .icon {
          float: right;
          line-height: 45px;
        }
      }
      .dropdown-menu {
        border: 0;
        margin: 0;
        border-radius: 0;
        width: 100%;
        line-height: 45px;
        background-color: transparent;
        box-shadow: none;
        padding: 0;
        a {
          line-height: 35px;
          i {
            line-height: 35px;
            float: left;
          }
        }
      }
    }
    .items {
      &.view-items-4 {
        > .row {
          > div {
            &:nth-child(n+5) {
              display: none;
            }
          }
        }
      }
      &.view-items-6 {
        > .row {
          > div {
            &:nth-child(n+7) {
              display: none;
            }
          }
        }
      }
      &.view-items-8 {
        > .row {
          > div {
            &:nth-child(n+9) {
              display: none;
            }
          }
        }
      }
      a.item {
        display: block;
        background: white;
        margin-bottom: 15px;
        position: relative;
        overflow: hidden;
        transition: .1s;
        &:hover {
          @include MQ(L) {
            box-shadow: 0 5px 10px getColor(neutral, search);
          }
        }
        &:not(.active):hover {
          .ftr-add {
            bottom: 0;
          }
          &:after {
            bottom: 0;
          }
        }
        &.active:hover {
          .ftr-rmv {
            bottom: 0;
          }
        }
        .hdr {
          overflow: hidden;
          position: relative;
          height: 253px;
          @include MQ(mL) {
            height: 100px;
            width: 100px;
            display: inline-block;
            float: left;
          }
          img {
            position: absolute;
            top: 0;
            left: 0;
            min-width: 100%;
            min-height: 100%;
            z-index: 10;
            @include MQ(mL) {
              max-width: auto;
              max-height: 100%;
            }
          }
          span {
            position: absolute;
            top: 40%;
            left: 0;
            right: 0;
            margin: 0 auto;
            color: white;
            text-align: center;
            z-index: 0;
            i {
              font-size: 48px;
            }
            @include MQ(mL) {
              top: 25%;
            }
          }
          &:after {
            content: '';
            position: absolute;
            opacity: .8;
            z-index: 0;
            width: 100%;
            height: 100%;
            background: getColor(info, base);
          }
        }
        &.active {
          .hdr {
            &:after {
              z-index: 12;
            }
            span {
              z-index: 13;
            }
          }
        }
        .txt {
          padding: 15px 10px 10px;
          height: 90px;
          @include MQ(mL) {
            display: inline-block;
            float: left;
            padding: 0 0 0 10px;
            vertical-align: middle;
            width: calc(100% - 110px)
          }
          .h2, h2, .h3, h3 {
            font-size: 22px;
            font-weight: 200;
            margin: 0;
            padding: 0;
            color: getColor(neutral, color);
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            @include MQ(mL) {
              padding: 15px 0 5px;
            }
          }
          .ftr {
            &.row {
              margin-top: 12px;
              @include MQ(mL) {
                margin-top: 0;
              }
            }
            font-size: 17px;
            color: getColor(neutral, color);
            opacity: .45;
            &.smaller {
              font-size: 15px;
              line-height: 16px;
              padding-top: 5px;
            }
            @include MQ(mL) {
              font-size: 15px;
            }
            * {
              color: getColor(neutral, color);
              white-space: nowrap;
              overflow: hidden;
            }
            div:last-child {
              text-align: right;
              @include MQ(mL) {
                text-align: left;
              }
            }
          }
        }
        .ftr-hover {
          transition: .1s;
          position: absolute;
          bottom: -90px;
          height: 90px;
          width: 100%;
          left: 0;
          color: getColor(txt, lightest);
          text-align: center;
          line-height: 84px;
          font-size: 22px;
          &.ftr-add {
            background: getColor(info, base);
            @include MQ(mL) {
              display: none;
            }
          }
          &.ftr-rmv {
            background: getColor(danger, delete);
            @include MQ(mL) {
              display: none;
            }
          }
        }
        &.not-sure {
          .hdr {
            span {
              @include MQ(mL) {
                top: 35%;
              }
              i {
                color: getColor(info, base);
                border: 2px solid getColor(info, base);
                border-radius: 50%;
                padding: 15px;
                font-size: 38px;
                @include MQ(mL) {
                  font-size: 30px;
                }
              }
            }
            &:after {
              background: rgba(black, .15);
            }
          }
          .txt {
            background: transparent;
            @include MQ(L) {
              background: white;
            }
            .h2 {
              text-align: left;
              line-height: 95px;
              padding: 0;
              color: getColor(info, base);
              @include MQ(L) {
                text-align: center;
                line-height: 55px;
              }
            }
          }
          &.active {
            background: getColor(info, base);
            .hdr {
              i {
                color: white;
                border-color: white;
              }
            }
            .txt {
              background: transparent;
              @include MQ(L) {
                background: getColor(info, base);
              }
              .h2 {
                color: white;
                @include MQ(L) {
                  color: getColor(txt, lightest);
                }
              }
            }
          }
        }
      }
    }
  }
  &.breadcrumbs {
    ol, ul {
      list-style: none;
      margin: 15px 0 15px;
      padding: 20px 0 20px 10px;
      border: {
        top: 1px solid rgba(black, .1);
        bottom: 1px solid rgba(black, .1);
      }
      &:after {
        content: '';
        display: block;
        clear: both;
      }
      > li {
        display: inline-block;
        padding: 0 0 0 3px;
        float: left;
        color: darken(white, 20%);
        max-width: 90%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        h1 {
          padding: 0;
          margin: 0;
          display: inline-block;
          font-weight: 400;
        }
        font: {
          size: 17px;
          weight: 400;
        }
        @include MQ(mM) {
          font-size: 22px;
          line-height: 40px;
        }
        i {
          opacity: .5;
          margin: 0 3px;
        }
        &:first-child {
          .txt {
            display: inline-block;
            @include MQ(mM) {
              display: none;
            }
          }
          .icon {
            display: none;
            font-size: 31px;
            vertical-align: middle;
            float: left;
            @include MQ(mM) {
              display: inline-block;
            }
          }
        }
        &.active {
          a {
            color: getColor(neutral, txt);
          }
        }
      }
    }
  }
  &.articles {
    clear: both;
    @include MQ(mM) {
      padding: 0 15px;
    }
    .item {
      height: 355px;
      margin-bottom: 10px;
      .item-container {
        transition: .25s;
        width: 100%;
      }
      &:hover {
        .item-container {
          @include MQ(L) {
            box-shadow: 0 5px 10px getColor(neutral, search);
          }
        }
      }
      aside {
        max-width: 100%;
        width: 100%;
        overflow: hidden;
        position: relative;
        height: 220px;

        a {
          height: 100%;
          width: 100%;
          display: block;

          background-image: url('#{$assets_path}/img/boat.jpg');
          background-position: center;
          background-size: cover;

          img {
            min-width: 100%;
            min-height: 100%;
            display: block;
            margin: 0 auto;
            position: absolute;
            top: 0;
            right: -500px;
            left: -500px;
          }
        }
      }
      article {
        margin: 0;
        padding: 0 10px 10px;
        font-weight: 400;
        background: getColor(bg, light);
        position: relative;
        border: {
          left: 1px solid getColor(neutral, bg);
          right: 1px solid getColor(neutral, bg);
          bottom: 1px solid getColor(neutral, bg);
        }
        .title {
          clear: both;
          display: table;
          width: 100%;
          height: 75px;
          overflow: hidden;
          margin: 0;
          padding: 0;
          font-weight: 400;
          a {
            display: table-cell;
            vertical-align: middle;
            font-size: 21px;
            color: getColor(txt, light);
            line-height: 27px;
            @include MQ(mL) {
              font-size: 18px;
              line-height: 20px;
            }
            &:hover {
              opacity: .8;
            }
          }
        }
        footer {
          font-size: 18px;
          line-height: 20px;
          opacity: .7;
          @include MQ(mL) {
            font-size: 16px;
            line-height: 16px;
          }
        }
        .top-container {
          font-size: 17px;
          padding: 12px 0 0 0;
          color: getColor(neutral, search);
          .category {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          .date {
            float: right;
          }
        }
        .txt {
          display: none;
        }
      }
      &.featured {
        width: 100%;
        margin-bottom: 15px;
        @include MQ(mM) {
          height: auto;
        }
        @include MQ(M) {
          footer {
            position: absolute;
            bottom: 15px;
            left: 25px;
            right: 25px;
          }
          .item-container {
            min-height: 325px;
            height: 325px;
          }
        }
        aside {
          width: 66%;
          @include MQ(M) {
            height: 325px;
          }

          a {
            background-image: url('#{$assets_path}/img/blog/01-featured.jpg');
          }
        }
        article {
          height: 325px;
          position: relative;
          width: 34%;
          padding: 10px 25px 10px;
          @include MQ(mM) {
            padding: 0 10px 10px;
            height: auto;
          }
          .top-container {
            padding: 5px 0 0;
            @include MQ(mL) {
              font-size: 16px;
            }
            @include MQ(mM) {
              padding: 12px 0 0;
            }
          }
          .txt {
            display: block;
            font-size: 16px;
            padding-bottom: 7px;
            @include MQ(mL) {
              padding-top: 5px;
              overflow: hidden;
              font-size: 14.5px;
            }
            @include MQ(mM) {
              display: none;
            }
          }
        }
        aside, article {
          float: left;
          @include MQ(mL) {
            width: 50%;
          }
          @include MQ(mM) {
            width: 100%;
            float: none;
          }
        }
        @include MQ(mM) {
          aside, article {
            width: 100%;
            display: block;
          }
        }
      }
    }
  }
  &.wizard-success-results {
    margin: -210px 0 0;
    position: relative;
    #answer {
      background: getColor(info, base);
      color: getColor(txt, lightest);
      overflow: hidden;
      @include MQ(mM) {
        padding: 0 20px;
      }
      .tbl {
        font-size: 22px;
        line-height: 24px;
        min-height: 180px;
        height: 180px;
        @include MQ(M) {
          font-size: 26px;
          line-height: 34px;
          height: 210px;
        }
      }
    }
    .inside {
      padding: 20px 20px 20px;
      background: white;
      transition-duration: .6s;
      box-shadow: 0 5px 10px rgba(black, .35);
      @include MQ(mS) {
        padding: 20px;
      }
      aside {
        margin: 20px 15px 30px 30px;
        @include MQ(mS) {
          margin: 5px 5px 22px 5px;
        }
        > * {
          display: inline-block;
          vertical-align: top;
        }
        .lft {
          width: 70px;
          height: 50px;
          min-height: 50px;
          @include MQ(mS) {
            width: 50px;
          }
          i {
            font-size: 48px;
            color: getColor(info, base);
            @include MQ(mS) {
              font-size: 37px;
            }
          }
        }
        .rgt {
          width: calc(100% - 80px);
          .hdr {
            font-size: 18px;
            color: getColor(neutral, medium);
          }
          .ftr {
            font-size: 22px;
            line-height: 26px;
            color: getColor(info, dark);
            min-height: 60px;
            @include MQ(mS) {
              font-size: 18px;
            }
            @include MQ(mM) {
              min-height: 0;
            }
          }
        }
      }
    }
  }
  &#filters, &.filters {
    .flatForm {
      .cd-select {
        select {
          padding: 23px 15px;
          margin: 0;
          border: 0;
          font-size: 22px;
          &:hover, &:active, &:focus {
            border: 0;
          }
        }
      }
    }
  }
  &#tags, &.tags {
    padding: 10px 0 15px 20px;
    .title {
      float: left;
      font-size: 23px;
      padding: 0 15px 0 0;
      margin-top: -4px;
      @include MQ(mM) {
        margin-top: 2px;
      }
    }
    a {
      display: inline-block;
      padding: 0 8px;
      font-size: 16px;
      margin: 0 0 5px 7px;
      border: 1px solid getColor(neutral, bg);
      background: getColor(bg, light);
      line-height: 29px;
      @include MQ(mM) {
        line-height: 38px;
      }
      &:hover {
        background: getColor(info, base);
        color: getColor(txt, lightest);
        border-color: getColor(info, base);
      }
    }
  }
  &.request {
    position: relative;
    .alert.alert-success {
      display: none;
    }
    .section-form {
      display: block;
    }
    &.success {
      .alert.alert-success {
        display: block;
      }
      .section-form {
        display: none;
      }
    }
  }
}
.pagination {
  text-align: center;
  ul {
    display: inline-block;
    font-size: 16px;
    @include MQ(mS) {
      font-size: 13px;
    }
    @include MQ(mM) {
      font-size: 100%;
    }
    li {
      display: inline;
      a {
        display: block;
        color: getColor(txt, darkest);
        text-align: center;
        background: white;
        border-right: 1px solid rgba(black, .1);
        position: relative;
        float: left;
        transition-duration: .1s;
        padding: 15px 0;
        width: 55px;
        @include MQ(mM) {
          padding: 13px 0;
          width: 40px;
        }
        &:hover {
          @include MQ(L) {
            z-index: auto;
            box-shadow: 0 1px 2px rgba(black, .5);
          }
        }
      }
      span {
        display: inline-block;
      }
      &:first-child {
        a {
          border-left: 1px solid rgba(black, .1);
        }
      }
      &.active {
        > a {
          color: getColor(txt, lightest);
          background: getColor(info, base);
          border-right: 1px solid getColor(info, base);
        }
      }
      &.start, &.end {
        > a {
          font-size: 9px;
          padding: 20px 0;
          @include MQ(mM) {
            padding: 16px 0 17px;
            width: 80px;
            font-size: 18px;
          }
        }
      }
      &.start {
        a {
          padding-right: 4px;
          margin-right: 5px;
        }
        span {
          transform: rotate(90deg);
        }
      }
      &.end {
        a {
          padding-left: 4px;
          margin-left: 5px;
        }
        span {
          transform: rotate(-90deg);
        }
      }
    }
  }
}
.lux-info {
  margin: 0 -15px;
  background: {
    image: url('#{$assets_path}/img/bg/lux/search.jpg');
    color: rgba(black, .3);
    size: cover;
  }
  * {
    color: getColor(txt, lightest);
    @include MQ(mM) {
      text-align: center;
    }
  }
  .tbl {
    height: 220px;
    @include MQ(mL) {
      height: 120px;
    }
  }
  .row {
    background-color: transparent !important;
  }
  aside {
    font-size: 58px;
  }
  main {
    .h1 {
      margin: 0;
    }
    .tbl {
      @include MQ(mM) {
        height: 90px;
      }
    }
  }
  footer {
    @include MQ(mS) {
      text-align: center;
    }
    .tbl {
      @include MQ(mL) {
        height: 90px;
      }
    }
  }
}
