.booking-process {
  background-color: getColor(neutral, bg);
  padding: 0 0 35px 0;
  margin: 0;
  .sections {
    position: relative;
    #progress-line {
      z-index: 10;
      background: getColor(timeline, base);
      min-height: 6px;
      min-width: 6px;
      position: absolute;
    }
  }
  section {
    z-index: 11;
    white-space: nowrap;
    padding: 10px 0;
    margin-top: 40px;
    header {
      font-size: 18px;
      font-weight: 400;
    }
    .footer {
      display: block;
      font-weight: 300;
      button {
        display: block;
      }
    }
    ins {
      text-decoration: none;
      text-align: center;
      height: 80px;
      .circle {
        border-radius: 50px;
        margin: 0 auto;
        background-color: getColor(timeline, base);
        border: 2px solid getColor(timeline, base);
        font-size: 14px;
        font-weight: 300;
        width: 78px;
        height: 78px;
        line-height: 16px;
        display: block;
        overflow: hidden;
        color: getColor(txt, lightest);
        span {
          color: getColor(txt, lightest);
        }
        &.active {
          background: getColor(bg, light);
          color: getColor(success, base);
          span {
            color: getColor(timeline, base);
          }
        }
        > p {
          position: relative;
          top: 50%;
          transform: translateY(-50%);
          margin: 0;
          display: inline-block;
          span { display: block; }
          span:last-child {
            font-size: 17px;
            font-weight: bold;
          }
        }
        i {
          position: relative;
          top: 28%;
          font-size: 31px;
        }
      }
    }
  }
}
@include MQ(mL) {
  #progress-line {
    left: 16.25%;
    top: 20%;
    height: 70%;
  }
  .booking-process {
    section {
      text-align: left;
      width: 100%;
      margin-top: 0px;
      min-height: 100px;
      .footer {
        text-align: left;
      }
    }
  }
}
@include MQ(mM) {
  #progress-line {
    left: 12%;
  }
}
@include MQ(L) {
  #progress-line {
    width: 80%;
    left: 10%;
    bottom: 148px;
  }
  .booking-process {
    section {
      text-align: center;
      width: 20%;
      margin-top: 0px;
      height: 200px;
      .footer {
        text-align: center;
        button {
          margin: 5px auto 0;
        }
      }
    }
    &.four-steps section {
      width: 25%;
    }
    .description {
      margin-top: 20px;
    }
  }
}
.cd-process {
  background: getColor(neutral, bg) !important;
  padding-bottom: 35px;
  color: getColor(txt, base);
  .container {
    padding: 0 30px 0 50px;
  }
  .process-table-container {
    padding: 0 30px 0 50px !important;
  }

  // .cancellation {
  //   margin-bottom: 15px;
  //   @include MQ(L) {
  //     margin-bottom: 5px;
  //   }
  // }

  .cd-timeline {
    position: relative;
  }
  .green-line {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 7px;
    background: getColor(timeline, base);
    height: 100%;
    z-index: 1;
  }
  @include MQ(M) {
    padding: 0 15px 0 30px;
    .container {
      padding: 0 15px;
    }
  }
  @include MQ(XXL) {
    padding: 0 15px;
  }
}
.cd-process-steps {
  margin-bottom: 30px;
  font-size: 16px;
  .row {
    margin-left: 0;
    margin-right: 0;
    background-color: getColor(bg, light);
    margin-bottom: 15px;
    border-radius: 3px;
    &.checked-step {
      [class^="col-"]:first-of-type {
        background-color: getColor(timeline, dark);
        color: getColor(txt, lightest);
        h3, h4 {
          color: getColor(txt, lightest);
        }
      }
    }
    &.downpayment-step {
      [class^="col-"]:first-of-type {
        background-color: getColor(timeline, light);
      }
      .cd-circle {
        background-color: getColor(bg, light);
        border: 2px solid getColor(timeline, base);
      }
      .circle-txt {
        color: getColor(timeline, base);
      }
    }
    &.noprice {
      [class^="col-"]:nth-of-type(2) {
        background-color: getColor(timeline, light); // lightest
        min-height: 89px;
      }
    }
    [class^="col-"] {
      padding: 15px;
      .btn-is-success {
        font-size: 16px;
        padding: 5px 10px;
        margin-top: 5px;
      }
      &:first-of-type {
        background-color: getColor(timeline, lightest);
        padding-left: 50px;
      }
      &:nth-of-type(2) {
        text-align: center;
        font-weight: 600;
        padding-left: 5px;
        padding-right: 5px;
      }
      &:nth-of-type(3) {
        background-color: getColor(warning, base);
      }
      &:nth-of-type(3), &:nth-of-type(4) {
        padding: 15px 30px;
      }
      h3, h4 {
        margin: 5px 0;
        padding: 0;
        font: {
          size: 20px;
          weight: 300;
        }
      }
      h3 {
        font-weight: 400;
      }
      h5 {
        font-weight: 600;
        font-size: 18px;
        margin: 10px 0;
        padding: 0;
      }
    }
  }
  .cd-circle {
    position: absolute;
    @include center(y);
    border-radius: 50px;
    background-color: getColor(timeline, base);
    width: 80px;
    height: 80px;
    left: -40px;
    line-height: 18px;
    text-align: center;
    color: getColor(txt, lightest);
    z-index: 2;
    .circle-txt {
      position: relative;
      @include center(y);
      font-weight: 400;
      display: block;
      white-space: nowrap;
      &:first-of-type {
        margin-top: -12px;
      }
      &.date {
        font-weight: 600;
        margin-top: 0;
        font-size: 14px;
      }
      &.fa {
        margin-top: 0;
        font-size: 28px;
      }
    }
  }
  p {
    margin: 10px 0;
  }
  @include MQ(L) {
    margin-bottom: 60px;
    .row {
      width: 100%;
      display: table;
      margin-bottom: 5px;
      [class^="col-"]  {
        display: table-cell;
        float: none;
        vertical-align: middle;
      }
    }
  }
}
.horizontal-timeline {
  position: relative;
  @include clearfix();
  margin-top: 15px;
  .progress-line {
    position: absolute;
    width: 70%;
    left: 15%;
    height: 6px;
    background-color: getColor(success, base);
    top: 40px;
  }
  .step-description {
    text-align: center;
    font-size: 21px;
    color: getColor(info, dark);
  }
  .cd-circle {
    border-radius: 50px;
    position: relative;
    margin: 0 auto;
    background-color: getColor(success, base);
    font-size: 14px;
    font-weight: 300;
    width: 80px;
    height: 80px;
    line-height: 16px;
    color: white;
    display: block;
    overflow: hidden;
    &.active {
      background-color: getColor(success, success);
    }
    span, i {
      position: absolute;
      @include center();
      font-size: 20px;
      font-weight: 400;
      text-align: center;
      line-height: 1em;
      white-space: nowrap;
    }
  }
}
.vertical-timeline {
  position: relative;
  .cd-timeline {
    position: relative;
  }
  .green-line {
    position: absolute;
    width: 6px;
    top: 0;
    left: 27px;
    height: 100%;
    z-index: 1;
    background: getColor(timeline, base);
  }
  .cd-process-steps {
    margin-bottom: 0;
    margin-top: 35px;
    .step {
      position: relative;
      margin-bottom: 30px;
    }
    .cd-circle {
      left: 0;
      @include size(60px, 60px);
      background-color: getColor(timeline, base);
    }
    .cd-txt {
      padding-left: 80px;
      h3 {
        margin-bottom: 0;
        font-weight: 600;
      }
      p {
        margin: 0;
      }
    }
  }
}
