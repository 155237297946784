body.destinations {
  background: getColor(neutral, bg);
  section {
    &.light {
      background: white;
    }
    &.dark {
      background: darken(white, 15%);
    }
    &#world {
      background: rgba(black, .1);
      height: 625px;
      width: 100%;
      @include MQ(mM) {
        display: none;
      }
    }
    &#map-section {
      @include MQ(mM) {
        display: none;
      }
    }
    &.welcome {
      p {
        color: $txt-style-one;
      }
      .more-btn {
        @include MQ(mM) {
          text-align: center;
        }
      }
    }
    #map {
      width: 100%;
      &.map-vertical {
        height: 500px;
        @include MQ(mM) {
          height: 250px;
        }
      }
      &.map-horizontal {
        height: 625px;
      }
    }
    &.details {
      .destination-container {
        @include MQ(mM) {
          border-top: 1px solid getColor(neutral, bg);
        }
        @include MQ(M) {
          border-left: 2px solid getColor(neutral, bg);
          padding-left: 75px;
        }
      }
      .destination-list {
        padding-top: 20px;
      }
      @include MQ(mM) {
        .destination-list {
          padding: 20px 20px 0;
        }
        .hdr {
          text-align: center;
        }
        .h1 {
          text-align: center;
        }
        p {
          &.small {
            text-align: right;
            display: block;
          }
        }
      }
    }

    &.photos {
      .cd-slick-carousel {
        position: relative;
      }
      .slick-next {
        right: 250px;
        @include MQ(mM) {
          right: 25px;
        }
      }
      .slick-counter {
        font-size: 18px;
        padding-right: 200px;
        @include MQ(mM) {
          padding-right: 0;
        }
      }
    }
    &.jumbotron {
      background: {
        size: cover;
        repeat: no-repeat;
        position: top center;
      }
      &:not(.imgix-fluid) {
        background-image: url('#{$assets_path}/img/bg/destinations/xl.jpg');
        @include MQ(mL) {
          background-image: url('#{$assets_path}/img/bg/destinations/l.jpg');
        }
        @include MQ(mM) {
          background-image: url('#{$assets_path}/img/bg/destinations/m.jpg');
        }
        @include MQ(mS) {
          background-image: url('#{$assets_path}/img/bg/destinations/s.jpg');
        }
      }
    }
  }
}
