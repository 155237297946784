.feedback {
  .jumbotron {
    background-image: url("../img/feedback/feedback-bg.jpg");
      height: auto;
      padding-bottom: 100px;
      @include MQ(mM) {
        padding-left: 15px;
        padding-right: 15px;
        padding-top: 250px;
        padding-bottom: 270px;
        z-index: 999999;
      }
      @include MQ(mS) {
        padding-top: 250px;
        padding-bottom: 270px;
        background-image: url("../img/feedback/Mobile-feedback-bg.jpg");
      }
      .container.with-titles {
         .title-container {
          max-width: 1120px;
          .icon-main{
            font-size: 90px;
            top: -44px;
            left: calc(50% - 44px);
            @include MQ(mS) {
              font-size: 90px;
              top: -44px;
              left: calc(50% - 44px);
            }
          }
          .titles {
            h1, .h1 {
              font-weight: 400;
              font-size: 36px;
              margin-bottom: 40px;
              @include MQ(mM) {
                padding: 0;
              }
            }
            p{
              font-size: 24px;
              color: white;
              font-weight: 400;
              margin: 0;
            }
            h3, .h3 {
              font-size: 18px;
              font-weight: 400;
              margin-top: 40px;
            }
            .btn-is-info {
              max-width: 450px;
              width: 100%;
              border-width: 2px;
              i{
                margin-right: 10px;
              }
              @include MQ(mS) {
                margin-left: 0;
                margin-right: 0;
              }
            }
          }
        }
      }
  }

  .review-header {
    background: linear-gradient(darken($colorInfo, 5%), $colorInfo 20%);
    background-color: $colorInfo;
    padding-bottom: 40px;
      padding-top: 40px;
      @include MQ(mM) {
        padding-left: 15px;
        padding-right: 15px;
        position: relative;
        z-index: 999999;
      }
      @include MQ(mS) {
      padding-bottom: 20px;
        padding-top: 20px;
    }
    h2, .h2{
      font-size: 36px;
      color: getColor(txt, lightest);
      margin: 0 0 10px;
      @include MQ(mS) {
        font-size: 30px;
      }
    }
    .location, .month, .week{
      font-size: 18px;
      color: getColor(txt, lightest);
      display: inline-block;
      margin-right: 18px;
      font-weight: 300;
      i{
        margin-right: 10px;
      }
    }
    .location{
      @include MQ(mS) {
        width: 100%;
        margin-right: 0;
        margin-bottom: 15px;
      }
    }
  }

  .feedbackform {
    padding-top: 50px;
    @include MQ(mM) {
      padding-top: 20px;
        padding-left: 15px;
        padding-right: 15px;
      }
    .feedbackform-list {
      h5{
        color: getColor(txt, feedbacktext);
        font-size: 24px;
        @include MQ(mM) {
            font-size: 18px;
          }
      }
      .feedbackform-list-heading {
        margin-top: 15px;
          margin-bottom: 15px;
          @include MQ(mM) {
            margin-top: 35px;
            margin-bottom: 20px;
          }
        span{
          border-radius: 50%;
          color: getColor(txt, lightest);
          background-color: $colorInfo;
          height: 42px;
            width: 42px;
            display: inline-block;
            float: left;
            text-align: center;
            line-height: 42px;
          margin-right: 10px;
          @include MQ(mM) {
              height: 35px;
              width: 35px;
              line-height: 35px;
            }
        }
        color: $colorInfo;
        font-size: 30px;
        font-weight: 800;
        @include MQ(mM) {
            font-size: 24px;
          }
      }
      .form-group{
        padding-top: 20px;
        padding-bottom: 20px;
        border-top: solid 1px getColor(neutral, base);
        margin-bottom: 0;
        margin: 0;
        @include MQ(mM) {
          padding-top: 15px;
        }
        &.noborder {
          border-top: none;
          padding-top: 0;
        }
        p {
          margin-top: 15px;
          font-size: 24px;
          @include MQ(mM) {
              font-size: 18px;
              margin-top: 0;
              margin-bottom: 15px;
            }
            &.commetbox-heading{
              font-size: 30px;
              color: $colorInfo;
              @include MQ(mM) {
                font-size: 24px;
                margin-top: 15px;
              }
            }
        }

        .row {
          * {
            color: getColor(txt, feedbacktext);
          }
        }
        .btn-is-info {
          &.top-bot-20{
            margin-top: 20px;
            margin-bottom: 20px;
          }
            font-size: 18px;
            max-width: 450px;
            padding: 18px 16px;
            width: 100%;
            color: getColor(txt, lightest);
        }
      }
      .label-radiobox {
        width: 20%;
        height: 65px;
          border: solid 1px getColor(neutral, base);
          border-right: none;
          margin-right: 0;
          margin-left: 0;
          margin-bottom: 0;
          float: left;
          font-weight: normal;
          &:last-child {
            border-right: solid 1px getColor(neutral, base);
          }
          @include MQ(mS) {
          height: 45px;
        }
          .outer {
            left: 0;
            top: 0;
            width: 100%;
            height: 65px;
            border: none;
            text-align: center;
            background: transparent;
            line-height: 60px;
            &:hover, &:focus{
              background-color: #efefef;
              border-radius: 0;
              height: 63px;
              width: 100%;
            }
            @include MQ(mS) {
              height: 45px;
              line-height: 43px;
            }
            &:before {
              content: "\e922";
              font-family: 'sailogy3' !important;
              font-size: 28px;
              color: getColor(neutral, checkbox);
              @include MQ(mS) {
                font-size: 22px;
              }
          }
            .inner {
              background: $colorInfo;
              border-radius: 0;
              width: 100%;
              height: 63px;
              top: 0;
              left: 0;
              text-align: center;
              font-weight: bold;
              transition: transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
              transform: scale(0, 0);
              opacity: 0;
              @include MQ(mS) {
                height: 43px;
              }
              &:before {
                content: "\e923";
                font-family: 'sailogy3' !important;
                font-size: 28px;
                color: getColor(txt, lightest);
                @include MQ(mS) {
                  font-size: 22px;
                }
            }
            }
          }
          &.sad{
            .outer {
              &:before {
                content: "\e922";
              }
              .inner {
                &:before {
                  content: "\e923";
                }
              }
            }
          }
          &.emoticon{
            .outer {
              &:before {
                content: "\e91e";
              }
              .inner {
                &:before {
                  content: "\e91f";
                }
              }
            }
          }
          &.smile{
            .outer {
              &:before {
                content: "\e925";
              }
              .inner {
                &:before {
                  content: "\e926";
                }
              }
            }
          }
          &.happy{
            .outer {
              &:before {
                content: "\e920";
              }
              .inner {
                &:before {
                  content: "\e921";
                }
              }
            }
          }
          &.very-happy{
            .outer {
              &:before {
                content: "\e927";
              }
              .inner {
                &:before {
                  content: "\e928";
                }
              }
            }
          }
          input:checked ~ .outer .inner {
            transform: scale(1, 1);
            opacity: 1;
          }
      }
    }
  }
}
