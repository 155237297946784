.jumbotron-boat {
  height: auto;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0;
  @include MQ(M) {
    height: auto;
    padding-bottom: 10px;
  }
  @include MQ(L) {
    padding-bottom: 0;
  }
  .jumbo-header {
    margin: 0;
    h2 {
      font-weight: 300;
      font-size: 22px;
      color: getColor(txt, lightest);
    }
  }
  > .container {
    position: relative;
  }
  .header-search {
    background-color: rgba(black, .2);
    padding: 12px 10px;
    @include clearfix();
    &.is-hide {
      display: none;
    }
    .form-group {
      margin: 0 0 20px 0;
      select {
        line-height: 1;
      }
    }
    .header-search-submit {
      padding: 18px;
      width: 100%;
      background-color: getColor(neutral, search);
      color: getColor(txt, lightest);
      height: auto;
      &:hover {
        background: darken(getColor(neutral, search), 10%);
      }
      span {
        display: inline;
      }
    }
    .back-search {
      display: block;
      margin: 10px 0 20px 0;
      color: getColor(neutral, txt);
      font-size: 16px;
      text-transform: uppercase;
      &:hover {
        color: getColor(txt, lightest);
      }
    }
    @include MQ(M) {
      margin-right: 0;
      margin-left: 0;
      .header-search-submit {
        width: 4%;
        height: 43px;
        padding: 0;
        background: getColor(bg, light);
        &:before {
          color: getColor(info, base);
        }
        &:hover {
          background: getColor(bg, light);
          &:before {
            color: getColor(info, base);
          }
        }
        span {
          display: none;
        }
      }
      .back-search {
        display: inline-block;
        float: left;
        margin: 10px 0;

        [class*="fa-"] {
          font-size: 24px;
        }
        span:not(.fa) {
          display: none;
        }
      }
      form {
        width: 97%;
        float: right;
      }
      .form-group {
        margin: 0 1.3% 0 0;
      }
    }
  }
}
.boat-options {
  @include MQ(L) {
    border-top: 1px solid rgba(black, .1);
  }
  background: white;
  section {
    &:first-child {
      padding-left: 40px;
      padding-right: 30px;
    }
    &:last-child {
      @include MQ(L) {
        border-left: 1px solid rgba(black, .1);
      }
    }
  }
  .tbl {
    @include MQ(L) {
      height: 170px;
    }
  }
  header {
    @include MQ(L) {
      text-align: left;
    }
    button, .btn-block {
      text-align: center;
      @include MQ(mXL) {
        text-align: left;
      }
      @include MQ(mL) {
        padding-top: 15px;
        padding-bottom: 15px;
        margin: 0 0 20px;
      }
      span {
        display: inline-block;
        @include MQ(mXL) {
          display: inline;
        }
      }
      i {
        float: left;
        display: inline-block;
        padding-right: 5px;
        font-size: 22px;
        @include MQ(mXL) {
          @include MQ(L) {
            line-height: 40px;
          }
        }
        @include MQ(XL) {
          padding-right: 10px;
        }
      }
    }
  }
  .social {
    width: 100%;
    margin: 25px auto 40px;
    @include MQ(mL) {
      width: 60%;
    }
    @include MQ(mM) {
      width: 100%;
      padding-top: 10px;
    }
    h3 {
      font-weight: 300;
      @include MQ(L) {
        font-size: 21px;
      }
      @include MQ(XL) {
        font-size: 26px;
      }
    }
    ul {
      margin: 25px 0 0;
    }
    a {
      font-size: 22px;
      padding: 10px;
      @include MQ(S) {
        font-size: 32px;
      }
      @include MQ(L) {
        padding-left: 0;
        font-size: 22px;
      }
      @include MQ(XL) {
        font-size: 28px;
      }
      &:hover, &:focus {
        color: getColor(info, darker);
      }
    }
  }
}
.search-bar {
  .tbl {
    height: 75px;
  }
  &.is-hide {
    display: none;
  }
  a {
    // position: absolute;
    // @include center(y);
    // left: 0;
    color: getColor(neutral, txt);
    &:hover, &:focus {
      color: getColor(txt, lightest);
    }
    span {
      display: inline-block;
      font-size: 22px;
      padding-top: 5px;
    }
    .icon {
      display: inline-block;
      font-size: 40px;
    }
  }
  @include MQ(M) {
    margin-left: 0;
    margin-right: 0;
  }
}
#edit-search {
  [class^="icon-sailogy-"] {
    font-size: 28px;
    @include MQ(L) {
      display: none;
    }
  }
}
#boat-tab-gallery {
  .tab-content {
    background: getColor(bg, light);

  }
  .img-container {
    img, picture {
      max-width: 100%;
      max-height: 100%;
    }
  }
}

#boat-layout {
  /*margin-top: 30px;*/
  @include image-block(2, 1, getColor(bg, light));
}

.boat-features-list {
  margin-bottom: 0;
  clear: left;
  li {
    font-size: 22px;
    font-weight: 300;
    max-height: 30px;
    margin-bottom: 10px;
    [class^="icon-sailogy"] {
      vertical-align: middle;
      padding-right: 10px;
    }
    .icon-sailogy-certified {
      font-size: 28px;
      padding-right: 2px;
    }
  }
  @include MQ(M) {
    margin-top: 45px;
    margin-bottom: 15px;
  }
}
img[data-lazy] {
  visibility: hidden;
}
#cd-overview {
  .container {
    &.transparent {
      background: transparent;
    }
  }
  .cd-slick-carousel .slick-track .slick-slide {
    @include aspect-ratio(3, 2);
  }
  .tab-gallery-boat {
    margin-top: 0;
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 0;
  }
  .panel-body .contact-manager-box {
    margin: 0;
    background-color: transparent;
    padding: 15px 15px;
    .alert {
      margin-bottom: 20px;
      h3 {
        color: black;
        font-size: 22px;
      }
    }
  }
  @include MQ(M) {
    .tab-gallery-boat {
      padding-left: 0;
      padding-right: 0;
    }
  }
}
#accordion-gallery {
  margin-bottom: 0;
  .panel-body {
    background: getColor(bg, light);
    padding: 25px 15px;
    p {
      margin: 10px 0;
    }
    h5 {
      font-size: 16px;
      margin: 10px 0;
    }
    .bottom-btn {
      margin-top: 15px;
    }
  }
}
#accordion-sidebar {
  background: getColor(bg, light);
  .panel-title a {
    pointer-events: none;
    transition: none;
    &.manager-details {
      border-left: 5px solid getColor(info, base);
      padding-left: 10px;
    }
    .discount {
      font-size: 20px;
    }
    #toggle-info {
      pointer-events: auto;
    }
    .accordion-indicator {
      opacity: 0;
    }
    &.collapsed {
      pointer-events: auto;
      border-left: 0;
      padding-left: 25px;
      &.manager-details {
        border-left: 5px solid getColor(info, base);
        padding-left: 10px;
      }
      .accordion-indicator {
        opacity: 1;
      }
    }
    &.manager-details {
      display: block;
      @include clearfix();
      .radius-thumb, .manager-name {
        display: inline-block;
        width: auto;
        max-width: 200px;
        vertical-align: middle;
        @include MQ(S) {
          max-width: none;
        }
        @include MQ(L) {
          max-width: 130px;
        }
        @include MQ(XL) {
          max-width: 170px;
        }
      }
      .radius-thumb {
        width: 50px;
        height: 50px;
      }
      .manager-name {
        padding-left: 8px;
      }
    }
  }
  @include MQ(L) {
    .panel-title {
      font-size: 18px;
    }
    .price-panel-title {
      font-size: 16px;
    }
  }
  @include MQ(XL) {
    .panel-title {
      font-size: 20px;
    }
    .price-panel-title {
      font-size: 20px;
    }
  }
}
.boat-sidebar {
  padding: 0;
  background: getColor(bg, light);

  #vacationDetails {
    .panel-body .alert-dismissible {
      display: none;
    }
  }

  .panel-body {
    padding-top: 0;
    padding-bottom: 0;

    #pricing {
      padding: 15px;

      hr {
        margin-top: 10px;
        margin-bottom: 10px;
      }

      .badge {
        &.discount {
          background-color: getColor(alert, success);
        }
      }

      .txt-bigger {
        font-size: 1.3em;
      }

    }
  }

  .cd-btn-call {
    @include clearfix();
    padding-left: 0;
    padding-right: 0;
    z-index: 5;
    .btn {
      font-size: 18px;
      .small {
        color: rgba(white, .6);
        display: block;
        font-size: 16px;
      }
    }
  }
  @include MQ(L) {
    height: 100%;
  }
  .sidebar-container {
    top: auto;
    bottom: auto;
    width: auto;
    position: relative;
    @include MQ(L) {
      /*.cd-btn-call {
        position: fixed;
      }
      &.affix-bottom {
        .cd-btn-call {
          position: absolute;
        }
      }*/
      &.affix-top {
        position: relative;
        .cd-btn-call {
          /*position: fixed;*/
          bottom: 0;
          width: 100%;
        }
      }
      &.affix {
        position: fixed;
        top: 0;
      }
      &.affix-bottom {
        position: absolute;
      }
    }
  }
}
#cd-location {
  margin: -35px -15px;
  .cd-fly-section {
    margin-right: -15px;
  }
  .flexRow {
    min-height: 300px;
  }
}
.cd-fly-section {
  background: getColor(info, fly);
  padding: 15px;
  p {
    margin-bottom: 0;
  }
  .btn-has-brd {
    white-space: normal;
    width: 100%;
    padding: 15px;
    color: getColor(txt, lightest);
    position: relative;
    padding-left: 60px;
    &:hover, &:focus {
      background: white;
      color: getColor(info, fly);
    }
  }

  [class^="icon-sailogy"] {
    position: absolute;
    @include center(y);
    font-size: 42px;
    left: 15px;
    margin-right: 15px;
  }
  @include MQ(S) {
    [class^="icon-sailogy"] {
      position: static;
      transform: none;
      vertical-align: middle;
    }
  }
  @include MQ(L) {
    [class^="icon-sailogy"] {
      position: absolute;
      left: 15px;
      @include center(y);
    }
  }
}
.cd-terms {
  @include clearfix();
  background: getColor(bg, light);
  article {
    margin-bottom: 25px;
    padding: 0 20px;
    background: getColor(bg, light);
    h2 {
      font: {
        size: 28px;
        weight: 300;
        color: getColor(txt, darkest);
      }
      margin-bottom: 25px;
    }
    h3 {
      font: {
        size: 26px;
        weight: 300;
      }
      margin: 15px 0;
    }
    section {
      margin: 15px 0;
    }
    p {
      font-size: 16px;
    }
  }
  @include MQ(M) {
    padding-top: 25px;
  }
}
.cd-warranty {
  background: getColor(neutral, bg);
  padding: 20px 0;
  h2 {
    font: {
      size: 28px;
      weight: 300;
    }
    margin-bottom: 25px;
  }
  article {
    background: getColor(neutral, bg);
    p {
      font: {
        size: 22px;
        weight: 300;
        line-height: 16px;
      }
      margin: 25px 0;
    }
  }
}
#boat-suggestions {
  background: darken(getColor(neutral, bg), 5%);
  padding-top: 20px;
  padding-bottom: 20px;
}
#main-boat-call {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  margin-bottom: 0;
  padding: 10px 0;
  i {
    font-size: 28px;
    margin-right: 5px;
  }
  .text-boat-call {
    display: inline-block;
    vertical-align: middle;
  }
  header {
    font-size: 18px;
  }
  footer {
    opacity: .6;
    font-size: 16px;
  }
  @include MQ(L) {
    position: relative;
  }
  @include MQ(mM) {
    padding: 4px 0;
  }
}
.upper-nav, #upper-header {
  background: transparent !important;
  height: 65px !important;
}
.scroll-bottom-area {
  position: relative;
}
body.boat {
  background: {
    size: 100% 100vh;
    repeat: no-repeat;
    attachment: scroll;
    position: top center;
    color: getColor(neutral, bg);
    image: url('#{$assets_path}/img/bg/boat/bg.jpg');
  }
  .jumbotron {
    background: transparent;
    padding: 0;
    margin: 0 -15px;
  }
}
.ask-manager {
  position: relative;
  .accordion-indicator {
    display: none;
  }
}

#allotment-caption {
  display: none;
}
