.sm-radius-thumb {
  display: inline-block;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  overflow: hidden;
  float: left;
  img {
    width: 100%;
  }
}
