body.login, 
body.remindpass {
  color: getColor(txt, lightest);

  > .wrapper {
    min-height: 80vh;
    position: relative;
    background: {
      image: url(#{$assets_path}/img/bg/login.jpg);
      size: cover;
      repeat: no-repeat;
      position: left center;
    }

    @include MQ(mM) {
      min-height: initial;
    }
  }

  p {
    color: getColor(txt, lightest);
  }

  h2 {
    font-weight: 600;
    color: getColor(txt, lightest);
  }

  .form-login, .form-remind {
    padding-top: 2%;
    padding-bottom: 15px;
    display: table;
    min-height: 80vh;
    width: 100%;
    
    form {
      display: table-cell;
      vertical-align: middle;
    }

    .help-inline {
      text-align: left !important;
      text-shadow: 0 1px 2px getColor(error, light);
      color: getColor(txt, lightest);
    }

    a {
      color: getColor(txt, lightest);
      font-weight: 400;
    }

    .btn {
      text-decoration: none;
      font-weight: 600;
    }

    .facebook {
      background: getColor(etc, fbLoginDark);
      border-color: getColor(etc, fbLoginDark);
      text-decoration: none !important;
      margin: 0;
      padding: 10px;
      &:hover {
        background: darken(getColor(etc, fbLoginDark), 7%);
        border-color: darken(getColor(etc, fbLoginDark), 7%);
      }
    }

    .footer {
      a {
        display: block;
        padding: 5px 0 0;
        text-decoration: underline;
        &:hover {
          text-decoration: none;
        }
      }
    }

  }
}
