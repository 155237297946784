// Faq accordion

.plus-accordion {
  padding-top: 10px;
  .accordion-title {
    margin: 5px 0 0;
    padding: 0;
  }
  .panel {
    border-radius: 0;
    border: 0;
    box-shadow: inset 0 0 0;
  }
  .panel-heading {
    background-color: getColor(bg, light);
    border-bottom: 1px solid getColor(neutral, base);
    padding: 0;
  }
  .panel-title {
    font-size: 20px;
    font-weight: 300;
    color: getColor(info, dark);
    .right-controls {
      padding: 15px 0 0 0;
    }
    a {
      display: block;
      position: relative;
      padding: 15px 45px 25px 0;
      transition-duration: .2s;
      color: getColor(info, base);
      @include MQ(mM) {
        font-size: 21px;
      }
      &.collapsed {
        color: getColor(info, dark);
        .close-it {
          display: none;
        }
      }
      &:not(.collapsed) {
        .show-it {
          display: none;
        }
      }
      .small {
        padding-left: 20px;
        font-size: 17px;
        @include MQ(mM) {
          display: none;
        }
        i {
          font-style: normal;
          color: getColor(info, base);
        }
        & > span {
          padding-left: 10px;
        }
      }
      button {
        transition: .2s;
        margin: -8px 0px 0 15px;//-8px -25px 0 0;
        float: right;
        @include MQ(mM) {
          font-size: 15px;
          padding: 7px 5px;
        }
        @include MQ(mS) {
          font-size: 13px;
        }
      }
    }
    &:hover {
      a {
        color: getColor(info, base);
      }
    }
  }
  .panel-body {
    border-bottom: 1px solid getColor(neutral, base);
    font-size: 16px;
  }
  .panel-heading + .panel-collapse > .panel-body, .panel-heading + .panel-collapse > .list-group {
    border-top: 0;
  }
  .accordion-indicator {
    display: inline-block;
    border-radius: 50px;
    width: 40px;
    height: 40px;
    border: 1px solid getColor(info, base);
    background-color: getColor(bg, light);
    position: absolute;
    right: 0;
    @include center(y);
    transition: background-color .2s;
    span {
      width: 50%;
      height: 2px;
      background-color: getColor(info, base);
      display: block;
      position: absolute;
      top: 18px;
      right: 10px;
      transition: all .2s;
      &.line-one {
        transform: rotate(180deg);
      }
      &.line-two {
        opacity: 0;
      }
    }
    &:hover {
      border: 1px solid lighten(getColor(info, base), 10%);
      span {
        background-color: lighten(getColor(info, base), 10%);
      }
    }
  }
  .accordion-toggle.collapsed {
    .accordion-indicator {
      span {
        &.line-one {
          transform: rotate(90deg);
        }
        &.line-two {
          opacity: 1;
        }
      }
    }
  }

  &.panel-group.borderless {
    margin-bottom: 10px;
    .panel-body {
      border-bottom: 0;
      padding: 0;
    }
    .panel-heading {
      border: 0;
      .panel-title {
        > a {
          display: block;
          padding: 20px 10px 15px 12px;
        }
      }
    }
  }
}
.arrow-accordion {
  margin-bottom: 0;
  &.panel-group .panel + .panel {
    margin-top: 0;
  }
  .panel {
    border-radius: 0;
    border: 0;
    box-shadow: 0 0 0;
    margin-top: 0;
  }
  .panel-heading {
    background-color: getColor(bg, light);
    border-bottom: 1px solid getColor(neutral, base);
    padding: 0;
  }
  .panel-title {
    font-size: 26px;
    font-weight: 300;
    color: getColor(info, dark);
    a {
      display: block;
      position: relative;
      padding: 25px;
      transition: padding .2s ease-in;
      color: getColor(info, base);
      &.collapsed {
        color: getColor(info, dark);
      }
    }
    &:hover {
      a {
        color: getColor(info, base);
      }
    }
    &.price-panel-title {
      font-size: 20px;
      font-weight: 400;
      .amount {
        float: right;
        padding-right: 20px;
      }
    }
  }
  .panel-body {
    border-bottom: 0;
    font-size: 16px;
    background-color: getColor(neutral, bgLight);
    .inner-panel-body {
      padding: 0 15px;
    }
    .alert-msg {
      margin: 0 0 15px;
      padding: 15px 25px;
      color: getColor(txt, darkest);
      a {
        text-decoration: underline;
      }
      p {
        line-height: 1em;
      }
      small {
        font-size: 14px;
        color: getColor(txt, darkest);
      }
      .row-data {
        color: getColor(txt, light);
        font-size: 16px;
        .date {
          color: getColor(info, base);
          text-decoration: none;
          display: block;
          padding-top: 5px;
          &:hover, &:focus {
            color: getColor(txt, light);
          }
        }
      }
      .close {
        right: 0;
        top: 0;
      }
      &.bottom {
        margin-bottom: -15px;
        margin-top: 15px;
      }
    }
  }
  .panel-collapse > .panel-body,
  .panel-collapse > .list-group {
    border-top: 0;
    padding-left: 0;
    padding-right: 0;
  }
  #cd-location-collapse .js-tabcollapse-panel-body {
    padding: 0 15px;
    margin-right: -30px;
    margin-left: -30px;
    @include MQ(M) {
      overflow: hidden;
      margin-right: 0;
      margin-left: 0;

      .cd-map {
        position: relative;
        left: -30px;
      }
    }
  }
  .js-tabcollapse-panel-body {
    background: getColor(bg, light);
    padding: 15px;
    .bottom-btn {
      margin-top: 25px;
    }
    p {
      margin: 10px 0;
    }
    h5 {
      font-size: 16px;
      margin: 10px 0;
    }
  }
  .accordion-indicator {
    display: inline-block;
    position: absolute;
    right: 25px;
    font-size: 25px;
    @include center(y);
    &:before {
      content: '\f106';
      font-family: 'FontAwesome';
    }
  }
  .accordion-toggle.collapsed, .js-tabcollapse-panel-heading.collapsed {
    .accordion-indicator {
      &:before {
        content: '\f107';
        font-family: 'FontAwesome';
      }
    }
  }
}

