.map-vertical, #map-canvas, #map {
  .tooltip-wrapper {
    overflow: hidden;
    background: getColor(bg, light);
    .row {
      padding: 0;
      margin: 0 auto;
    }
    h3 {
      background: none;
      padding: 15px 0 5px 0;
      font: {
        size: 20px;
        weight: 600;
      }
      color: getColor(info, base);
    }
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      li {
        font: {
          size: 14px;
          weight: 300;
        }
        margin: 0;
        padding: 0;
        strong {
          font: {
            size: 14px;
            weight: 600;
          }
        }
      }
    }
    .thumbnail {
      display: block;
      border-radius: 0;
      border: none;
      padding: 0;
      width: 500px;
      height: 250px;
      img {
        padding: 0;
        margin: 0;
        width: 100%;
        height: 100%;
      }
    }
    a.btn {
      border-color: getColor(txt, lightest);
      border-radius: 0;
      font: {
        size: 16px;
        weight: 600;
      }
      float: right;
      margin: 0;
    }
  }
}
