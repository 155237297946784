body.weekend {
  .jumbotron {

    & {
      padding: 0 !important;
      height: 220px;
      @include MQ(mM) {
        height: 320px;
      }
      @include MQ(mM) {
        height: auto;
      }
      .header-container {
        header {
          height: 200px;
          @include MQ(mM) {
            height: auto;
          }
        }
      }
    }

    .icon-main {
      @include MQ(mM) {
        margin-top: 20px;
      }
    }
    @include MQ(mM) {
      * {
        text-align: center;
      }
    }

    &:not(.imgix-fluid) {
      background-image: url('#{$assets_path}/img/bg/cabin-charter/regatta_bg_xl.jpg');
      @include MQ(mL) {
        background-image: url('#{$assets_path}/img/bg/cabin-charter/regatta_bg_l.jpg');
      }
      @include MQ(mM) {
        background-image: url('#{$assets_path}/img/bg/cabin-charter/regatta_bg_m.jpg');
      }
      @include MQ(mS) {
        background-image: url('#{$assets_path}/img/bg/cabin-charter/regatta_bg_s.jpg');
      }
    }
  }

  section.filters {
    form {
      margin: 0;
    }
  }

  section#boats-section {
    @include MQ(mM) {
      &#filters {
        background: getColor(neutral, bg);
      }
    }
    &.article .container, .row > .col-xs-12 {
      padding: 20px 12px;
      p, ul, ol, table td, table th {
        font-size: 18px;
        line-height: 29px;
        color: #7b7b7b
      }
      ul {
        margin-left: 15px;
        list-style: inside disc;
      }
      ol {
        margin-left: 15px;
        list-style: inside decimal;
      }
    }
    .total-price {
      font-size: 32px;
      font-weight: 600;
      color: getColor(info, darker);
    }

    .boat-related {
      height: 420px;
      @include MQ(mL) {
        &, header {
          height: 210px;
        }
      }

      footer ul li {
        @include MQ(mM) {
          &:nth-child(n+4) {
            display: table;
          }
        }
      }
    }
  }

}
