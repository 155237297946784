@CHARSET "UTF-8";
#inrecruiting_announces_list { 
border:5px solid #ddd;
background-color: #fefefe;
padding: 5px;
border-radius: 5px;}
#inrecruiting_announces_list ul{ list-style:none; margin:0px;padding:0px;}
#inrecruiting_announces_list ul li{ text-align:left; list-style:none; font-size:12px;margin:15px 0px 15px 0px; padding:3px 5px; border-bottom: 1px dotted #ddd}
#inrecruiting_announces_list ul li a.titolo{color:#000; text-decoration:underline; font-size:16px;margin-bottom:3px;}
#inrecruiting_announces_list ul li.inEvidenza{ background-color: yellow;}
#inrecruiting_announces_list ul li.inGrassetto a.titolo{ font-weight:bold; }
#inrecruiting_announces_list ul li .info{ color:#555; padding: 0px; background: transparent; border:none;}