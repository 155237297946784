body.lux {
  .footer-triangle {
    position: absolute;
    width: 100%;
    height: 190px;
    overflow: hidden;
    z-index: 0;
    bottom: 0;
    left: 0;
    background: {
      image: url('#{$assets_path}/img/bg/lux/jb.png');
      size: 100% 100%;
    }
  }
  .jumbotron {
    > .container {
      position: relative;
    }
    position: relative;
    color: getColor(txt, lightest);
    .icon-main {
      font-size: 97px;
      display: block;
      margin: 0 0 20px;
      @include MQ(mS) {
        font-size: 89px;
      }
    }
    &.jumbotron-lux-search {
      height: auto;
      padding: 156px 0 106px;
      background: {
        size: cover;
        position: bottom center;
        attachment: scroll;
      }
      background-image: url('#{$assets_path}/img/bg/lux/xl.jpg');
      @include MQ(mL) {
        background-image: url('#{$assets_path}/img/bg/lux/l.jpg');
      }
      @include MQ(mM) {
        background-image: url('#{$assets_path}/img/bg/lux/m.jpg');
      }
      @include MQ(mS) {
        background-image: url('#{$assets_path}/img/bg/lux/s.jpg');
      }
      .h1, h1 {
        font-size: 51px;
      }
      .h2 {
        color: getColor(txt, lightest);
        font-size: 22px;
        margin: 20px 0 0;
      }
      .h3 {
        color: getColor(neutral, base);
        font-size: 18px;
        font-weight: 400;
        margin: 8px 0 0;
      }
    }
    &.jumbotron-lux-boat {
      height: 1010px;
      padding-top: 300px;
      background: {
        size: cover;
        position: top center;
        attachment: scroll;
      }

      &:not(.imgix-fluid) {
        background-image: url('#{$assets_path}/img/bg/lux/bg.jpg');
      }

      @include MQ(mL) {
        height: 870px;
        padding-bottom: 35px;
        padding-top: 130px;
      }
      @include MQ(mM) {
        height: auto;
        padding-bottom: 35px;
        padding-top: 90px;
      }
      header {
        text-align: center;
        .h1 {
          font-weight: 300;
          border-bottom: 2px solid white;
          display: inline;
          clear: both;
          margin-right: 0;
          padding: 0 0 10px;
          font-size: 52px;
        }
        .explain {
          font-weight: 400;
          font-size: 32px;
          padding: 25px 0 0;
          display: inline-block;
          i {
            font-size: 43px;
            float: left;
            padding-right: 10px;
          }
        }
      }
      & + #photos {
        margin: -220px 0 60px;
        @include MQ(mM) {
          margin: 0px 0 60px;
        }
      }
    }
    .footer {
      position: absolute;
      bottom: 270px;
      width: 100%;
      left: 0;
      @include MQ(mM) {
        position: relative;
        padding-top: 40px;
        bottom: auto;
      }
      section {
        @include MQ(mM) {
          text-align: center;
        }
      }
      .btn {
        padding: 20px 15px;
        white-space: nowrap;
        font-size: 16px;
        max-width: 100%;

        .btn-container {
          display: inline-block;
        }
        i {
          font-size: 25px;
          float: left;
          padding-right: 7px;
        }
        @include MQ(mL) {
          padding: 15px 10px;
          font-size: 17px;
        }
        @include MQ(mM) {
          font-size: 18px;
          margin: 0 auto 15px;
        }
      }
    }
  }

  .wrapper {
    .popup-slider {
      .slick-slider {
        .slick-slide, .slick-track {
          background: getColor(bg, slider) !important;
        }
      }
    }
  }

  section.photos {
    margin: -255px 0 0;
    @include MQ(mM) {
      margin-top: 0;
    }

    .slick-slider {
      .slick-slide, .slick-track {
        background: getColor(neutral, bg);
      }

      position: relative;
    }
    .slick-next {
      right: 250px;
      @include MQ(mM) {
        right: 25px;
      }
    }
    .slick-counter {
      font-size: 18px;
      padding-right: 200px;
      @include MQ(mM) {
        padding-right: 0;
      }
    }
  }

  .wrapper > section {
    position: relative;
    &#search-results {
      background: getColor(bg, bgLightInactive);
      padding-bottom: 15px;
      #results-row {
        padding-left: 25%;
        position: relative;
        @include MQ(mL) {
          padding-left: 0;
        }
        &.full-width {
          padding-left: 0;
        }
      }
      .item {
        &.narrow {
          max-width: 33.33333333%;
          min-width: 33.33333333%;
          @include MQ(mL) {
            max-width: none;
            min-width: none;
          }
        }
        .inside {
          @include transition(.25s);
          margin: 25px -7px 0;
          background: getColor(bg, light);
          display: block;
          &:hover {
            opacity: .8;
          }
          header {
            width: 100%;
            height: 219px;
            overflow: hidden;
            position: relative;
            background: {
              color: darken(white, 18%);
              image: url('#{$assets_path}/img/ico/boat-placeholder.png');
              position: center;
              repeat: no-repeat;
            }
            img {
              min-width: 100%;
              min-height: 100%;
              display: block;
              margin: 0 auto;
              position: absolute;
              top: 0;
              right: -500px;
              left: -500px;
            }
            .title {
              position: absolute;
              bottom: 0;
              left: 0;
              width: 100%;
              background: rgba(black, .7);
              padding: 7px 0 7px 15px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              color: white;
              font: {
                size: 20px;
                weight: 400;
              }
            }
          }
          ul {
            word-wrap: break-word;
            margin: 2px 0 6px 0;
            font-size: 16px;
            display: block;
            list-style-type: none;
            li {
              padding: 7px 0 6px 0;
              line-height: 15px;
              .name {
                width: 30%;
                float: left;
                color: getColor(neutral, lighter);
                display: inline-block;
                vertical-align: top;
                padding-left: 10px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
              .value {
                color: getColor(info, darker);
                display: inline-block;
                vertical-align: top;
                /*width: 68%;*/
              }
            }
          }
          section {
            padding: 0;
            clear: both;
            border-top: 1px solid getColor(border, base);
            ul {
              margin: 5px 15px 0 15px;
              li {
                padding: 0 0 7px 0;
                .name {
                  font-size: 18px;
                  color: getColor(info, regular);
                  padding: 9px 0 0 10px;
                }
                .value {
                  font: {
                    size: 24px;
                    weight: 100;
                  }
                  line-height: 30px;
                  span {
                    font-size: 18px;
                    display: block;
                    line-height: 17px;
                    padding-bottom: 4px;
                  }
                }
              }
            }
          }
        }
      }
      #filters {
        float: left;
        width: 25%;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
        @include MQ(mL) {
          position: relative;
          width: 100%;
          float: none !important;
          display: block;
          position: relative;
        }
        #filters-content {
          background: getColor(bg, lightEven);
          margin: 0 15px;
          border: {
            left: 1px solid getColor(border, input);
            right: 1px solid getColor(border, input);
            top: 1px solid getColor(border, input);
          }
          .portlet {
            background: transparent;
          }
          .portletLength {
            hr {
              margin: 0 -20px;
            }
          }
          .portletHeader {
            a {
              font-size: 22px;
              color: getColor(info, dark);
            }
            .show-hide-icon {
              margin-right: 6px;
              margin-top: 7px;
            }
          }
          a.collapsed {
            .show-hide-icon {
              margin-right: 5px;
            }
          }
          .btn-clear-filter {
            background: transparent;
            color: getColor(info, base);
            font-size: 18px;
            border: 0;
            i {
              display: none;
            }
          }
          @include MQ(mL) {
            float: none !important;
            display: block;
            position: relative;
          }
          .filters-buttons {
            padding-top: 10px;
            padding-bottom: 10px;
            .btn {
              display: block;
              width: 100%;
            }
          }
        }
      }
    }

    &#filters-header {

      form {
        margin: 0;
      }
      select {
      }

      a {
        &#toggle-filters {
          @include transition(.25s);
          color: getColor(info, darker);
          background: getColor(bg, lightEven);
          display: block;
          padding: 23px 0 24px 20px;
          border: {
            left: 1px solid getColor(border, input);
            right: 1px solid getColor(border, input);
          }
          font: {
            size: 22px;
            weight: 400;
          }
          i {
            @include transition(.25s);
            color: getColor(neutral, txt);
            float: right;
            margin: 3px 20px 0 0;
          }
          @include MQ(mL) {
            border: 0;
            padding: 17px 0 15px 14px;
            text-align: left;
            i {
              margin: 3px 13px 0 0;
            }
          }
          &:hover {
            opacity: .8;
          }
          &[aria-expanded=false] {
            > i {
              @include rotate(180deg);
              margin-top: 3px;
              @include MQ(mL) {
                margin: 3px 25px 0 0;
              }
            }
          }
        }
      }
    }

    &.video, &.gallery {
      margin: -230px 0 60px;
      @include MQ(mM) {
        margin: 0px 0 60px;
      }
      .h1 {
        margin: 90px 0 30px;
      }
    }

    &.price {
      padding: 90px 0 200px;
      height: 900px;
      color: getColor(txt, lightest);
      background: {
        image: url('#{$assets_path}/img/bg/lux/price.jpg');
        size: cover;
        position: top center;
        attachment: scroll;
      }

      @include MQ(mM) {
        height: auto;
        padding: 30px 5px 56px;
        text-align: center;
        .h1 {
          margin-top: 28px;
          margin-bottom: 39px;
        }
      }
      .h1 {
        color: getColor(txt, lightest);
        margin: 0;
        font-weight: 400;
      }
      header {
        @include opacity(.6);
        font-size: 17px;
        margin-top: 30px;
        &.megacenter {
          margin-top: 128px;
          @include MQ(mM) {
            margin-top: 50px;
          }
        }
        &.megafooter {
          margin-top: 56px;
          @include MQ(mM) {
            margin-top: 50px;
          }
        }
      }
      article {
        margin: 0;
        font-size: 23px;
        &.megacenter {
          font-size: 28px;
        }
      }
      & + #specs {
        margin: -255px 0 0;
        @include MQ(mM) {
          margin-top: 0;
        }
      }
    }

    &#specs {
      padding: 0 0 50px;
      font-size: 18px;
      .h1 {
        color: getColor(txt, darkest);
      }
      article {
        margin-top: 0;
        background: getColor(bg, lightEven);
        padding-bottom: 40px;
        padding-top: 20px;
        aside {
          p {
            font-size: 18px;
            text-align: left;
            color: getColor(txt, darkest);
            opacity: .6;
          }
        }
      }
      .footer {
        padding: 22px 0 64px;
        font-weight: 100;
        text-align: center;
      }
    }

    &#map {
      color: getColor(txt, darkest);
      height: 600px;
      margin-bottom: 50px;
      background: {
        image: url('#{$assets_path}/img/bg/lux/map.jpg');
        size: cover;
        position: top center;
        attachment: scroll;
      }
      display: table;
      width: 100%;
      @include MQ(mM) {
        margin-bottom: 0;
        height: 700px;
        .footer-triangle {
          height: 40px;
        }
      }
      header {
        display: table-cell;
        width: 100%;
        padding: 20px 0 50px;
        vertical-align: middle;
      }
      .h1 {
        padding-bottom: 25px;
        font: {
          size: 28px;
          weight: 400;
        }
      }
      .h2 {
        font: {
          size: 28px;
          weight: 200;
        }
      }
    }

    &.similar {
      padding: 90px 0;
      .h1 {
        margin: 0;
        padding: 0 0 66px;
      }
      .similar-boats {
        .item {
          display: block;
          position: relative;
          overflow: hidden;
          height: 439px;
          margin-bottom: 15px;
          transition: .2s;
          background: {
            color: darken(white, 18%);
            image: url('#{$assets_path}/img/ico/boat-placeholder.png');
            position: center;
            repeat: no-repeat;
          }
          img {
            min-height: 100%;
            min-width: 100%;
            display: block;
            margin: 0 auto;
            position: absolute;
            top: 0;
            right: -500px;
            left: -500px;
          }
          header {
            position: absolute;
            left: 0;
            bottom: 0;
            height: 55px;
            overflow: hidden;
            width: 100%;
            span {
              font-size: 20px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              z-index: 10;
              display: block;
              position: absolute;
              padding: 12px 0 14px 15px;
              left: 0;
              bottom: 0;
              width: 100%;
              color: white;
            }
            &:after {
              content: '';
              position: absolute;
              left: -10%;
              bottom: -7px;
              width: 120%;
              z-index: 9;
              height: 95px;
              background: rgba(black, .5);
              overflow: hidden;
              filter: blur(20px);
            }
          }
          &:hover {
            box-shadow: 0 5px 10px darken(white, 35%);
          }
        }
      }
    }

  }
}
