@include keyframes(roll) {
  0% {
    transform: translateX(-100px) rotate(360deg);
    opacity: 0;
  }

  100% {
    transform: translateX(0px) rotate(0deg);
    opacity: 1;
  }
}

@include keyframes(moveUp) {
  0% {
    transform: translateY(40px);
  }

  100% {
    transform: translateY(0px);
  }
}

@include keyframes(fadeIn) {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@include keyframes(scale) {
  0% {
    transform: scale(1, 1);
  }

  100% {
    transform: scale(1.1, 1.1);
  }
}

@include keyframes (bounceInLeft) {
  0%, 60%, 75%, 90%, 100% {
    transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
  }

  0% {
    opacity: 0;
    transform: translate3d(-3000px, 0, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(25px, 0, 0);
  }

  75% {
    transform: translate3d(-10px, 0, 0);
  }

  90% {
    transform: translate3d(5px, 0, 0);
  }

  100% {
    transform: none;
    opacity: 1;
  }
}

@include keyframes (fadeInRight) {
  0% {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }

  100% {
    opacity: 1;
    transform: none;
  }
}
