* {
  transition-duration: .1s;
}
html * {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body, .body {
  padding: 0;
  overflow-x: hidden;
}

html, body, .body {
  min-height: 100%;
}
h1, h2, h3, h4, h5 {
  margin: 0;
  padding: 0;
}
h1 {
  padding-bottom: 10px;
  color: getColor(txt, base);
  font-size: 16px;
}
.container {
  padding: 0;
  &.has-pad {
    padding-top: 0;
    padding-left: 15px;
    padding-right: 15px;
  }
}
a, a:hover, a:focus {
  text-decoration: none;
}
hr {
  border-color: getColor(border, base);
}
article {
  margin: 25px 0;
}
.textcut {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.rounded50 {
  border-radius: 50%;
}
.tt-dropdown-menu { // typehead dropdown menu
  border-radius: 0;
  right: 0!important; // make it 100% width of parent input
}
.tt-suggestion {
  cursor: pointer;
}
.tt-is-under-cursor,
.tt-suggestion:hover {
  background-color: darken(getColor(bg, light), 5%);
}
.portlet {
  position: relative;
  margin: 0 0 20px 0;
  padding: 5px 20px;
  box-sizing: border-box;
  font-size: 14px;
  clear: both;
  background-color: getColor(bg, light);
  @include MQ(mL) {
    font-size: 12px;
    .portletMainMarinas, .portletBrands {
      .textcut {
        width: 75px;
        font-size: 12px;
      }
      table tr td:first-child {
        padding-right: 5px;
        input {
          margin-top: 1px;
        }
      }
    }
  }
  .show-hide-icon {
    color: getColor(info, base);
    float: right;
    margin-top: 2px;
    transform: rotate(180deg);
    @include MQ(mL) {
      display: block;
      float: right;
      margin-top: 5px;
    }
  }
  &.collapsed, .collapsed {
    .show-hide-icon {
      transform: rotate(0deg);
    }
  }
  .portletHeader {
    margin: 0 0 10px 0;
    color: getColor(info, base);
    font-size: 16px;
    span {
      margin-left: 3px;
      // color: getColor(txt, darkest);
      font-size: 9px; /*13px;*/
    }
  }
  .portletItem {
    padding: 10px 0;
    &:last-child {
      border-bottom: 1px solid getColor(border, base);
    }
  }
  table {
    width: 100%;
  }
  hr {
    margin: 10px 0 5px 0;
  }
  ul {
    margin: 0;
    padding: 0;
    list-style: none none;
  }
  li {
    display: block;
    margin: 0;
    padding: 0;
  }
  label {
    display: inline;
    margin: 0;
    font-weight: normal;
  }
}
.portletGoogleMap {
  display: none;
  margin: 0;
  padding: 0;
  &.active {
    display: block;
  }
  .gmap {
    width: 100%;
    height: 331px;
    margin-bottom: 15px;
    border: 1px solid getColor(border, base);
  }
  img {
    width: 100%;
    height: 100%;
  }
  .btn {
    display: block;
    margin: 0 20px;
    padding: 4px 12px;
    i {
      margin-right: 3px;
    }
  }
}
.portletMainMarinas {
  margin-bottom: 0;
  padding-top: 15px;
  padding-bottom: 0;
  table {
    tr {
      td {
        padding: 0 10px 12px 0;
        vertical-align: top;
        &:first-child {
          width: 1%;
        }
        &:last-child {
          padding-right: 0;
          font-size: 13px;
          text-align: right;
        }
      }
    }
  }
  .textcut {
    width: 120px;
  }
  hr {
    margin-bottom: 0
  }
}
.portletBoatTypes {
  margin-bottom: 0;
  padding-top: 15px;
  ul {
    margin-bottom: 15px
  }
  li {
    padding: 0 !important
  }
  input {
    margin-right: 12px
  }
  label {
    display: block;
    cursor: pointer;
    span {
      margin-right: 5px;
      line-height: 1;
      font-size: 34px;
      color: getColor(neutral, txt);
      vertical-align: middle;
    }
    &.selected, &:hover {
      color: getColor(txt, darkest);
      span { color: getColor(info, regular) }
    }
  }
}
.portletTotalPrice {
  margin-bottom: 0;
  hr {
    margin: 25px 0 10px 0
  }
}
.portletLength {
  margin-bottom: 0;
  .portletHeader {
    margin-bottom: 22px
  }
  .btn-group {
    position: absolute;
    right: 20px;
    top: 7px;
    input { margin: 0 }
    .btn:active, .btn.active {
      box-shadow: none;
    }
  }
  hr {
    margin: 3px 0 0;
  }
}
.portletPeople {
  margin-bottom: 0;
  .portletHeader {
    margin-bottom: 22px
  }
  hr {
    margin: 3px 0 0;
  }
}
.portletBrands {
  margin-bottom: 0;
  td {
    padding: 0 5px 13px 0;
    &:first-child {
      width: 1%;
    }
    &:last-child {
      padding-right: 0;
      font-size: 13px;
      text-align: right;
      white-space: nowrap;
    }
  }
  .textcut {
    width: 140px;
  }
  hr {
    margin: 3px 0 0 0;
  }
  .btn-clear-filter {
    width: 100%;
  }
}
.portletBoatTypes, .portletTotalPrice, .portletLength, .portletCabins,
.portletToilets, .portletYears, .portletBrands, .portletMainMarinas {
  margin-bottom: 0;
  padding: 0px 15px 0 20px;
  .portletHeader {
    margin: 0;
    cursor: pointer;
    a {
      &:focus {
        outline: 0;
      }
      width: 100%;
      display: block;
      padding: 14px 0;
    }
  }
  li {
    padding: 5px 0;
  }
  label {
    display: block;
    cursor: pointer;
    &.selected, &:hover {
      color: getColor(txt, darkest);
      span {
        color: getColor(info, regular);
      }
    }
  }
  hr {
    margin: 3px 0 0;
  }
}
.portletShare {
  padding: 20px;
  .portletHeader {
    margin-bottom: 20px;
  }
  ul {
    display: table;
    width: 115%;
    margin-left: -20px;
  }
  li {
    display: table-cell;
    width: 25%;
    font-size: 22px;
    line-height: 1;
    text-align: center;
    a {
      display: inline-block;
      color: getColor(info, regular);
      vertical-align: middle;
      &:hover { color: getColor(info, hover) }
    }
  }
}
.portletSubscribe {
  padding: 20px;
  a {
    color: getColor(txt, base);
    text-decoration: underline;
  }
  a:hover {
    color: getColor(txt, darkest);
  }
  p {
    line-height: 1.2
  }
  #already-registered {
    margin-bottom: 17px;
    font-style: italic;
    font-size: 13px;
  }
  form {
    margin-bottom: 15px;
    input.form-control {
      margin-bottom: 15px;
      font-size: 13px;
      border-radius: 0;
    }
    .btn {
      width: 100%;
    }
  }
  .agreement-notify {
    margin: 0;
    font-size: 9px;
  }
}
.portletRecentSearches {
  padding-top: 20px;
  padding-bottom: 5px;
  li {
    padding: 15px 0;
    line-height: 1.2;
    border-bottom: 1px solid getColor(border, base);
    &:last-child { border: 0 }
    a { color: getColor(txt, base) }
    a:hover {
      color: getColor(txt, darkest);
      strong { text-decoration: underline }
    }
  }
  strong {
    font-size: 14px;
    font-weight: bold;
  }
  span {
    display: block;
    margin: 0;
    font-size: 13px;
  }
  em {
    font-size: 13px;
    font-style: italic;
  }
}

.page-header {
  padding-bottom: 5px;
  margin: 20px 0 15px;
}
.hideme {
  display: none;
  visibility: hidden;
}
