body.vacation-summary {
  color: getColor(txt, base);
  .bg-grey {
    background: getColor(neutral, bg) !important;
  }
  .bk-prc-btn {
    @include MQ(mM) {
      font-size: 16px;
      padding: 10px 0;
      margin: -8px -25px 0 0;
    }
  }
  section.jumbotron.jumbotron-vacation-summary {
    height: 550px;
    color: getColor(txt, lightest);
    overflow: hidden;
    padding-top: 0;
    padding-bottom: 0;
    position: relative;
    background: {
      size: cover;
      repeat: no-repeat;
      attachment: scroll;
      position: top center;
    }
    background-image: url('#{$assets_path}/img/bg/vacation/xl.jpg');
    @include MQ(mL) {
      background-image: url('#{$assets_path}/img/bg/vacation/l.jpg');
    }
    @include MQ(mM) {
      background-image: url('#{$assets_path}/img/bg/vacation/m.jpg');
    }
    @include MQ(mS) {
      background-image: url('#{$assets_path}/img/bg/vacation/s.jpg');
    }
    @include MQ(mL) {
      height: auto;
    }
    .container {
      position: relative;
      min-height: 550px;
      @include MQ(mL) {
        height: auto;
        min-height: 0;
      }
    }
    .jumbo-header {
      margin: 120px 0 0;
      @include MQ(mL) {
        margin: 80px 0 0;
      }
      @include MQ(mM) {
        margin-top: 20px;
      }
      h1 {
        color: white;
        font: {
          size: 42px;
          weight: 400;
        }
        @include MQ(mL) {
          font-size: 27px;
          margin-bottom: 10px;
        }
      }
      h2 {
        color: white;
        font: {
          size: 29px;
          weight: 400;
        }
        @include MQ(mL) {
          font-size: 23px;
        }
        @include MQ(mM) {
          margin: 0;
          font-size: 20px;
        }
      }
    }
    .block-steps {
      position: absolute;
      left: 0;
      bottom: 0;
      padding: 0 15px;
      width: 100%;
      @include MQ(mM) {
        padding: 0;
      }
      @include MQ(mL) {
        position: relative;
        padding: 0 15px;
      }
      .row {
        &:last-child {
          padding: 0 0;
        }
      }
      .h3 {
        margin-left: 15px;
        color: white;
        font-size: 29px;
        @include MQ(mL) {
          display: none;
        }
      }
      a {
        transition: .25s;
        display: block;
        vertical-align: middle;
        height: 100px;
        @include MQ(mL) {
          height: 80px;
        }
        @include MQ(mM) {
          height: 55px;
        }
        color: getColor(txt, lightest);
        background-color: rgba(#000, .4);
        &:hover {
          background-color: rgba($colorInfo, .4);
        }
        section {
          display: table;
          height: 100%;
          vertical-align: middle;
          aside {
            height: 100%;
            display: table-cell;
            vertical-align: middle;
            word-wrap: break-word;
            font: {
              size: 20px;
              weight: 500;
            }
            @include MQ(mL) {
              font-size: 15px;
            }
            line-height: 20px;
            .check {
              font: {
                size: 24px;
                weight: 600;
              }
              position: relative;
              border-radius: 100%;
              text-align: center;
              line-height: 34px;
              overflow: hidden;
              display: block;
              width: 36px;
              height: 36px;
              @include MQ(mL) {
                width: 26px;
                height: 26px;
                font-size: 18px;
                line-height: 25px;
              }
              background: $colorInfo;
              color: getColor(txt, lightest);
              .ok {
                position: absolute;
                width: 36px;
                height: 36px;
                @include MQ(mL) {
                  width: 26px;
                  height: 26px;
                }
                background: $colorSuccess;
                top: 0;
                left: 0;
                display: none;
              }
            }
            .icon {
              font-size: 42px;
              margin: 0 15px;
              @include MQ(mL) {
                font-size: 26px;
                margin: 0 10px;
              }
            }
          }
        }
        &.active {
          background-color: rgba(darken($colorSuccess, 5%), .3);
          aside {
            .check {
              .ok {
                display: block;
              }
            }
          }
        }
      }
    }
  }
  section {
    .plus-accordion {
      padding-bottom: 0;
    }
    .panel-group {
      margin-bottom: 0;
      @include MQ(mL) {
        margin: 0;
      }
    }
    .panel-body {
      /*background: getColor(bg, light);*/
      .tab-pane {
       padding: 20px 0 0;
     }
   }
   .controls {
    padding-top: 15px;
    @include MQ(mL) {
      button {
        display: block;
        width: 100%;
        margin: 20px 0 10px 0;
        padding: 14px 0;
      }
    }
  }
  &#extras {
    form.has-top-mg {
      margin-top: 50px;
    }
  }
  &#crew {
    .container {
      border-top: 1px solid getColor(neutral, base);
    }
    .flatForm {
      legend {
        padding-left: 15px;
      }
    }
    #btm-btn {
      @include MQ(mL) {
        margin-top: 5px !important;
      }
    }
  }
  &#insurance_1 {
    padding: 10px 0 20px;
    .insurance-box {
      margin: 20px 20px;
    }
  }
  &#actions {
    .container {
      padding: 0;
    }
    #manager-contact {
      min-height: 310px;
      background: getColor(neutral, light);
      padding-top: 20px;

      .manager-name {
        min-height: 60px;
        padding-left: 70px;
        .h3 {
          font-size: 24px;
        }
      }
      .flatForm {
        padding-top: 15px;
      }
      .manager-lang {
        color: $h2-color;
        padding: 15px 0 0 0;
        font-size: 15px;
        .title {
          padding-right: 10px;
          color: getColor(neutral, medium);
        }
      }
    }
    #contacts {
      color: white;
    }
  }
  &#faq {
    #accordion > .panel:last-child {
      .panel-heading {
        border-bottom: 5px;
        a[aria-expanded='true'] {
          border-bottom: 1px solid getColor(neutral, base);
        }
      }
      .panel-body {
        border-bottom: 0;
      }
    }
  }
  &#footer-info {
    & > .container {
      overflow: hidden;
      @include MQ(mL) {
        padding-top: 10px;
      }
    }
    .bordered {
      border-left: 1px solid getColor(neutral, base);
      @include MQ(mL) {
        border-left: 0;
      }
    }
  }
}
} // body

.attention-block {
  font-size: 18px;
  color: getColor(info, darker);
  padding: 10px 5px;
  .footer {
    float: right;
  }
}
section {
  &.order-details {
    @include MQ(L) {
      .payments-table {
        display: table;
        width: 100%;
        & > div {
          display: table-cell;
          vertical-align: middle;
          float: none;
          &:last-child {
            text-align: right;
          }
        }
        .last-btn {
          vertical-align: bottom;
          .btn {
            margin-bottom: 54px;
          }
        }
        form {
          .btn {
            margin-bottom: 0!important;
          }
        }
      }
    }
    .details-inside {
      color: getColor(txt, base);
      .h3, .h1 {
        margin: 0;
        padding: 25px 0 5px 0;
      }
      .explain {
        clear: both;
        padding-bottom: 15px;
        font-size: 15px;
        color: getColor(neutral, lighter);
        .date {
          color: getColor(info, darker);
        }
      }
      .price-holder {
        .price {
          font: {
            size: 27px;
            weight: 400;
          }
          color: getColor(info, darker);
          padding: 25px 0 0;
          @include MQ(mL) {
            padding: 23px 0 0;
          }
        }
      }
      .right-side {
        text-align: center;
        @include MQ(L) {
          text-align: right;
        }
        .form-group {
          margin-bottom: 0;
          .help-block {
            margin-bottom: 0;
          }
        }
      }
      [aria-controls=insurance_1] {
        &[aria-expanded=false] {
          .turn-on {
            display: block;
          }
          .turn-off {
            display: none;
          }
        }
        &[aria-expanded=true] {
          .turn-on {
            display: none;
          }
          .turn-off {
            display: block;
          }
        }
      }
    }
    #payment-methods {
      .panel-body {
        padding: 0;
        .payment-tab {
          padding: 0;
          .nav-tabs {
            border: 0;
          }
          .tab-content {
            padding: 0 15px;
            margin: 0;
          }
        }
      }
    }
  }
}
