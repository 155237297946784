// Embeds responsive
.embed-responsive {
  // Modifier class for 3:2 aspect ratio
  &.embed-responsive-3by2 {
    padding-bottom: 66.666%;
  }
  &.embed-responsive-16by10 {
    padding-bottom: 62.5%;
  }

}
