.jumbotron-compare {
  height: 430px;
  // margin-top: -60px;
  color: getColor(txt, lightest);
  padding-top: 0;
  position: relative;
  background: {
    image: url('#{$assets_path}/img/sailogy-compare-bg.jpg');
    size: cover;
    position: top right;
    attachment: fixed;
  }
  .jumbo-header {
    margin: 60px 0 0 0;
    font-size: 17px;
    h1 {
      margin-top: 40px;
      font-weight: 300;
      font-size: 58px;
    }
    .help {
      font-size: 17px;
      margin-top: 40px;
    }
    .sharing {
      font-size: 26px;
      font-weight: 400;
      [class^="icon-sailogy-"], [class*=" icon-sailogy-"] {
        margin-left: 7px;
        &:hover {
          color: getColor(info, base);
        }
      }
    }
    a {
      color: getColor(txt, lightest);
    }
    #compare-results {
      color: getColor(txt, darkBgInfo); 
      background: rgba(0,0,0,0.3);
      margin: 0;
      a {
        color: getColor(neutral, txt);
        display: block;
        padding: 15px 0;
      }
    }
    #search-term {
      padding-top: 15px;
      padding-bottom: 15px;
      b {
        color: getColor(txt, lightest);
        font-weight: normal;
      }
    }
    #back-link {
      font-size: 25px;
      margin-right: 7px;
    }
  } 
}

[draggable] { 
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  -khtml-user-drag: element;
  -webkit-user-drag: element;
}

@media only screen and (max-width: 992px) {
  body {
    .wrapper {
      #compare-list {
        margin-top: 0;
        overflow-x: auto;
        overflow-y: hidden;
        &.container {
          width: auto;
        }
        .item {
          white-space: nowrap;
          position: relative;
          height: 150px;
          width: auto;
          margin-bottom: 0;
          border-bottom: 1px solid getColor(bg, bgLightInactive);
          padding-left: 510px; 
          cursor: default;
          
          .compare-nav-fixed-left {
            position: fixed;
            z-index: 10;
            left: 34px;
            border-bottom: 1px solid getColor(bg, bgLightInactive);
          }

          .compare-boatname-fixed-left {
            @include rotate(-90deg);
            transform-origin: left top 0;
            -webkit-transform-origin: left top 0;
            -ms-transform-origin: left top 0;
            -o-transform-origin: left top 0;
            padding: 8px 0 !important;
            background: getColor(bg, light);
            position: fixed;
            z-index: 10;
            margin: 0;
            left: 0;
            white-space: nowrap;
            overflow: hidden;
            max-width: 150px;
            text-overflow: ellipsis;
            font-size: 13px;
            border-left: 1px solid getColor(bg, bgLightInactive);
          }

          &:nth-child(odd) {
            background: getColor(bg, lightEven);
          }
          .specs {
            white-space: nowrap;
            header {
              padding: 0;
              color: getColor(txt, lightest);
              width: 30px;
              height: 151px;
              text-align: center;
              display: inline-block;
              span {
                @include rotate(-90deg);
                display: block;
                transform-origin: left top 0;
                font-size: 17px;
                width: 150px;
                position: absolute;
                top: 150px;
                margin-left: 3px;
              }
            }
            ul {
              display: inline-block;
              vertical-align: top;
              width: 300px;
              &:last-child {
                width: 450px;
              }
              li {
                float: left;
                width: 50%;
                font-size: 14px;
                padding: 9px 0 9px 12px;
                background: transparent !important;
                &:nth-child(odd) {
                  background: getColor(bg, lightEven);
                }
                .hover {
                  background: transparent !important;
                }
              }
            }
            [data-sailogy='specs'] {
              .icon {
                display: none;
              }
            }
          }
          & > header { 
            height: 150px;
            position: absolute;
            left: 230px; 
            top: 0;
            & > div { // boat-name and boat-place
              margin-left: 45px;
              margin-right: 0;
              font-size: 16px;
            }
            nav {
              float: left;
              a {
                height: 75px;
                width: 36px;
                &:nth-child(2) {
                  height: 74px;
                }
                i {
                  font-size: 17px;
                  padding-top: 28px;
                }
              }
            }
          }
          .footer {
            position: absolute;
            left: 275px;
            top: 75px;
            padding: 11px 0 0 0;
            text-align: left;
            .date {
              font-size: 15px;
            }
            .price {
              font-size: 17px;
            }
            .circle {
              width: 50px;
              height: 50px;
              span {
                margin-top: 13px;
                font-size: 15px;
              }
            }
          }
          section { 
            position: absolute;
            left: 0;
            top: 0;
            width: 230px; 
            height: 150px;
            overflow: hidden;
          }
        }
      }
    }
  }
}

#compare-list {
  margin-top: -80px;
  .item {
    background: getColor(bg, light);
    margin-bottom: 10px;
    padding-right: 0;
    padding-left: 0;
    cursor: move;
    &:nth-child(1) .specs header {
      color: getColor(txt, lightest); 
    }
    & > header {
      height: 80px;
      overflow: hidden;
      color: getColor(txt, base);
      white-space: nowrap;
      
      div {
        white-space: nowrap;
        overflow: hidden;
        max-width: 80%;
        text-overflow: ellipsis;
      }
      
      .boat-name {
        font-size: 20px;
        margin: 11px 40px 0 12px;
      }
      .boat-place {
        font-size: 17px;
        margin: 6px 40px 0 12px;
      }
      nav {
        float: right;
        a { 
          background: getColor(info, base);
          text-align: center;
          i {
            font-size: 17px;
            display: block;
            padding: 9px 0 0 0;
          }
          &:hover {
            background: getColor(bg, light);  
            color: getColor(info, base);
          }
          &:first-child {
            border-bottom: 1px solid getColor(border, forDark);
            i {
              font-size: 15px;
              padding-top: 12px;
            }
          }
          display: block;
          height: 40px;
          width: 40px;
          color: getColor(txt, lightest);
        }
      }
    }
    section {
      img {
        width: 100%;
        max-width: 100%; 
        &:hover {
          opacity: 0.8;
        }
      }
    }
    .footer {
      font-weight: 300;
      padding: 10px 12px 10px 20px;
      white-space: nowrap;
      .date {
        color: getColor(txt, light);
        font-size: 15px;
        @include MQ(XL) {
          font-size: 18px;
        }
      }
      .price {
        font-size: 26px;
        color: getColor(neutral, color);
      }
      .circle {
        width: 60px;
        height: 60px;
        font-size: 18px;
        font-weight: 600;
        text-align: center;
        border-radius: 100%;
        border: 1px solid getColor(attention, base);
        color: getColor(attention, base);
        span {
          display: block;
          margin-top: 16px;
        }
      }
    }
    .specs {
      font-size: 18px;
      font-weight: 400;
      color: getColor(attention, base);
      header {
        font-size: 20px;
        padding: 7px 0 7px 12px;
        background: getColor(attention, base);
      }
      ul {
        color: getColor(txt, medium);
        margin-bottom: 0;
        li {
          padding: 6px 0 6px 12px;
          &.hover {
            background: getColor(neutral, bg);
          }
          &.inactive {
            color: getColor(bg, bgLightInactive);
          }
          .icon {
            padding-top: 2px;
            margin-left: -10px;
            color: getColor(neutral, lighter);
            width: 35px;
            text-align: center;
          }
          &:nth-child(odd) {
            background: getColor(neutral, bgLight);
          }
        }
      }
    }
  }
}