.payment-tab {
  padding: 0;
  #paypal {
    #paypal-form {
      #braintree-paypal-loggedout, #braintree-paypal-loggedin {
        display: none !important;
      }
    }
  }
  .nav-tabs {
    float: left;
    width: 100%;
    border: none;
    li {
      background: getColor(neutral, light);
      color: getColor(info, base);
      display: table-cell;
      width: 1%;
      border-right: 1px solid getColor(neutral, base);
      &:last-child {
        border-right: 0;
      }
      &:hover, &:focus {
        background-color: getColor(info, dark);
        color: getColor(txt, lightest);
        a {
          color: getColor(txt, lightest);
        }
      }
      &.active {
        background-color: getColor(info, base);
        color: getColor(txt, lightest);
        border-right: 1px solid getColor(neutral, base);
        &:hover, a:hover {
          background-color: getColor(info, base);
          color: getColor(txt, lightest);
        }
        a {
          background: getColor(info, base);
          color: getColor(txt, lightest);
          border: 0;
          &:active, &:focus, &:hover {
            border: 0;
          }
        }
      }
      a {
        border: 0;
        text-align: center;
        color: getColor(info, base);
        background-color: transparent;
        border-radius: 0;
        vertical-align: middle;
        line-height: 75px;
        margin: 0;
        font-size: 16px;
        line-height: 24px;
        width: 100%;
        padding: 20px !important;
        height: inherit;
        [class^="icon-sailogy"] {
          display: block;
          margin-bottom: 15px;
          font-size: 36px;
        }
        @include MQ(L) {
          [class^="icon-sailogy"] {
            display: inline-block;
            margin-bottom: 0;
            margin-right: 10px;
            vertical-align: middle;
          }
        }
        &:hover, &:focus {
          background-color: transparent;
          color: getColor(txt, lightest);
        }
        i {
          background-image: url(#{$assets_path}/img/payment.png);
          background-repeat: no-repeat;
          display: inline-block;
          width: 50px;
          height: 35px;
          vertical-align: middle;
          margin-right: 20px;
          &#icon-sailogy-cards {
            background-position: 0 0;
            width: 103px;
          }
          &#icon-sailogy-paypal {
            background-position: -110px 0;
          }
          &#icon-sailogy-sms {
            background-position: -166px 0;
          }
        }
      }
      &:last-child {
        a {
          border-right: 0;
        }
      }
    }
  }
  .tab-content {
    @include clearfix();
    clear: both;
    padding: 30px 0;
  }
  .tab-content {
    color: getColor(txt, darkest);
    width: 100%;
    float: left;
    h3, .h3 {
      color: getColor(info, dark);
      font-size: 22px;
      padding-left: 0;
      font-weight: 400;
    }
    small, .small {
      font-size: 12px;
      color: getColor(neutral, search);
    }
    .instructions {
      background: getColor(neutral, light);
      padding: 20px;
      margin: 20px 0;
      text-align: center;
      color: getColor(neutral, medium);
      p {
        font-size: 18px;
      }
      small, .small {
        font-size: 12px;
        color: getColor(neutral, medium);
      }
    }
    .help-block {
      margin-bottom: 25px;
    }
    .form-group {
      @include clearfix();
      margin-bottom: 15px;
      .tot-q {
        display: block;
        font: {
          size: 24px;
          style: normal;
          weight: 400;
        }
        text-align: right;
        @include MQ(S) {
          display: inline-block;
        }
        @include MQ(M) {
          display: inline-block;
          width: 100%;
        }
        @include MQ(L) {
          text-align: left;
        }
      }
      .cd-img-cards {
        margin-top: 0;
        /*display: none;*/
        float: left;
        top: 3px;
        img {
          height: 34px;
        }
        @include MQ(S) {
          display: inline-block;
          position: absolute;
          top: 4.5px;
          right: 20px;
        }
        @include MQ(M) {
          top: 3.5px;
          img {
            height: 29px;
          }
        }
      }
      label, .label {
        font-weight: 400;
        color: getColor(neutral, medium);
        text-align: left;
      }
      .cd-ccv {
        max-width: 165px;
        .cd-img-cards {
          display: inline-block;
          position: absolute;
          right: 0;
        }
        .ccv-code {
          max-width: 80px;
        }
      }
    }
    .bill-fieldset {
      h3 {
        margin-bottom: 25px;
      }
      legend {
        border-bottom: 0;
      }
      .form-group:first-child {
        label {
          @include MQ(S) {
            margin-right: 2px;
          }
          @include MQ(M) {
            margin-right: 0;
          }
        }
      }
    }
    .inner-section {
      margin-bottom: 25px;
    }
    .bottom-form {
      border-top: 1px solid getColor(neutral, base);
      padding: 25px 0;
      text-align: center;
    }
  }
}
