#best-offers {
  background-color: getColor(neutral, bg);
}
.slide-in-icons {
  color: white;
  margin-top: 10px;
  margin-bottom: 20px;
  > div {
    margin-top: 15px;
  }
  .deco {
    display: inline-block;
    vertical-align: top;
    text-align: left;
    width: 45px;
    span {
      @include animations(0.1s, 1s, roll, forwards);
      display: inline-block;
      font-size: 40px;
      opacity: 0;
      @include MQ(M) {
        font-size: 45px;
      }
    }
  }
  h3, .h3 {
    opacity: 0;
    font-size: 16px;
    margin: 0;
    padding: 0;
    display: inline-block;
    text-align: left;
    color: white;
    vertical-align: middle;
    height: 75px;
    min-height: 75px;
    width: calc(100% - 50px);
    @include animations(0.8s, 1s, fadeIn, forwards);
    @include MQ(M) {
      font-size: 15px;
      height: auto;
      min-height: 0;
    }
    @include MQ(S) {
      vertical-align: top;
      font-size: 18px;
      padding: 5px 0 0 10px;
    }
  }
  &.one {
    .deco {
      span {
        @include animations(0.1s, 1s, bounceInLeft, forwards);
      }
    }
    h3, .h3 {
      @include animations(0.5s, .5s, fadeInRight, forwards);
    }
  }
}

#best-collections {
  .img-container {
    width: 100%;
    height: 250px;
    overflow: hidden;
    img {
      opacity: 1;
      transition: opacity .2s ease-in-out;
    }
    a {
      width: 100%;
      height: 100%;
      display: inline-block;
      @include clearfix();
      &:hover, &:focus {
        img {
          opacity: .7;
        }
      }
    }
    @include MQ(M) {
      height: 320px;
    }
  }
  &.model-boat {
    .header-bck {
      background-image: url('#{$assets_path}/img/boat-with-skipper.jpg');
    }
    .bottom {
      padding-top: 15px;
    }
  }
}
.cd-partners {
  h2 {
    margin-bottom: 50px;
  }
  .logo-container {
    float: left;
    width: 33%;
    padding: 5px;
    span {
      display: block;
      padding: 0 30px;
      width: 100%;
      img {
        width: 100%;
      }
    }
  }
  @include MQ(L) {
    .logo-container {
      display: table-cell;
      float: none;
      width: 1%;
    }
  }
}
.sailogy-info {
  position: relative;
  background: {
    size: cover;
    position: center;
  }
  background-image: url('#{$assets_path}/img/bg/about/sea-xl.jpg');
  @include MQ(mL) {
    background-image: url('#{$assets_path}/img/bg/about/sea-l.jpg');
  }
  @include MQ(mM) {
    background-image: url('#{$assets_path}/img/bg/about/sea-m.jpg');
  }
  @include MQ(mS) {
    background-image: url('#{$assets_path}/img/bg/about/sea-s.jpg');
  }
  h1, h2, h3, .h1, .h2, .h3, p {
    color: getColor(txt, lightest);
  }
  article {
    margin: 15px 0 0;
    padding-top: 95px;
  }
  .logo {
    background: getColor(danger, base);
    color: getColor(txt, lightest);
    font-size: 43px;
    padding: 10px 35px;
    position: absolute;
    top: 22px;
    left: -20px;
  }
}
.features-list {
  color: white;
  margin: 0;
  padding: 0 0 20px;
  li {
    text-align: left;
    margin: 0;
    padding: 5px 0 5px;
    font-size: 16px;
    span {
      &:first-child {
        padding-right: 10px;
      }
    }
  }
}
.destination-info, .model-info {
  background: {
    image: url('#{$assets_path}/img/boat-holidays.jpg');
    size: cover;
    position: center;
  }
  position: relative;
  color: white;
  padding-top: 100px;
  padding-bottom: 100px;
  h2 {
    padding-bottom: 30px;
    font-weight: 400 !important;
  }
  h1, h2, h3, p {
    color: getColor(txt, lightest);
  }

}
.sailogy-info, .destination-info, .model-info, .charter-info {
  .darken-layer {
    z-index: 0;
  }
}
.model-info {
  background-image: url('#{$assets_path}/img/boat-model.jpg');
}
.charter-info {
  background: {
    image: url('#{$assets_path}/img/profile-charter-boat.jpg');
    repeat: no-repeat;
    size: cover;
    attachment: fixed;
    position: center;
  }
  min-height: 350px;
  position: relative;
  .center-focus {
    max-width: 500px;
    h3 {
      text-align: center;
      margin-top: 0;
      font-size: 23px;
      color: getColor(txt, lightest);
    }
    [class^="icon-sailogy"] {
      font-size: 75px;
      text-align: center;
      display: block;
      margin-bottom: 25px;
    }
  }
  h3 {
    color: white;
  }
  @include MQ(M) {
    min-height: 500px;
    .center-focus {
      h3 {
        text-align: left;
      }

      [class^="icon-sailogy"] {
        float: left;
        margin-right: 50px;
        margin-bottom: 0;
        vertical-align: middle;
        display: inline-block;
      }
    }
  }
}
#cd-quote {
  .cd-btn {
    text-align: center;
    margin-top: 15px;
    button {
      padding: 15px 25px;
    }
  }
  @include MQ(M) {
    .cd-btn {
      text-align: left;
      width: 100%;
    }
  }
}
.landing-footer {
  .boats-list {
    text-align: left;
    font-size: 16px;
    a {
      font-size: 16px;
      color: getColor(txt, base);

      &:hover, &:focus {
        color: getColor(info, base);
      }
    }
  }
  .bottom-footer {
    text-align: left;
    .row {
      > div {
        white-space: nowrap;
        line-height: 32px;
      }
    }
    .container {
      position: relative;
    }
    .circle {
      height: 50px;
      width: 50px;
      position: relative;
      background-color: getColor(danger, base);
      display: inline-block;
      vertical-align: middle;
      border-radius: 50%;
      border: 5px solid white;
      margin-right: 5px;
      @include MQ(mS) {
        width: 30px;
        height: 30px;
        border-width: 2px;
      }
      [class^="icon-"] {
        position: absolute;
        @include center();
        font-size: 18px;
      }
    }
    .cd-social-icons {
      padding-top: 5px;
      font-size: 30px;
      @include MQ(mS) {
        padding-top: 0;
      }
      a {
        margin-right: 15px;
        font-size: 30px;
        @include MQ(mS) {
          font-size: 25px;
        }
        color: white;
        &:hover, &:focus {
          opacity: .7;
        }
      }
    }
  }
}
#quote-modal {
  z-index: 1139;
}
