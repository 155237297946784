
.homepage {
  #jumbo-right-special-offer {
    position: absolute;
    bottom: 0;
    right: 15px;
    width: 300px;
    @include MQ(mL) {
      position: relative;
      //top: -15px;
      //padding-bottom: 5px;
      //margin: 0px 15px 0px 15px;
      width: 100%;
      right: 0;
    }
    @include MQ(mM) {
      margin: 0px 0px 0px 0px;
      top: 0;
    }

    article {
      background: getColor(attention, base);
      &:hover, &:focus {
        background-color: getColor(danger, dark);
        color: getColor(danger, txt);
      }

      margin-top: 0; /*10px;*/
      margin-bottom: 0; /*5px;*/

      padding-top: 10px;
      padding-right: 10px;
      padding-bottom: 10px;
      padding-left: 10px;

      * {
        color: getColor(txt, lightest);
      }

      i {
        font-size: 2em;
      }
      .h3 {
        margin: 0;
        padding: 0;
        text-align: center;
        font: {
          weight: 200;
          size: 25px;
        }
      }
      .h4 {
        text-align: center;
        font: {
          weight: 600;
          size: 25px;
        }
        @include MQ(mL) {
          margin-top: 0;
          font-size: 23px;
        }
      }
      .explain {
        font-size: 16px;
        line-height: 18px;
        text-align: center;
      }
    }
  }
}

.special-offers {

  .jumbotron {
    form.flatForm {
      color: getColor(neutral, color);
      margin: 0 15px;

      @include MQ(M) {
        width: 40%;
        margin: 0 auto;
      }
      @include MQ(L) {
        width: 30%;
      }
      @include MQ(XL) {
        width: 20%;
      }
    }
  }

  .jumbotron-special-offers:not(.imgix-fluid) {
      background-image: url('#{$assets_path}/img/sailogy-special-offers.jpg');
  }

  /*background: getColor(neutral, bg);*/
  section {
    &.dark {
      background: darken(white, 15%);
    }
  }

  .boat-related {
    &:hover footer {
      @include MQ(L) {
        top: inherit;
      }
    }

    header span.btn {
      position: absolute;
      bottom: 0;
      margin: 15px 25px 15px 40px;
    }

  }

}
