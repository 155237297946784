#conversations {
  #search-form {
    padding: 20px 0 0 0;
    @include MQ(mM) {
      padding: 0 0 15px;
      .form-group {
        margin-bottom: 0;
      }
    }

    [type=text] {
      padding: 5px 12px;
    }

    button {
      @include MQ(mM) {
        width: 100%;
      }
    }
  }

  // messages list archive
  #conversations-archive {

    #toggle-conversations {
      margin: 0;
    }

    article {
      margin-top: 0;

      // block above messages
      header {
        .arrow-accordion {
          .panel-body {
            .header {
              font-size: 16px;
              font-weight: 600;
              padding: 5px 0 0 0;
            }
            .footer {
              font-size: 14px;
              font-weight: 300;
              padding: 0 0 5px 0;
            }
          }
          .panel-title {
            a {
              padding: 15px;
              font-weight: 400;
            }
            background: getColor(bg, lightEven);
            font-size: 17px;
          }
          .panel-footer {
            padding: 5px 15px;
            font-size: 14px;
          }
        }
        .h3 {
          margin: 5px 0 20px 10px;
          @include MQ(mM) {
            margin: 15px 0 10px;
            text-align: center;
          }
        }
        .reserve {
          padding: 0 15px 15px 0;
          @include MQ(mM) {
            button {
              width: 75%;
              margin: 0 auto;
              display: block;
            }
          }
        }
      }

      .reply-block {
        padding: 15px 0 0;
      }

      .reply-form {
        display: none;
        margin-top: 10px;
        background: getColor(bg, lightEven);
        padding: 10px 15px 15px;
        #vacation-answer {
          margin-bottom: 10px;
        }
      }

      // controls for messages list

      #conversations-controls {
        @include MQ(M) {
          display: none;
        }
        z-index: 15;
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        a {
          margin: 0;
        }
      }

      // messages list

      #messages {

        padding-top: 15px;
        .item {
          &:first-child {
            border-top: 1px solid getColor(border, input);
          }

          padding: 15px 0;
          border-bottom: 1px solid getColor(border, input);

          header {
            text-align: center;
            img {
              border-radius: 100%;
              display: block;
              margin: 0 auto;
            }
          }

          .footer {
            clear: both;
            padding-top: 15px;
            font-size: 14px;
            font: {
              weight: 200;
            }
          }

          &.me {
            section {
              padding: 0 10px;
              // @include MQ(mM) {
              //  padding: 0 10px;
              // }
            }
            background: getColor(bg, lightEven);
            .author {
              float: left;
              padding: 0 10px;
            }
            .time {
              float: right;
              padding: 0 10px;
            }
          }

          &.forme {
            background: lighten(getColor(info, base), 47%);
            section {
              padding: 0 10px;
            }
            .author {
              float: right;
              padding-right: 10px;
            }
            .time {
              float: left;
              padding: 0 10px;
            }
          }

        }
      }
    }
    aside {
      padding-right: 0;
      @include MQ(mM) {
        padding: 0 15px;
      }

      .conversation-item {
        border-radius: 0;
        padding: 7px;

        .footer {
          font-size: 12px;
          line-height: 105%;
          padding-top: 7px;
        }

        .badge {
          top: 10px;
          z-index: 2;
          position: absolute;
          background: getColor(danger, base);
          color: getColor(txt, lightest);
          @include MQ(L) {
            left: 10px;
          }
          @include MQ(mL) {
            right: 10px;
          }
        }

        aside {
          padding: 0 5px 0 10px;
          @include MQ(mL) {
            padding: 0 15px;
          }
          header {
            line-height: 115%;
            padding: 0 7px 5px 0;
            font: {
              weight: 400;
              size: 15px;
            }
          }
          time {
            font-size: 12px;
            display: block;
            color: darken(getColor(txt, lightest), 25%);
          }
        }

        section {
          padding: 0 0 0 15px;
          z-index: 1;
          img {
            max-width: 100%;
          }
        }

        &.active {
          background: getColor(info, base);
          color: getColor(txt, lightest);
          &:hover {
            background: lighten(getColor(info, base), 7%);
          }
          .badge {
            background: white;
            color: getColor(danger, base);
          }
          aside {
            time {
              color: lighten(getColor(info, base), 25%);
            }
          }
        }

      }
    }
  }
}
