// Photo styles

.photo-bg { // as an image
  position: relative;
  overflow: hidden;
  display: inline-block;

  &.bg-dark,
  &:not(.bg-colored) {
    background: getColor(txt, darkest);
  }

  &.bg-colored {
    background: $colorSuccess;
  }

  .cover {
    color: getColor(txt, lightest);
    position: absolute;
    font-size: 26px;
    line-height: 95%;

    &.title {
      font-size: 52px;
      font-weight: 100;
    }
    &.subtitle {
      font-size: 30px;
    }
    &.small {
      font-size: 20px;
    }

    &.cover-main {
      left: 20px;
      top: 20px;
    }
    &.cover-secondary {
      left: 20px;
      bottom: 20px;
    }
  }

  img {
    max-width: 100%;
    position: relative;

    &.blurred {
      @include filter(blur(6px));
    }

    &.transp {
      opacity: 0.7;
    }

  }
}

.photo-bg-back { // as a background
  overflow: hidden;
  &:before {
    content: '';
    position: absolute;
    width : 100%;
    height: 100%;
    background: inherit;
    z-index: 0;

    left: 0;
    top: 0;
  }

  &.transp {
    &:before {
      opacity: 0.7;
    }
  }
  &.blurred {
    &:before {
      @include filter(blur(6px));
    }
  }
}

