.modal {
  z-index: 1140;
  form {
    h3, .h3 {
      font-size: 21px;
      font-weight: 400;
      color: getColor(info, dark);
      margin: 5px 0 20px 0;
    }
  }
}
.modal-content {
  border-radius: 0;
}
.modal-body, .modal-footer, .modal-header {
  padding: 15px 20px;
}
#reserve-modal, .modal {
  p {
    font-size: 16px;
    margin: 10px 0;
  }
  .close {
    font: {
      weight: 100;
    }
  }
  .modal-header{
    border-bottom: 0;
    color: getColor(txt, lightest);
  }
  .modal-footer {
    // border-top: 0;
    .help {
      text-align: center;
      color: $table-text-color;
    }
  }
  .modal-title {
    font: {
      size: 22px;
      weight: 100;
    }
    color: getColor(info, base);
  }
  button[type="submit"] {
    width: 100%;
    white-space: normal;
  }
  .alert {
    padding: 10px;
    text-align: center;
    margin-bottom: 10px;
  }
}
#quote-modal {
  .modal-header {
    background-color: getColor(info, base);
    color: white;
    padding: 15px 20px 20px;
    .close {
      color: white;
      opacity: 1;
      &:hover, &:focus {
        opacity: .6;
      }
    }
    h4 {
      font-size: 28px;
      font-weight: 300;
      color: white;
      line-height: 26px;
    }
    p {
      font-size: 16px;
      margin: 0;
      color: white;
      padding-top: 10px;
    }
  }
  .cd-main-features {
    margin-bottom: 35px;
    padding: 0 15px 15px;
    font-size: 16px;
    color: getColor(txt, medium);
    border-bottom: 1px solid getColor(border, input);
    [class^="col-"] {
      padding: 0;
    }
    span {
      display: inline-block;
      vertical-align: middle;
      width: 70%;
      &[class^="icon-"] {
        font-size: 38px;
        padding: 0 5px;
        width: auto;
      }
    }
    .inner {
      padding: 10px 0;
    }
  }
  .sailogy-info {
    padding: 50px 15px;
    background: {
      size: cover;
      position: center;
    }
    background-image: url('#{$assets_path}/img/bg/about/sea-xl.jpg');
    @include MQ(mL) {
      background-image: url('#{$assets_path}/img/bg/about/sea-l.jpg');
    }
    @include MQ(mM) {
      background-image: url('#{$assets_path}/img/bg/about/sea-m.jpg');
    }
    @include MQ(mS) {
      background-image: url('#{$assets_path}/img/bg/about/sea-s.jpg');
    }
    h2 {
      font-weight: 300;
      font-size: 28px;
      text-align: center;
      margin-bottom: 50px;
      position: relative;
    }
    @include MQ(M) {
      .features-list {
        li {
          text-align: center;
          margin-left: 0;
        }
      }
    }
  }
  .bottom-btn {
    margin: 25px 0 10px;
    p {
      margin: 10px 0;
      color: getColor(neutral, lighter);
      font-size: 16px;
    }
  }
  .date-picker {
    margin-bottom: 10px;
    position: relative;
    [class^="icon-"] {
      position: absolute;
      left: 10px;
      @include center(y);
    }
    input {
      padding-left: 35px;
    }
  }
  .modal-footer {
    padding: 0;
  }
  button[type="submit"] {
    width: auto;
  }
  @include MQ(L) {
    .date-picker {
      max-width: 170px;
      display: inline-block;
      margin-left: 5px;
    }
  }
}
#tc p { 
  color: getColor(txt, base); 
}
.modal-dialog.modal-xl {
  margin: 0 auto;
  width: 90vw;
  @include MQ(S) { // 480?
    width: 460px;
  }
  @include MQ(M) { // 768
    width: 730px; // 750
  }
  @include MQ(L) { // 992
    width: 900px; //950
  }
  @include MQ(XL) { // 1200
    width: 1000px; //1120
  }
  .container {
    max-width: 100%;
  }
}
