.go-top {
  position: fixed;
  height: 57px;
  width: 57px;
  bottom: 70px;
  right: 5px;
  border: 1px solid getColor(info, base);
  z-index: 1100;
  background-color: getColor(bg, light);
  transition-duration: .2s;
  transform: rotate(180deg);
  i {
    @include center();
    color: getColor(info, base);
    font-size: 15px;
    position: absolute;
  }
  @media (max-width: $screen-md-max) {
    display: none;
  }
  &:hover {
    background-color: getColor(info, base);
    i {
      color: getColor(txt, lightest);
    }
  }
}
