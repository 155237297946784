.btn {
  @include transition(.2s);
  border-radius: 0;
  white-space: normal;
  @extend .btn-is-md;
  i {
    margin-right: 3px
  }
  outline: 0;
  &:focus, &:active, &:hover {
    outline: 0 !important;
  }
  &[disabled] {
    background-color: getColor(neutral, bg);
    color: getColor(neutral, color);
  }

  .icon {
    &.fa.fa-chevron-down {
      margin-left: 10px;
    }
  }

  &.btn-is-sm {
    padding: 7px 13px;
    font-size: 14px;
    @include MQ(M) {
      font-size: 14px;
      padding: 7px 13px;
    }
    @include MQ(L) {
      font-size: 14px;
      padding: 7px 13px;
    }
  }
  &.btn-is-md {
    padding: 10px 16px;
    @include MQ(M) {
      font-size: 15px;
      padding: 10px 16px;
    }
    @include MQ(L) {
      font-size: 16px;
      padding: 10px 16px;
    }
  }
  &.btn-is-lg {
    font-size: 14px;
    padding: 13px 15px;
    @include MQ(M) {
      font-size: 16px;
      padding: 12px 15px;
    }
    @include MQ(L) {
      font-size: 18px;
      padding: 15px 20px;
    }
  }
  &.btn-is-xl {
    font-size: 26px;
    font-weight: 200;
    padding: 13px 15px;
    @include MQ(M) {
      font-size: 18px;
      padding: 12px 15px;
    }
    @include MQ(L) {
      font-size: 22px;
      padding: 15px 20px;
    }
  }
  &.btn-is-link {
    background-color: transparent;
    color: getColor(txt, lightest);
    border-color: transparent;
    &:hover {
      opacity: .8;
    }
  }
  &.btn-is-danger {
    background-color: getColor(danger, base);
    border: 1px solid getColor(danger, base);
    color: getColor(danger, txt);
    &:hover, &:focus {
      background-color: getColor(danger, dark);
      border: 1px solid getColor(danger, dark);
      color: getColor(danger, txt);
    }
    &.btn-bg-fordark {
      color: getColor(danger, base);
      background-color: getColor(bg, light);
      &:hover {
        color: getColor(txt, lightest);
        background-color: getColor(danger, hover);
      }
    }
  }
  &.btn-is-success {
    border: 1px solid getColor(success, base);
    background-color: getColor(success, base);
    color: getColor(success, txt);
    &:hover, &:focus {
      color: getColor(success, txt);
      background-color: getColor(success, dark);
    }
  }
  &.btn-is-cover {
    border: 1px solid getColor(txt, lightest);
    background-color: transparent;
    color: getColor(txt, lightest);
    &.btn-is-danger {
      color: white;
      border: 1px solid getColor(danger, base);
      &:hover, &:active, &:focus {
        color: getColor(danger, base);
      }
    }
    &.btn-is-info, &.btn-is-default {
      color: white;
      border: 1px solid getColor(info, base);
      &:hover, &:active, &:focus {
        color: getColor(info, base);
      }
    }
    &:hover, &:active, &:focus {
      background: white;
      border-color: white;
      color: getColor(info, base);

      .icon {
        border-color: getColor(info, base);
        color: getColor(info, base);
      }
    }
  }
  &.facebook {
    background: getColor(etc, fbLoginDark);
    border-color: getColor(etc, fbLoginDark);
    text-decoration: none !important;
    color: getColor(txt, lightest);
    &:hover {
      background: darken(getColor(etc, fbLoginDark), 7%);
      border-color: darken(getColor(etc, fbLoginDark), 7%);
    }
  }
}
.close {
  font-size: 32px;
  text-shadow: 0 0 0;
}
.btn-is-info, .btn-primary {
  border: 1px solid getColor(info, base);
  background-color: getColor(info, base);
  color: getColor(info, txt);
  &:hover, &:focus, &.active {
    color: getColor(info, txt);
    background-color: getColor(info, dark);
    border: 1px solid getColor(info, dark);
  }
}
.btn-delete {
  background-color: getColor(danger, delete);
  color: getColor(txt, lightest);
  font-size: 18px;
  padding: 20px 17px;
  &:hover, &:focus {
    color: getColor(txt, lightest);
    background-color: getColor(danger, hover);
  }
  @include MQ(L) {
    font-size: 16px;
    padding: 15px;
  }
}
.btn-has-brd, .btn-has-brd:hover, .btn-has-brd:focus {
  border: 1px solid currentColor;
  color: inherit;
  padding: 25px 15px;
  font-size: 18px;
  @include MQ(L) {
    padding: 15px;
    font-size: 16px;
  }
}
.btn-is-info-brd {
  background-color: transparent;
  color: getColor(info, base);
  border: 1px solid currentColor;
  font-size: 18px;
  padding: 25px 15px;
  &:active {
    color: getColor(info, base);
  }
  &:hover, &:focus {
    border: 1px solid getColor(info, hover);
    background-color: getColor(info, hover);
    color: getColor(txt, lightest);
  }
  @include MQ(L) {
    font-size: 16px;
    padding: 15px;
  }
}
.btn-go-on {
  border-color: getColor(info, base);
  padding: 16px 20px 14px;
  color: getColor(txt, lightest);
  @include MQ(mS) {
    width: 80%;
    margin: 20px 10% 30px;
  }
  font: {
    weight: 400;
    size: 18px;
  }
  .icon {
    i {
      line-height: 26px;
      font-size: 19px;
      margin: 0 0 0 1px;
    }
    color: white;
    display: inline-block;
    vertical-align: middle;
    width: 30px;
    height: 30px;
    text-align: center;
    overflow: hidden;
    border-radius: 100%;
    margin: 0 5px 0 0;
    &.brd {
      border: 2px solid white;
    }
    &.lg {
      i {
        line-height: 26px;
        font-size: 27px;
        margin: 0 0 0 1px;
      }
    }
  }
  &.btn-is-danger {
    &:hover {
      .icon {
        &.brd {
          border-color: getColor(danger, base);
        }
        i {
          color: getColor(danger, base);
        }
      }
    }
  }
  &.btn-is-info {
    &:hover {
      .icon {
        &.brd {
          border-color: getColor(info, base);
        }
        i {
          color: getColor(info, base);
        }
      }
    }
  }
}
.btn-block {
  display: block;
  margin-bottom: 5px;
  width: 100%;
  float: left;
}
[aria-expanded='false'] {
  .on {
    display: none;
  }
  .off {
    display: block;
  }
}
[aria-expanded='true'] {
  .on {
    display: block;
  }
  .off {
    display: none;
  }
}
