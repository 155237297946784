ol, ul {
  list-style: none;
  padding-left: 0;
}

ul.cookie {
  list-style-type: circle;
  font-size: 16px;
  font-weight: 400;
  padding-left: 20px;
  list-style-position: inside;
}

.justified-list {
  text-align: justify;
  &:after {
    content: "";
    width: 100%;
    display: inline-block;
  }
}
.features-inline-list {
  display: inline-block;
  margin: 10px 0;
  li {
    color: getColor(info, base);
    font-size: 18px;
    [class^="icon-sailogy"] {
      margin-right: 5px;
      vertical-align: middle;
    }
    .icon-sailogy-certified {
      font-size: 24px;
    }
  }
}
.icon-list {
  padding-top: 15px;
  li {
    font-size: 17px;
    color: getColor(info, base);
    overflow: hidden;
    display: block;
    vertical-align: middle;
    margin-bottom: 12px;
    .icon {
      display: inline-block;
      width: 30px;
      text-align: center;
      vertical-align: middle;
      i {
        font-size: 24px;
      }
    }
    .txt {
      display: inline-block; 
      padding: 0 0 0 7px;
      vertical-align: middle;
      line-height: 24px;
      font-size: 16px;
    }
    &.off {
      color: getColor(neutral, base); 
    }
  }
}
.docs-list {
  li {
    a {
      &::before {
        content: '\e639';
        font-family: 'sailogy3';
        font-size: 18px;
        display: inline-block;
        margin-right: 10px;
        color: inherit;
        vertical-align: middle;
      }
    }
    &:hover {
      a {
        color: darken(getColor(info, base), 10%) !important;
      }
    }
  }
}
.destination-list {
  padding: 40px 0 0;
  > div {
    letter-spacing: -1px;
    font-size: 20px;
    padding-bottom: 10px;
    padding-right: 0;
    .counter {
      padding-left: 5px;
      color: getColor(neutral, search);
      &::first-letter, b {
        color: getColor(txt, base);
        font-weight: 400;
      }
    }
  }
  a {
    display: block;
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: getColor(txt, base);
  }
  li {
    padding-top: 5px;
    font-size: 18px;
    a {
      color: getColor(txt, light);
      &:hover, &:focus {
        color: getColor(info, base);
      }
    }
  }
  .qt, span, .counter {
    color: getColor(neutral, lighter);

    &:hover {
      color: getColor(info, base);
    }
  }
}

// services-included

.price-box {
  padding: 0 0 15px;
  margin: 0 0 15px;
  border-bottom: 1px solid getColor(border, base);
  h4, .h4 {
    padding-bottom: 0;
    font-size: 22px !important;
    font-weight: 400;
    margin-bottom: 0;
  }
}
.services-included {
  aside {
    color: getColor(txt, base);
    padding: 15px 0;
    margin: 0;
    font-size: 17px;
    width: 100%;
    background: getColor(neutral, bg);
    display: table;
    @include MQ(mM) {
      font-size: 15px;
    }
    @include MQ(mS) {
      padding-bottom: 15px;
      font-size: 14px;
    }
    &:nth-child(even) {
      background: lighten(getColor(neutral, bg), 5%);
    }
    > div {
      display: table-cell;
      float: none;
      vertical-align: middle;
      @include MQ(mL) {
        display: block;
        vertical-align: bottom;
        float: left;
        padding-top: 6px;
        padding-bottom: 6px;
      }
      &:first-child {
        vertical-align: top;
      }
      &:last-child {
        text-align: right;
        @include MQ(mS) {
          padding-left: 0;
          padding-right: 20px;
        }
      }
    }
    &.total {
      background: getColor(info, medium);
      color: getColor(info, dark);
      font-size: 20px;
      div:last-child {
        color: getColor(info, dark);
      }
    }
    &.paid {
      background: getColor(timeline, light);
      color: getColor(info, dark);
      font-size: 20px;
      padding-top: 15px;
      padding-bottom: 15px;
    }
    &.form-group {
      cursor: pointer;
      &:hover {
        opacity: .8;
      }
    }
    &.coupon {
      color: getColor(neutral, lighter);
      background: transparent;
      line-height: 34px;
      vertical-align:top;
      font-size: 14px;      
      > div { 
        &:first-child {
          font-size: 15px;
          white-space: nowrap;
        }
        &:first-child {
          padding-top: 17px;
          padding-bottom: 17px;
        }
        @include MQ(mS) {
          padding-top: 0 !important;
          padding-bottom: 0 !important;
        }
      }
      input {
        height: 34px;
        border-radius: 0;
        float: right;
        width: 60%;
        padding: 0px 10px;
        @include MQ(mM) {
          width: 70%;
        }
      }
      button {
        height: 34px;
        border: none;
        padding: 0 10px;
        float: right;
        @include MQ(mM) {
          width: 30%;
        }
        &:hover, &:focus {
          outline: 0;
          background-color: getColor(info, hover);
          color: white;
        }
      }
    }
  }
  .tags {
    text-align: right;
    margin-bottom: -10px;
    @include MQ(mL) {
      text-align: left;
    }
  }
  .tag {
    font: {
      size: 14px;
      weight: 400;
    }
    background: getColor(neutral, medium);
    padding: 3px 10px;
    margin-right: 10px;
    color: white;
    white-space: nowrap;
    float: left;
    color: getColor(txt, lightest);
    margin-bottom: 3px;
    &:first-letter {
      text-transform: uppercase;
    }
    &.checkin {
      background: darken(getColor(success, base), 27%);
    }
    &.person {
      background: getColor(danger, base);
    }
    &.online {
      background: getColor(info, base);
    }
  }
}
