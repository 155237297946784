.darken-layer {
  position: absolute;
  left: 0;
  width: 100%;
  top: 0;
  height: 100%;
  background-color: rgba(black, .6);
}
.jumbotron {
  height: 630px;
  margin: 0;
  padding-top: 140px;
  position: relative;
  background: {
    size: cover;
    position: center;
  }
  @include MQ(mM) {
    height: auto;
    padding: 50px 0 40px;
  }
  &.middle-height {
    height: 420px;
    padding: 0 !important;
    @include MQ(mM) {
      height: 520px;
    }
    @include MQ(mM) {
      height: auto;
    }
    #jumbo-right-side-container {
      min-height: 420px;
      @include MQ(mM) {
        min-height: 0;
        height: auto;
      }
    }
    .header-container {
      header {
        height: 380px;
        @include MQ(mM) {
          height: auto;
        }
      }
    }
  }
  .header-container {
    position: relative;
    display: table;
    width: 100%;
    header {
      text-align: center;
      display: table-cell;
      width: 100%;
      vertical-align: bottom;
      height: 400px;

      h1, h2, h3, .h1, .h2, .h3 {
        color: getColor(txt, lightest);
      }
      .h1, h1 {
        margin: 0;
        padding: 0 0 65px 0;
        line-height: 50px;
        font: {
          size: 52px;
          weight: 300;
        }
        @include MQ(mM) {
          padding: 80px 0 40px 0;
        }
        @include MQ(mS) {
          padding: 10px 0 30px 0;
        }
      }
      .h2, h2 {
        margin: 0;
        padding: 0 0 10px 0;
        font: {
          size: 22px;
          weight: 400;
        }
        @include MQ(mM) {
          padding: 0 0 20px 0;
        }
      }
      .h3, h3 {
        margin: 0;
        opacity: .7;
        font: {
          size: 18px;
          weight: 400;
        }
        @include MQ(mM) {
          padding: 0 0 25px 0;
        }
      }
    }
  }
  > .container {
    position: relative;
  }
  &.big-icon {
    position: relative;
    color: getColor(txt, lightest);
    padding: 20px 0;
    &.jumbotron-short {
      min-height: 400px;
      height: auto;
    }
    > .container {
      position: relative;
    }

    .icon-main {
      font-size: 86px;
      display: block;
      margin: 0 0 20px;
      @include MQ(mS) {
        font-size: 89px;
      }
    }
    h1, h2, h3, .h1, .h2, .h3 {
      color: getColor(txt, lightest);
    }
    h2, .h2 {
      font: {
        size: 52px;
        weight: 200;
      }
    }
    h2, .h2 {
      font: {
        size: 27px;
        weight: 200;
      }
    }
    h3, .h3 {
      font: {
        size: 22px;
        weight: 400;
      }
    }
  }
  #jumbo-right-side-container {
    min-height: 400px;
    right: 0;
    @include MQ(mXL) {
      min-height: 385px;
    }
    @include MQ(mL) {
      height: auto;
      min-height: 0;
    }
  }
  #jumbo-right-side {
    margin-top: 15px;
    bottom: 0;
    width: calc(100% - 30px);
    position: absolute;
    color: white;
    @include MQ(mL) {
      position: relative;
      width: auto;
      top: 0;
      padding-bottom: 15px;
    }
    @include MQ(mM) {
      max-width: 350px;
      margin: 0 auto;
    }
    header {
      background-color: rgba(#000, .4);
      .h3 {
        color: white;
        padding: 20px 0 0 20px;
        margin: 0;
        font: {
          weight: 200;
          size: 24px;
        }
      }
      .share-list {
        a {
          transition: .25s;
          color: white;
          font-size: 25px;
          &:hover {
            opacity: 0.7;
          }
        }
      }
    }
    article {
      background-color: rgba($colorInfo, .4);
      margin: 0;
      padding: 20px 20px;
      * {
        color: getColor(txt, lightest);
      }
      .h3 {
        margin: 0;
        padding: 0;
        font: {
          weight: 200;
          size: 25px;
        }
      }
      .h4 {
        font: {
          weight: 600;
          size: 25px;
        }
        @include MQ(mL) {
          margin-top: 0;
          font-size: 23px;
        }
      }
      .explain {
        font-size: 16px;
        line-height: 18px;
      }
    }
  }

}
.jumbotron-cover {
  min-height: 450px;
  color: getColor(txt, lightest);
  padding: 25px 0;
  position: relative;
  background: {
    color: white;
    size: cover;
    position: bottom center;
  }
  &#about {
    padding-top: 100px;
    background-image: url('#{$assets_path}/img/bg/about/xl.jpg');
    @include MQ(mL) {
      background-image: url('#{$assets_path}/img/bg/about/l.jpg');
    }
    @include MQ(mM) {
      background-image: url('#{$assets_path}/img/bg/about/m.jpg');
    }
    @include MQ(mS) {
      background-image: url('#{$assets_path}/img/bg/about/s.jpg');
    }
  }

  h1, h2, h3, .h1, .h2, .h3, p {
    color: getColor(txt, lightest);
  }

  h1, .h1 {
    font: {
      size: 52px;
      weight: 300;
    }
  }

  h2, .h2 {
    font: {
      size: 30px;
      weight: 400;
    }
  }

  h3, .h3 {
    font: {
      size: 26px;
      weight: 400;
    }
  }

  .btn-is-info, .btn-is-danger {
    background-color: transparent;

    i {
      vertical-align: middle;
      font-size: 24px;
      font-weight: 600;
      padding-right: 10px;
    }

    &:hover, &:focus {
      color: getColor(txt, lightest);
      i {
        color: getColor(txt, lightest);
      }
    }
  }

  .btn-is-info {
    border: 1px solid getColor(info, base);

    i {
      color: getColor(info, base);
    }

    &:hover, &:focus {
      background-color: getColor(info, base)
    }
  }

  .btn-is-danger {
    border: 1px solid getColor(danger, base);

    i {
      color: getColor(danger, base);
    }

    &:hover, &:focus {
      background-color: getColor(danger, base);
    }
  }

  @include MQ(M) {
    min-height: 500px;
    padding: 50px 0;
  }
}

.jumbotron-vacation,
.jumbotron-boat {
  height: 450px;
  color: getColor(txt, lightest);
  padding-top: 0;
  position: relative;
  background: {
    size: cover;
    position: 50% 50%;
    repeat: no-repeat;
  }

  &:not(.imgix-fluid) {
    background-image: url('#{$assets_path}/img/sailogy-boat-vacation.jpg');
  }

  h1, .h1 {
    font: {
      size: 46px;
      weight: 300;
    }
    padding: 0;
    margin: 10px 0;
  }
  h2, .h2 {
    font: {
      size: 26px;
      weight: 400;
    }
    padding: 0;
    margin: 10px 0;
  }
  h3, .h3 {
    font: {
      size: 22px;
      weight: 400;
    }
    color: white;
    padding: 0;
    margin: 5px 0;
    line-height: 1.2em;
  }
  .jumbo-header {
    margin: 110px auto 40px;
  }
  .btn-call {
    font: {
      size: 15px;
      weight: 400;
    }
    padding: 15px;
    margin-top: 15px;
    width: 100%;
    max-width: 380px;
    text-align: left;
    &:hover, &:focus, &:active {
      background-color: getColor(danger, dark);
      color: white;
    }
    .icon-sailogy-arrow {
      font-size: 45px;
      vertical-align: middle;
      padding-right: 10px;
    }
  }
  @include MQ(S) {
    .btn-call {
      font-size: 18px;
      padding-top: 10px;
    }
    .icon-sailogy-arrow {
      padding-right: 10px;
    }
  }
  @include MQ(M) {
    height: 630px;
    h1, .h1 {
      font-size: 52px;
    }
    h2, .h2 {
      font-size: 28px;
    }
    h3, .h3 {
      font-size: 26px;
    }
    .jumbo-header {
      margin: 160px auto 70px;
    }
    .btn-call {
      margin-top: 25px;
    }
  }
}
.jumbotron-boat {
  background: {
    repeat: no-repeat;
  }

  &:not(.imgix-fluid) {
    background-image: url('#{$assets_path}/img/boat-sailogy-front.jpg');
  }

  .jumbo-header {
    h1 {
      display: inline-block;
      vertical-align: middle;
      line-height: 100%;
    }
    .btn-is-danger {
      margin-bottom: 10px;
      @include MQ(XL) {
        margin-top: 15px;
        margin-left: 15px;
      }
    }
  }
}
.jumbotron-gift {
  @include clearfix();
  height: auto;
  background: none;
  padding: 0;
  @include MQ(XL) {
    height: 600px;
  }
  .cd-slick-carousel .slick-slide img {
    position: absolute;
    top: 1px;
  }
}
.jumbotron-bonus {
  height: auto;
  background-image: url('#{$assets_path}/img/bg/bonus/xl.jpg');
  @include MQ(mL) {
    background-image: url('#{$assets_path}/img/bg/bonus/l.jpg');
  }
  @include MQ(mM) {
    background-image: url('#{$assets_path}/img/bg/bonus/m.jpg');
    padding: 120px 0 40px;
  }
  @include MQ(mS) {
    background-image: url('#{$assets_path}/img/bg/bonus/s.jpg');
  }
  .btn-place {
    position: relative;
  }
}
.jumbotron-landing {
  @extend .jumbotron-boat;
  color: white;
  background: {
    color: black;
    size: cover;
    position: center;
  }
  // &:not(.imgix-jumbotron-bg) {
  &:not(.imgix-fluid) {
    background-image: url('#{$assets_path}/img/bg/landing/xl.jpg');
    @include MQ(mL) {
      background-image: url('#{$assets_path}/img/bg/landing/l.jpg');
    }
    @include MQ(mM) {
      background-image: url('#{$assets_path}/img/bg/landing/m.jpg');
    }
    @include MQ(mS) {
      background-image: url('#{$assets_path}/img/bg/landing/s.jpg');
    }
  }

  h3, h4 {
    color: white;
  }
  &.destinations:not(.imgix-fluid) {
    background-image: url('#{$assets_path}/img/italy.jpg');
  }
  &.charter {
    background-image: url('#{$assets_path}/img/sailogy-charter.jpg');
    .jumbo-header {
      padding: 100px 0 !important;
    }
  }
  .jumbo-header {
    padding: 0 10px;
    padding-top: 75px !important;
    h3, .h3 {
      font-size: 34px;
      font-weight: 300;
      strong {
        font-weight: 600;
      }
    }
  }
  @include MQ(L) {
    height: 530px !important;
    .jumbo-header {
      padding: 50px 0 0 !important;
    }
  }
}

.jumbotron-carousel {
  padding-top: 0;
  position: relative;
}

// titles container

.container.with-titles {
  position: relative;
  text-align: center;
  .title-container {
    position: relative;
    max-width: 800px;
    width: 100%;
    margin: 0 auto 0;
    padding: 20px 0 20px;
    display: table;
    height: auto;
    .titles {
      display: table-cell;
      vertical-align: middle;
      padding: 20px 0 20px;
      .h1, h1 {
        font-size: 53px;
        color: getColor(txt, lightest);
        padding-left: 10%;
        padding-right: 10%;
      }
      .h2, h2 {
        color: getColor(txt, lightest);
        font-size: 28px;
        font-weight: 200;
        margin: 20px 10px 0;
      }
      .h3, h3 {
        color: getColor(txt, lightest);
        font-size: 24px;
        font-weight: 200;
        margin: 20px 10px 0;
      }
    }
    .icon-main {
      font-size: 88px;
      display: block;
      color: getColor(txt, lightest);
      position: absolute;
      top: -44px;
      left: calc(50% - 44px);
      @include MQ(mS) {
        font-size: 50px;
        top: -25px;
        left: calc(50% - 25px);
      }
      &.with-border {
        border: 2px solid getColor(txt, lightest);
        border-radius: 50%;
        width: 88px;
        height: 88px;
        font-size: 46px;
        line-height: 88px;
      }
    }
    .l {
      position: absolute;
      border-color: getColor(info, base);
      border-style: solid;
      &.right, &.left {
        height: 40%;
        top: 0;
        width: calc(50% - 134px);
      }
      &.right {
        right: 10%;
        @include MQ(mS) {
          right: 10%;
        }
        border-width: 3px 3px 0 0;
      }
      &.left {
        left: 10%;
        @include MQ(mS) {
          left: 10%;
        }
        border-width: 3px 0 0 3px;
      }
      &.btm {
        height: 10%;
        bottom: 0;
        border-width: 0 3px 3px 3px;
        left: 10%;
        width: 80%;
        @include MQ(mS) {
          width: 80%;
          left: 10%;
        }
      }
    }
  }
  &.has-error {
    .l {
      border-color: getColor(danger, base);
    }
  }
}
