body.payment {
  background: getColor(neutral, bg);
  section {
    &.jumbotron {
      height: 580px;
      display: table;
      width: 100%;
      background: {
        image: url('#{$assets_path}/img/bg/payment.jpg');
        size: cover;
        position: bottom center;
        attachment: scroll;
      }
      @include MQ(mM) {
        height: auto;
      }
    }

    .payments-table {
      > div {
        text-align: left !important;
      }
      .details-inside {
        .h3 {
          margin: 0;
          padding: 25px 0 5px 0;
        }
        .explain {
          clear: both;
          padding-bottom: 15px;
          font-size: 15px;
          color: getColor(neutral, lighter);
          .date {
            color: getColor(info, darker);
          }
        }
      }
    }

    .payment-tab .tab-content{
      margin: 0;
    }

    &.results {
      > .container {
        background: white;
        position: relative;
        max-width: 775px;
        padding: 20px 0 20px;
        box-shadow: 0 12px 12px darken(white, 27%);
        footer {
          position: absolute;
          transform: scaleY(.8);
          white-space: nowrap;
          bottom: -22px;
          left: 0;
          width: 100%;
          height: 33px;
          overflow: hidden;
          span {
            width: 20px;
            height: 20px;
            display: inline-block;
            transform: rotate(45deg);
            background: white;
            margin: 1px;
          }
        }
      }
      .box-container {
        max-width: 570px;
        margin: 0 auto;
      }
      .price {
        font: {
          size: 27px;
          weight: 400;
        }
        color: getColor(info, darker);
      }
    }
  }
}
