.left-menu-container {
  .left-menu {
    margin-bottom: 10px;
    @include MQ(L) {
      &.affix-top {
        position: static;
      }
      &.affix {
        position: fixed;
        top: 0;
      }
      &.affix-bottom {
        position: absolute;
      }
    }
    @include MQ(mL) {
      width: auto !important;
      top: auto !important;
      margin: 0 -15px 10px !important;
    }
    @include MQ(mM) {
      margin: 0 0 10px;
    }
    .list-group-item {
      border-radius: 0;
      font-size: 19px;
      line-height: 105%;
      padding: 15px 0 15px 15px;
      i {
        float: left;
        font-size: 21px;
        padding-right: 6px;
      }
      @include MQ(mM) {
        padding: 10px 0 10px 10px;
        font-size: 17px;
      }
      &:first-child {
        border-top: 0;
      }
      &.active, &[aria-expanded="true"] {
        background: getColor(info, base);
        color: white;
        border-color: getColor(info, base);
        &:hover {
          background: getColor(info, light);
          border-color: getColor(info, light);
        }
      }
    }
  }
}

body.contactus a.contactus,
body.faq a.faq,
body.cookies a.cookies,
body.tcs a.tcs,
body.work-with-us a.work-with-us,
body.presspage a.presspage,
body.privacy-policy a.privacy-policy,
body.about-us a.about-us {
  color: getColor(txt, lightest);
  background: getColor(info, base);
  border-color: getColor(info, base);
  &:hover {
    background: getColor(info, light);
  }
}
