/// VARIABLES ///

// Breakpoints    : 320, 480, 768, 980, 1200, 1440
$XS               : 320px; // iPhone Retina
$S                : 480px; // iPhone5 Portrait
$M                : 768px; // iPhone5 Landscapes
$L                : 992px; // iPad portrait
$XL               : 1200px; // iPad landscapes
$XXL              : 1440px; // Wide screens

// Fonts
$basic_font       : 'Open Sans', Arial, sans-serif;

// Image path
$assets_path      : "..";
$assets_path_email: "../img";

// TYPOGRAPHY
$h1-color         : #103849;
$h1-size          : 28px;
$h1-weight        : 300;

$h2-color         : #175359;
$h2-size          : 22px;
$h2-weight        : 400;

$h3-color         : #363636;
$h3-size          : 18px;
$h3-weight        : 400;

$h4-color         : #7b7b7b;
$h4-size          : 18px;
$h4-weight        : 300;

$main-text-color  : #1a1919;
$main-text-size   : 16px;
$main-text-font   : 'Open Sans';
$main-text-weight : 400;

$table-text-color : #7b7b7b;
$table-text-size  : 18px;
$table-text-weight: 300;

$help             : $table-text-color;

// Text colors
$txt-style-one    : $h4-color;
$txt-style-two    : $main-text-color;
$txt-style-three  : $h1-color;

// Paragraphs colors
$p-style-one      : #bbb;
$p-style-two      : $main-text-color;
$p-style-three    : #373737;

$small            : $p-style-one;

// COLOR MAPS
$colorSuccess     : #88d06c;
$colorDanger      : #d43601;
$colorInfo        : #2098cc;
$colorWarning     : #fdfae7;
$colorAttention   : #d43500;
$colorText        : #333333;

$colors: (
  border: (
    base: #e8e8e8,
    input: #d2d3d5,
    forDark: #fff
  ),

  bg: (
    light: #fff,
    lightEven: #fafafa,
    dark: #000,
    darkEven: #0f0f0f,
    bgLightInactive: #e6e6e6,
    filtersHeader: #eaeaea,
    filtersCollapsed: #44bde1,
    slider: #2c2c2c,
  ),

  search: (
    bgEven: #e3f2f5,
  ),

  neutral: (
    light: #f3f7f9,
    medium: #515151,
    base: #ccc,
    color: #2d2d2d,
    lighter: #777777, /*#bbb,*/
    bg: #eee,
    bgEven: #f5f5f5,
    bgLight: #fafafa,
    bgDark: #373737,
    focus: #4f4f4f,
    txt: #c8cbd0,
    search: #9f9f9f,
    checkbox: #272727
  ),

  homepage: (
    articleHover: #428bca,
  ),

  txt: (
    base: #333,
    light: #545454,
    lightest: #fff,
    medium: #595959,
    darkest: #000,
    darkBgInfo: #cad1df,
    inputtext: #b1b1b1,
    feedbacktext: #626262
  ),

  error: (
    base: #e64646,
    light: #d76f6f
  ),

  attention: (
    base: #d43500,
    hover: #ab2b00
  ),

  warning: (
    base: #fdfae7
  ),

  info: (
    base          : $colorInfo,
    dark          : #006890,
    medium        : #d4f9ff,
    regular       : #45bde2,
    light         : #33bee2,
    fly           : #09bdf0,
    darker        : #0c394c,
    hover         : #0091c3,
    bgHover       : #fff,
    txt           : #fff,
    txtHover      : $colorInfo,
  ),

  danger: (
    base: $colorDanger,
    light: #ff9b79,
    dark: #bc2f00,
    delete: #e23900,
    hover: #bb3001,
    bgHover: #fff,
    txt: #fff,
    txtHover: $colorDanger,
  ),

  success: (
    base: $colorSuccess,
    light: #ebfbe5,
    medium: #72bf54,
    regular: #c0e7b1,
    success: #62b940,
    hover: #69c445,
    dark: #69c445,
    bgHover: #fff,
    txt: #fff,
    txtHover: $colorSuccess,
  ),

  etc: (
    fbLogin: #d4d8e4,
    fbLoginDark: #3b5998,
    specDestInfo: #e3f4fa,
    link: #2196c9,
    linkHover: #147eac,
  ),

  timeline: (
    base    : #88d06c,
    dark    : #72bf54,
    light   : #c0e7b1,
    lightest: #ebfbe5
  ),

  alert: (
    warning: #fff4c9,
    success: #eafde2,
    error: #e64646
  )
);

// z-index
// $header-zindex        : 5;
$nav_zindex           : 999;

// Email
$footer_email     : getColor(info, base);
