.bonus {

    .jumbotron {
        // .darken-layer {
        //     background-color: rgba(0,0,0,.6);
        // }
    }
}


.jumbotron-sailor {
  	height: auto;
  	background-image: url('#{$assets_path}/img/sailor/headerimg.jpg');
  	padding-bottom: 100px;
  	@include MQ(mL) {

  	}
  	@include MQ(mM) {
    	padding-bottom: 24px;
    	padding-top: 70px;
  	}
  	@include MQ(mS) {

  	}
  	.btn-place {
    	position: relative;
  	}

	.container {
		&.with-titles {
			.title-container {
				@include MQ(mM) {
					max-width: 470px;
				}
				.titles {
					h1 {
						padding-left: 0;
		    			padding-right: 0;
		    			font-size: 60px;
		    			@include MQ(mM) {
		    				font-size: 36px;
		    			}
					}
					h2 {
						margin-bottom: 20px;
						font-weight: 400;
						@include MQ(mM) {
		    				font-size: 22px;
		    			}
					}
					.padded-bottom-lg {
						@include MQ(mM) {
		    				padding-bottom: 5px;
		    			}
					}
					.btn-place {
						@include MQ(mM) {
		    				display: none;
		    			}
						.btn-is-info {
							width: 230px;
							padding: 15px;
						}
					}
				}
			}
		}
	}
}

.choosedestination-form {
	padding-bottom: 40px;
	@include MQ(mM) {
		padding-bottom: 10px;
	}
	.container {
		@include MQ(mM) {
			padding-left: 15px !important;
			padding-right: 15px !important;
		}
	}
	.h1 {
		font-size: 36px;
		color: $h3-color;
		@include MQ(mM) {
			margin-bottom: 0;
		}
	}
	.flatForm {
		.padding-0 {
			padding-left: 0;
			padding-right: 0;
		}
		.form-group {
			/*&:first-child{
				@include MQ(M) {
					margin-bottom: 30px;
				}
			}*/
			margin-bottom: 24px;
			@include MQ(mM) {
				margin-bottom: 8px;
			}
			label.control-label{
				font-size: 18px;
				padding-top: 10px;
			}
			.form-control {
				padding: 12px 15px;
				font-size: 18px;
			}
			.help-inline {
			  min-height: 2px;
			}
		}
		.checkboxlabel {
			padding-top: 0 !important;
			@include MQ(mM) {
				padding-top: 10px !important;
			}
		}
		.cd-check {
			margin-bottom: 0;
    		margin-top: 2px;
    		@include MQ(mM) {
    			margin-bottom: 0;
    			margin-top: 1px;
    		}
    		@include MQ(mS) {
    			width: 100%;
    		}
    		.check-label {
    			color: $h3-color;
    		}
    		.outer {
    			@include MQ(mM) {
    				top: 3px;
    			}
    			.fa {
    				@include MQ(mM) {
    					top: 0;
    				}
    			}
    		}
		}
		.datefield,.timefield {
			@include MQ(mXL) {
				width: 29.16%;
			}
			@include MQ(mL) {
				width: 37.5%;
			}
			@include MQ(mM) {
				width: 100%;
			}
		}
		.timefield {
			@include MQ(mM) {
				margin-top: 15px;
			}
		}
		.cd-select.icon{
			select {
				padding-left: 45px;
				color: getColor(txt, inputtext);
			}
			i {
				font-size: 26px;
				left: 15px;
			}
		}
		.cd-select.noarrow::after{
		  content: '';
		}
		.intl-tel-input {
			.form-control {
				margin-bottom: 0 !important;
			    margin-left: 0;
			    margin-top: 0 !important;
			    padding-left: 60px;
			    position: relative;
			    z-index: 0;
			}
			.selected-flag {
				padding-left: 15px;
				width: 50px;
			}
		}

		.intl-location-input {
			.form-control {
				margin-bottom: 0 !important;
			    margin-left: 0;
			    margin-top: 0 !important;
			    padding-left: 60px;
			    position: relative;
			    z-index: 0;
			}
		}

		.btn-is-info {
			font-size: 18px;
			padding: 18px 16px;
			/*margin-top: 20px;*/
			max-width: 450px;
			width: 100%;
			i{
				display: inline-block;
			    font-size: 28px;
			    height: 20px;
			    margin-right: 3px;
			    position: relative;
			    width: 34px;
			    &:before {
			    	left: 0;
    				position: absolute;
			    }
			}
			@include MQ(mM) {
				margin-top: 40px;
			}
		}
		p{
			/*margin-top: 20px;*/
			font-size: 18px;
			color: getColor(txt, inputtext);
			@include MQ(mM) {
				margin-top: 20px;
				font-size: 14px;
			}
		}
	}
}

.sailor-middle-icons {
	background-image: url('#{$assets_path}/img/sailor/sailor-middle.jpg');
	background-position: center top;
    background-size: cover;
    position: relative;
	min-height: 180px;
	.darken-layer {
		background-color: rgba(0, 0, 0, 0.4);
	}
	#features {
    @include animations(0.1s, 1s, bounceInLeft, forwards);
    color: white;
    position: absolute;
    left: 0;
    top: 40px;
    bottom: 40px;
    padding: 0 15px;
    width: 100%;
    font-weight: 400;

    @include MQ(mL) {
      padding: 0;
    }
    @include MQ(mM) {
     	top: 0;
     	bottom: 0;
     	position: relative;
     	padding-bottom: 10px;
    	padding-top: 10px;
    }
    .container {
    	@include MQ(mM) {
    		max-width: 400px;
    	}
    }
    .item {
      display: block;
      vertical-align: middle;
      height: 100px;
      padding-right: 0;
      margin-right: -15px;
      @include MQ(M) {
        width: 30%;
        &:first-child {
      		padding-left: 0;
      		width: 20%;
      	}
      	&:last-child {
      		width: 20%;
      	}
      }
      @include MQ(L) {
      	margin-right: 0;
      	width: 25%;
      	&:first-child {
      		padding-left: 5%;
      		width: 23%;
      	}
      	&:last-child {
      		width: 20%;
      	}
      }
      @include MQ(mL) {
        height: 85px;
      }

      section {
        display: table;
        height: 100%;
        vertical-align: middle;
        aside {
          font: {
            size: 18px;
            weight: 500;
          }
          height: 100%;
          display: table-cell;
          vertical-align: middle;
          word-wrap: break-word;
          line-height: 20px;
          @include MQ(mL) {
            font-size: 15px;
            line-height: 17px;
          }
          .icon {
            font-size: 62px;
            margin: 0 25px 0 15px;
            @include MQ(mL) {
              font-size: 40px;
              margin: 0 10px;
            }
          }
        }
      }
    }
  }
}

.bestoffers {
	padding-top: 20px;
	@include MQ(mM){
		padding-top: 0;
	}
	.btn-is-info {
		max-width: 450px;
		width: 100%;
		font-size: 18px;
		padding: 18px 16px;
	}
	.h1 {
		font-size: 36px;
		color: $h3-color;
		margin-bottom: 32px;
		@include MQ(mM){
			margin-top: 10px;
			margin-bottom: 10px;
		}
	}
	.boat-related {
		height: 400px;
		max-width: 600px;
		@include MQ(mL){
			height: 375px;
		}
		@include MQ(mM){
			height: auto;
		}
		header {
			img, picture{
				max-height: none;
			}
			border-radius: 0;
		    box-sizing: content-box;
		    margin-left: 0;
		    margin-top: 0;
		    min-height: 0;
		    overflow: hidden;
		    position: relative;
		    width: 100%;
		    display: inline-block;
		    img {
			    height: auto;
			    left: 50%;
			    position: absolute;
			    top: 50%;
			    transform: translate(-50%, -50%);
			    width: 100%;
		    }


			@include MQ(mL) {
				 width: 100%;
			}
			@include MQ(mM) {
			    display: inline-block;
			    width: 155px;
			    height: 170px;
			    vertical-align: top;
		    }
		}
		footer {
			ul {
				li{
					min-height: 30px;
				}
			}
			@include MQ(mL) {
				width: 100%;
				left: 0;
				position: relative;
			}
			@include MQ(mM) {
			    width: calc(100% - 155px);
			    left: 155px;
			    top: 0;
			    position: absolute;
		    }
		}
		ul li{
			&:first-child {
				height: 80px;
				@include MQ(mM) {
					height: 55px;
					min-height: 55px;
				}
				h3{
					vertical-align: top;
					padding-top: 20px;
					@include MQ(mL) {
						font-size: 22px;
					}
					@include MQ(mM) {
						padding-top: 5px;
					}
					@include MQ(mS) {
						font-size: 18px !important;
					}
				}
			}

			&.space-25 {
				padding-left: 15px;
				padding-right: 10px;
				@include MQ(mM) {
					padding-left: 15px;
					padding-right: 10px;
				}
				@include MQ(mS) {
					padding-left: 15px;
				}
			}
			&.space-20 {
				padding-left: 0;
				padding-right: 0;
				@include MQ(mM) {
					padding-left: 5px;
					padding-right: 5px;
				}
				@include MQ(mS) {
					padding-left: 5px;
				}
			}
			aside {
				@include MQ(mM) {
					padding-top: 0 !important;
				}
				.icon-sailogy-location,
				.icon-sailogy-price {
					font-size: 14px;
					@include MQ(mM) {
						font-size: 16px;
					}
				}
			}
			&.price {
				margin-bottom: 20px;
				section {
					.old, .new{
						font-size: 24px;
						@include MQ(mM) {
							font-size: 20px;
							padding-top: 0;
						}
					}
				}
			}
		}
	}
}


.discover-destination-list-box {
	.h1 {
		font-size: 36px;
		color: $h3-color;
		margin-bottom: 32px;
		@include MQ(mM){
			margin-top: 10px;
			margin-bottom: 10px;
		}
	}
	.col {
		@include MQ(mS) {
			width: 100% !important;
		}
	}
	.btn-is-info {
		max-width: 450px;
		width: 100%;
		font-size: 18px;
		padding: 18px 16px;
	}
	.discover-destination-list {
		height: 290px;
		max-width: 600px;
		transition: .1s;
		position: relative;
		background: white;
		overflow: hidden;
		margin: 0 auto 25px;
		@include MQ(mL) {
		  height: 285px;
		}
		&:hover {
		    @include MQ(L) {
		        box-shadow: 0 5px 10px darken(white, 35%);
		    }
		}
		.imgbox {
		    height: 170px;
		    overflow: hidden;
		    position: relative;
		    background: {
		      color: darken(white, 18%);
		      image: url('#{$assets_path}/img/ico/boat-placeholder.png');
		      position: center;
		      repeat: no-repeat;
		    }
		    img, picture {
		      /*min-width: 100%;
		      min-height: 100%;
		      max-height: 100%;*/
		    }
		    @include MQ(mL) {
		      display: inline-block;
		      height: 160px;
		      width: 100%;
		      vertical-align: top;
		    }
		}
		.imgbox {
			border-radius: 0;
		    box-sizing: content-box;
		    margin-left: 0;
		    margin-top: 0;
		    min-height: 0;
		    overflow: hidden;
		    position: relative;
		    width: 100%;
		    display: inline-block;
		    img{
		    	height: auto;
			    left: 50%;
			    position: absolute;
			    top: 50%;
			    transform: translate(-50%, -50%);
			    width: 100%;
			    @include MQ(mM) {
			    	width: 125%;
			    }
		    }
		}
		.imgdetail {
			transition: .2s;
		    position: absolute;
		    background: white;
		    top: 170px;
		    left: 0;
		    width: 100%;
		    text-align: left;
		    min-height: 100%;
		    @include MQ(mL) {
		        top: 160px;
		    }
		    ul {
		      	margin: 0;
		      	li {
			        display: table;
			        width: 100%;
			        color: getColor(txt, inputtext);
			        padding: 5px 0 5px 0;
			        min-height: 40px;
			        font-size: 18px;
			        @include MQ(mM) {
			          	font-size: 16px;
			        }

			        &:first-child {
			         	height: 40px;
			          	min-height: 40px;
			          	padding: 0 0 0 12px;
				        @include MQ(mL) {
				            height: 40px;
				            min-height: 40px;
				        }
				        h3, .h3 {
				            padding: 0;
				            padding-top: 20px;
    						vertical-align: top;
				            font-size: 22px;
				            color: getColor(neutral, bgDark);
				            margin: 0;
				            display: table-cell;
				            vertical-align: middle;
				            @include MQ(mM) {
				              	font-size: 28px;
				            }
				            @include MQ(mS) {
				              	font-size: 16px;
				            }
				        }
			        }
			        &.space-25 {
						padding-left: 25px;
						padding-right: 20px;
						@include MQ(mM) {
							padding-left: 20px;
							padding-right: 10px;
						}
					}
					&.space-20 {
						padding-left: 20px;
						padding-right: 20px;
						@include MQ(mM) {
							padding-left: 15px;
							padding-right: 5px;
						}
					}
		        }
		    }
		}
	}
}


.bottomsection {
	header {
		display: table;
	    height: 100%;
	    vertical-align: middle;
	}
	.left, .right {
		font-size: 18px;
	    font-weight: 500;
	    height: 100%;
	    display: table-cell;
	    vertical-align: middle;
	    word-wrap: break-word;
	    line-height: 20px;
	}
	.left{
		padding-right: 20px;
		i{
			font-size: 70px;
		}
	}
	.h2{
		font-size: 24px;
		color: $colorText;
		text-align: left;
		@include MQ(mM) {
			font-size: 22px;
		}
	}
	p{
		color: $h4-color;
		margin-bottom: 50px;
	}
	.padding-bottom-sm {
		@include MQ(mM) {
			margin-bottom: 40px;
		}
	}
}


.sailor {
	.landing-header {
		height: 75px;
		#logo-landing{
			font-size: 32px;
			@include MQ(mXL) { left: 40px;}
		}
	}
	.h1 {
		@include MQ(mM) {
			font-size: 28px !important;
		}
	}
	.btn-is-info {
		max-width: 450px;
		width: 100%;
		font-size: 18px;
		padding: 18px 16px;
		margin: 0 auto;
		float: none;
	}

	.sailogy-info {
		background-image: url('#{$assets_path}/img/sailor/sailogy-info-boat-bg.jpg');
		.darken-layer {
    		background-color: rgba(0,0,0,.4);
    	}
		.logo{
			&:before {
				position: absolute;
				width: 280%;
				left:-280%;
				content: "";
				background: #d43601;
				height: 63px;
				top:0;
			}
		}
		.h1 {
			font-size: 36px;
			font-weight: 300;
			margin-top: 35px;
			@include MQ(mM) {
				font-size: 28px;
			}
		}

		.h3 {
			font-size: 30px;
			font-weight: 300;
			margin-top: 35px;
			@include MQ(mM) {
				font-size: 22px;
			}
		}
		p {
			font-size: 18px;
			@include MQ(mM) {
				font-size: 18px;
			}
		}

	}
	.footertop{
		background-image: url('#{$assets_path}/img/sailor/footerboatbg.jpg');
		background-position: center 70%;
	    background-size: cover;
	    position: relative;
	    padding: 45px 0 50px;

	    h2{
	    	font-size: 36px;
	    	font-weight: 300;
			color: white;
			text-align: left;
			margin-top: 0;
			margin-bottom: 0;
			@include MQ(mM) {
				font-size: 28px;
			}
	    }

	    p{
	    	font-size: 24px;
	    	line-height: 26px;
	    	color: white;
			margin-bottom: 50px;
			@include MQ(mM) {
				font-size: 18px;
				line-height: 22px;
			}
	    }

	    .btn-is-danger {
	    	display: inline-block;
	    	max-width: 450px;
	    	width: 100%;
	    	font-size: 18px;
	    	padding: 15px 10px;
	    }
	    .container {
	    	@include MQ(mS) {
	    		padding-right: 15px;
	    		padding-left: 15px;
	    	}
	    }
	}

	.landing-footer {
		.bottom-footer {
			padding: 18px 0;
			.circle {
				margin-right: 50px;
				border: 6px solid white;
				height: 60px;
				width: 60px;
				[class^="icon-"] {
					font-size: 26px;
				}
				@include MQ(mM) {
					margin-right: 30px;
				}
			}
			small {
				font-size: 16px;
				@include MQ(mM) {
					font-size: 12px;
				}
			}
			.cd-social-icons {
				a {
					font-size: 41px;
				}
			}
			.row > div {
				@include MQ(mS) {
					padding-bottom: 10px;
    				padding-top: 10px;
    				width: 100%;
    				text-align: center;
				}
			}
		}
	}
}
.sailor {
	.container {
		@include MQ(mXL) {
			padding: 0 50px;
	    	width: 100%;
		}
		@include MQ(mL) {
			padding: 0 40px;
		}
		@include MQ(mM) {
			padding: 0;
		}
		@include MQ(mS) {
			padding: 0;
		}
	}
}
