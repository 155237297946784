body.homepage {
  @include MQ(mM) {
    overflow-x: hidden;
  }
  padding: 0;
  h1, h2, h3, h4, h5, .h2, .h3, .h4, .h5 {
    padding: 0;
    margin: 0;
  }
  h1 {
    font: {
      size: 16px;
      weight: 400;
    }
    color: getColor(txt, base);
    padding-bottom: 10px;
    &.navbar-tagline {
      float: left;
      margin: 0 0 0 28px;
      color: getColor(attention, base);
      font-size: 20px;
      line-height: 60px;
      padding-bottom: 0;
      @include MQ(mL) {
        display: none;
      }
    }
  }
  h2 {
    padding-bottom: 0;
  }
  .navbar-collapse {
    .btn {
      @include MQ(mM) {
        width: 100%;
      }
    }
  }
  .container {
    padding: 0;
  }
  a {
    text-decoration: none;
  }
  .jumbotron {
    background: {
      color: getColor(bg, light);
      repeat: no-repeat;
      size: cover;
      position: top center;
    }
    &:not(.imgix-fluid) {
      background-image: url('#{$assets_path}/img/bg/sailogy_home_video_bg.jpg');
    }
    height: 500px;
    margin: 0;
    padding-top: 65px;
    overflow: hidden;
    @include MQ(mXL) {
      height: 450px;
      padding-top: 40px;
    }
    @include MQ(mL) {
      height: 505px;
      padding-bottom: 0px;
      right: 0;
    }
    @include MQ(mM) {
      background: getColor(bg, light);
      padding-top: 0;
      height: auto;
    }
  }
  #home-video {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
  #search-form {
    background-color: getColor(txt, lightest);
    padding-bottom: 20px;
    text-align: center;
    color: getColor(neutral, color);
    font-size: 16px;
    margin: 0;
    .container {
      margin: 0 auto;
      width: 90%;
      position: relative;
      padding-top: 40px;
    }
    .stamp-container {
      position: absolute;
      top: -30px;
      left: 0;
      width: 100%;
      z-index: 500;
      @include MQ(mM) {
        display: none;
      }
    }
    .stamp {
      background: getColor(attention, base);
      border: 6px solid #e9e9e9;
      border-radius: 100px;
      color: getColor(txt, lightest);
      width: 60px;
      height: 60px;
      margin: 0 auto 10px auto;
      .icon-sailogy-boat {
        color: getColor(txt, lightest);
        font-size: 27px;
        line-height: 44px;
        vertical-align: middle;
      }
    }
    // .cancellation {
    //   .close {
    //     display: none;
    //   }
    // }
    h2 {
      font: {
        size: 16px;
        weight: 400;
      }
      color: getColor(attention, base);
    }
    h3 {
      font: {
        size: 16px;
        weight: 400;
      }
      padding-bottom: 10px;
    }
    .form-group {
      position: relative;
      height: 40px;
      width: 100%;
      overflow: hidden;
      &:first-child {
        overflow: visible;
      }
      input, select {
        border: 1px solid getColor(border, input);
        border-radius: 0;
        height: 40px;
        background: transparent;
        padding-left: 40px;
        line-height: inherit;
        font: {
          size: 16px;
          weight: 300;
        }
        appearance: none;
      }
      i {
        &.left {
          position: absolute;
          pointer-events: none;
          top: 10px;
          font-size: 20px;
          color: lighten(getColor(neutral, medium), 8.5%);
        }
        &.left {
          left: 10px;
        }
      }
    }
    .form-control {
      border: 1px solid getColor(neutral, base);
      box-shadow: inset 0 0 0;

      &:focus {
        border: 1px solid getColor(neutral, focus);
        box-shadow: 0 0 0;
      }
    }
    p.info-duration {
      font: {
        size: 12px;
        weight: 400;
      }
      color: lighten(getColor(neutral, medium), 8.5%);
    }
    button {
      font: {
        size: 20px;
        weight: 600;
      }
      color: getColor(txt, lightest);
      background: getColor(info, base);
      border: none;
      border-radius: 0;
      float: none;
      &:hover {
        background: getColor(info, hover);
      }
    }
    .tt-dropdown-menu {
      border-radius: 0;
      margin-top: 0;
      width: 100%;
    }
    .tt-suggestion {
      p {
        font-size: 16px !important;
        font-weight: 400;
        margin: 0;
        padding: 5px 0;
      }
      &.tt-is-under-cursor {
        p {
          color: getColor(txt, lightest);
        }
      }
    }
    .tt-hint {
      color: getColor(neutral, lighter);
    }
    // .map-trigger {
    //   text-align: center;
    //   margin-top: 10px;
    //   span {
    //     font: {
    //       size: 14px;
    //       weight: 400;
    //     }
    //     color: getColor(attention, base);
    //   }
    //   a {
    //     cursor: pointer;
    //     border: 1px solid getColor(neutral, lighter);
    //     font: {
    //       size: 14px;
    //       weight: 400;
    //     }
    //     color: getColor(attention, base);
    //     display: block;
    //     padding: 5px 10px;
    //     margin: 10px auto 0;
    //     &:hover {
    //       background: getColor(attention, base);
    //       border-color: getColor(attention, base);
    //       color: getColor(txt, lightest);
    //     }
    //     i {
    //       color: getColor(neutral, lighter);
    //       padding: 0 10px;
    //     }
    //   }
    // }
  }
  #facts {
    background: getColor(neutral, bgEven);
    section {
      @include MQ(M) {
        padding-right: 0;
      }
    }
    .row {
      vertical-align: middle;
    }
    article {
      margin: 0;
      display: table;
      height: 90px;
      @include MQ(mL) {
        height: 70px;
      }
      @include MQ(mM) {
        display: block;
        text-align: center;
        height: 115px;
        padding: 13px 0 5px;
      }
      * {
        display: table-cell;
        vertical-align: middle;
      }
    }
    i {
      color: getColor(info, regular);
      font-size: 50px;
      @include MQ(mL) {
        font-size: 46px;
      }
      @include MQ(mM) {
        display: block;
        margin: 0 auto;
        padding: 0 0 5px;
      }
    }
    h3, h4 {
      font: {
        size: 18px;
        weight: 100;
      }
      color: getColor(txt, darkest);
      padding-left: 10px;
      @include MQ(mL) {
        font-size: 16px;
      }
      @include MQ(mM) {
        display: block;
        text-align: center;
        padding: 0;
        font-size: 15px;
      }
    }
  }
  #destinations-list {
    padding: 60px 0 0 0;
    .padded-bottom-lg h2, .padded-bottom-lg h3 {
      text-align: center;
    }
    h2 {
      font: {
        size: 30px;
        weight: 600;
      }
    }
    .left-menu-container {
      @include MQ(mL) {
        display: none;
        height: 0;
      }
    }
  }
  #offers, #destinations {
    padding: 60px 0 0 0;
    h2 {
      color: getColor(txt, base);
      text-align: center;
      padding-bottom: 10px;
      font: {
        size: 30px;
        weight: 600;
      }
      @include MQ(mS) {
        font: {
          size: 26px;
        }
        text-align: center;
        margin: 0 10px;
      }
    }
    h3 {
      font: {
        size: 20px;
        weight: 400;
      }
      color: getColor(txt, base);
      text-align: center;
    }
    article {
      text-align: center;
      margin-top: 50px;
      padding-top: 110px;
      height: 360px;
      position: relative;
      background: getColor(neutral, search);
      text-shadow: 0 1px 2px rgba(black, .3);
      @include MQ(mM) {
        margin: 25px 20px;
      }
      @include MQ(mS) {
        margin: 35px 20px;
        .text {
          top: 40px;
        }
      }
      .text {
        z-index: 2;
        position: absolute;
        top: 100px;
        width: 100%;
      }
      .picture {
        background: getColor(bg, dark);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        overflow: hidden;
        height: 100%;
        img {
          min-width: 100%;
          min-height: 100%;
          // vertical-align: middle;
          transition-duration: .2s;
          opacity: .6;
          position: absolute;
          left: 0;
          transform: translateY(-50%);
          top: 50%;
        }
      }
    }
    h4 {
      font: {
        size: 30px;
        weight: 600;
      }
      color: getColor(txt, lightest);
      padding-bottom: 20px;
      margin: 0 30px;
      text-align: center;
      z-index: 2;
      @include MQ(mXL) {
        font: {
          size: 22px;
          weight: 400;
        }
      }
      @include MQ(mM) {
        font: {
          size: 22px;
          weight: 400;
        }
      }
      @include MQ(mS) {
        margin: 20px 15px 10px 15px;
      }
    }
    h5 {
      font: {
        size: 15px;
        weight: 400;
      }
      color: getColor(txt, lightest);
      margin: 0 30px;
      z-index: 2;
      @include MQ(mL) {
        font: {
          size: 18px;
          weight: 400;
        }
      }
      @include MQ(mS) {
        margin: 10px 15px 10px 15px;
        padding-bottom: 5px;
      }
    }
    .price, .boat {
      background: getColor(info, base);
      font: {
        size: 20px;
        weight: 400;
      }
      color: getColor(txt, lightest);
      text-align: center;
      vertical-align: middle;
      line-height: 20px;
      width: 160px;
      position: absolute;
      bottom: 10px;
      left: -10px;
      padding: 10px 0;
      box-shadow: 0 1px 1px rgba(black, .2);
      b, strong {
        font: {
          size: 20px;
          weight: 600;
        }
        padding-left: 5px;
      }
    }
    a {
      &:hover {
        article {
          .picture {
            background: getColor(homepage, articleHover);
            img {
              opacity: .5;
            }
          }
        }
      }
    }
  }
  #world {
    padding: 60px 0 0 0;
    h2 {
      font: {
        size: 30px;
        weight: 600;
      }
      color: getColor(txt, lightest);
      text-align: center;
      padding: 30px 0 10px 0;
    }
    & > h3 {
      font: {
        size: 20px;
        weight: 400;
      }
      color: getColor(txt, lightest);
      text-align: center;
      padding: 0 0 30px 0;
    }
    & > h2, & > h3 {
      background: getColor(attention, base);
    }
    .container {
      position: relative;
      @include MQ(XXL) {
        width: 100%;
      }
    }
    .geo-offers-container {
      position: absolute;
      top: 0;
      right: 0;
      width: 250px;
      height: 625px;
      background: rgba(black, .7);
      overflow: hidden;
      z-index: 20;
      @include MQ(XXL) {
        right: 40px;
      }
      i {
        padding: 0 10px 0 5px;
        color: getColor(info, regular);
      }
      .geo-offer {
        .discount {
          i {
            padding: 0 10px 0 0;
            font: {
              size: 12px;
              weight: 300;
            }
            line-height: 40px;
            color: getColor(txt, lightest);
          }
        }
      }
    }
    #map {
      background: getColor(info, regular);
      height: 625px;
      width: 100%;
      * {
        transition: none;
      }
    }
    h4 {
      font: {
        size: 18px;
        weight: 400;
      }
      color: getColor(txt, base);
      background: getColor(bg, light);
      padding: 10px 0;
      text-align: center;
    }
    .geo-offer {
      width: 200px;
      background: getColor(bg, light);
      margin: 10px auto;
      padding: 7px;
      position: relative;
      img {
        // height: 95px;
        // width: 180px;
        display: block;
        width: 100%;
      }
      h5 {
        font: {
          size: 14px;
          weight: 400;
        }
        color: getColor(txt, darkest);
        padding-top: 5px;
      }
      .price {
        font: {
          size: 15px;
          weight: 400;
        }
        margin-top: 0;
        color: getColor(info, base);
        b, strong {
          font: {
            size: 16px;
            weight: 600;
          }
          padding-left: 5px;
        }
      }
      .discount {
        font: {
          size: 16px;
          weight: 600;
        }
        line-height: 38px;
        color: getColor(txt, lightest);
        width: 40px;
        height: 40px;
        background: getColor(attention, base);
        text-align: center;
        vertical-align: middle;
        border-radius: 40px;
        border: 1px solid getColor(bg, light);
        position: absolute;
        top: 5px;
        right: 5px;
        padding-left: 5px;
      }
    }
    .geo-offers-container a:hover .geo-offer {
      background: getColor(info, base);
      h5, .price {
        color: getColor(txt, lightest);
      }
    }
    #map-canvas {
      width: 100%;
      height: 630px;
      margin-top: -30px;
    }

    #pac-input {
      padding: 8px 12px;
      margin-top: 16px;
      width: 400px;
      border: 1px solid getColor(border, input);
      border-radius: 0;
      height: 40px;
      background-color: getColor(txt, lightest);
      font: {
        size: 16px;
        weight: 300;
      }
      appearance: none;
      box-shadow: 0 2px 6px rgba(black, .3);
      &:focus {
        border-color: lighten(getColor(info, base), 14%);
        margin-left: -1px;
        padding-left: 13px;
        width: 401px;
      }
    }
  }
  #testimonials {
    background: getColor(bg, light);

    display: block;
    @include MQ(mM) {
      display: none;
    }

    article {
      height: 180px;
      @include MQ(mS) {
        text-align: center;
        height: auto;
        overflow: hidden;
      }

      header {
        float: left;
        @include MQ(mS) {
          float: none;
        }

        overflow: hidden;
        border-radius: 50%;
        border: 6px solid rgba(black, .1);

        width: 150px;

        .testimonial-thumb {
          background-position: center;
          background-repeat: no-repeat;

          margin-left: auto;
          margin-right: auto;

          &#catherine-thumb {
            height: 146px;
            background-image: url('#{$assets_path}/img/catherine.jpg');
            background-size: cover;
          }
          &#robb-thumb {
            height: 93px;
            background-image: url('#{$assets_path}/img/Robb-Report-Logo.png');
            background-size: contain;
          }

        }

        img {
          width: 100%;
          display: block;
        }
      }
      blockquote {
        display: block;
        width: 70%;
        float: right;
        margin: 0;
        padding: 0;
        border: none;
        font-style: normal;
        &:before {
          content: '';
        }
        @include MQ(mXL) {
          width: 60%;
        }
        @include MQ(mL) {
          width: 70%;
        }
        @include MQ(mM) {
          width: 65%;
        }
        @include MQ(mS) {
          width: auto;
        }
        h3, h2 {
          padding: 0 0 10px;
          color: getColor(txt, base);
          padding-bottom: 10px;
          font: {
            size: 30px;
            weight: 600;
            style: normal;
          }
          @include MQ(mL) {
            font-size: 25px;
          }
        }
        p {
          font: {
            size: 18px;
            weight: 400;
            style: normal;
          }
          color: getColor(txt, base);
          padding-bottom: 10px;
          @include MQ(mL) {
            font: {
              size: 16px;
              weight: 300;
            }
          }
          @include MQ(mM) {
            font: {
              size: 16px;
            }
          }
        }
        cite {
          font: {
            size: 14px;
            weight: 300;
          }
          color: getColor(neutral, search);
          padding-bottom: 10px;
        }
      }
    }
  }
  #blog {
    padding: 60px 0 0 0;
    h2 {
      text-align: center;
      font: {
        size: 30px;
        weight: 600;
      }
    }
    .btn {
      margin-bottom: 10px;
    }
  }
}
