// Overwrite global slick classes
.slick-prev:before, .slick-next:before, .slick-prev:focus:before, .slick-next:focus:before {
  opacity: .65;
}
.slick-prev:hover:before, .slick-next:hover:before {
  opacity: 1;
}

// Slick custom carousel
// Hide carousel before initialization
.carousel {
  position: relative;
  &:not(.slick-initialized) {
    img {
      display: block;
      margin: 0 auto;
    }
    > * {
      display: none;
      visibility: hidden;
      &:first-child {
        display: block;
        visibility: visible;
      }
    }
  }
  &.slick-initialized {
    display: block;
  }
}
.cd-slick-carousel {
  &.with-fullscreen {
    position: relative;
    .slick-next {
      right: 200px !important;
      @include MQ(mM) {
        right: 25px !important;
      }
    }
    .slick-counter {
      padding-right: 200px !important;
      @include MQ(mM) {
        padding-right: 0 !important;
      }
    }
    .slick-dots {
      @include MQ(M) {
        width: calc(100% - 175px) !important;
      }
    }
    .slick-slider {
      @include MQ(mM) {
        margin-bottom: 49px !important;
        .slick-prev, .slick-next {
          bottom: -37px;
        }
      }
    }
  }
  a.open-slider {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 15px 15px;
    width: 175px;
    color: getColor(txt, lightest);
    background-color: rgba(getColor(info, base), 0.6);
    border-bottom-color: transparent;
    z-index: 50;
    max-height: 49px;
    .icon-sailogy-fullscreen {
      float: left;
      font-size: 23px;
      margin: -3px 8px -2px 0;
    }
    @include MQ(mM) {
      display: none;
    }
    &:hover {
      background-color: getColor(info, base);
    }
    &.bigger {
      padding: 13px 15px 12px;
      font-size: 18px;
      width: 225px;
      .icon-sailogy-fullscreen {
        float: left;
        font-size: 27px;
        margin: -1px 14px -2px 0;
      }
      @include MQ(mM) {
        display: none;
      }
      &:hover {
        background-color: getColor(info, base);
      }
    }
  }
  .carousel {
    .slick-next, .slick-prev {
      top: auto;
      bottom: 10px;
      z-index: 2;
      width: auto;
      height: auto;

      &:focus {
        &:before {
          opacity: .65;
        }
      }

      &:hover {
        &:before {
          opacity: 1;
        }
      }

      &:before {
        font-family: 'sailogy3';
        color: getColor(txt, lightest);
        font-size: 26px;
        content: '\e659';
        opacity: .65;
      }
    }
    .slick-prev {
      left: 25px;
      transform: rotate(180deg);
    }
    .slick-next {
      right: 25px;
    }
    &.slick-slider {
      margin-bottom: 0;

      img {
        margin-left: auto;
        margin-right: auto;
      }
    }
    .slick-counter {
      position: absolute;
      bottom: 0;
      color: getColor(txt, lightest);
      text-align: center;
      width: 100%;
      max-height: 50px;
      padding: 15px;
      background: rgba(black, .5);
      z-index: 1;
      @include MQ(mM) {
        bottom: -49px;
      }
    }
    .slick-dots {
      background: rgba(black, .5);
      max-height: 49px;
      height: 49px;
      bottom: 0;
      margin: 0;
      @include MQ(mM) {
        bottom: -49px;
      }
      li {
        margin: 15px 5px 0;
      }
      button {
        &:before {
          font-size: 16px;
        }
      }
    }
  }
}

// Carousel for multiple texts
.message-carousel {
  background: getColor(neutral, bg);
  padding: 0 25px;

  .header-carousel {
    padding: 15px 0;
    min-height: 80px;
    position: relative;

    h2 {
      font-weight: 300;
      font-size: 28px;
      color: getColor(info, dark);
      margin: 0;
    }
  }

  .header-message {
    padding: 30px 15px;
    position: relative;

    .user-data {
      float: left;
      margin-left: 20px;

      .time {
        font-size: 16px;
        color: darken(getColor(neutral, lighter), 15%);
      }

      h3 {
        margin: 2px 0 !important;
      }
    }
  }
  .slick-slider {
    margin: 0 -25px;
    background-color: darken(getColor(neutral, bg), 2%);
    padding: 15px 25px;
  }
  .slick-counter {
    font-size: 22px;
    font-weight: 300;
    color: getColor(neutral, medium);
    text-align: center;
    padding: 15px 0;
    background-color: getColor(neutral, bg);
    margin: -15px -25px 0;
  }
  .slick-next, .slick-prev {
    top: 25px;
    z-index: 2;
    width: auto;
    height: auto;
    &:before {
      content: '\e659';
      font-family: 'sailogy3';
      color: getColor(info, dark);
      font-size: 26px;
    }
  }
  .slick-prev {
    left: 25px;
    transform: rotate(180deg);
  }
  .slick-next {
    right: 25px;
  }
  .avarage-rate {
    margin-top: 15px;
    h4 {
      padding-bottom: 5px;
      margin: 0;
    }
  }
  @include MQ(S) {
    .avarage-rate {
      position: absolute;
      right: 0;
      margin-top: 0;
      @include center(y);
      width: auto;
      text-align: right;
    }
  }
  @include MQ(L) {
    .header-message {
      padding: 30px 50px;
      .avarage-rate {
        right: 50px;
      }
    }
    .header-carousel {
      padding: 15px 0 0;
      min-height: 0;
      overflow: visible;
      .avarage-rate {
        top: 70px;
        z-index: 2;
      }
    }
    .slick-prev, .slick-next {
      top: 115px;
      &:before {
        content: '\e659';
      }
    }
    .slick-prev {
      left: 25px;
    }
    .slick-next {
      right: 25px;
    }
    .slick-counter {
      text-align: left;
      width: auto;
      padding: 15px 25px;
    }
  }
}
.cover-slick-carousel {
  max-height: 600px;
  overflow: hidden;
  .slick-slide {
    img {
      width: 100%;
    }
  }
  .slick-counter {
    min-height: 50px;
  }
  .slick-dots {
    li {
      margin: 0 10px;
    }
  }
}
.cd-slick-carousel {
  .slick-slide {
    @include image-block(16, 9, getColor(bg, slider));

    /*.videoWrapper {
      width: 100%;
      @include centerer;
    }*/
  }
}
.popup-slider {
  display: none;
  position: fixed;
  overflow: hidden;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  max-width: 100%;
  height: 100vh;
  z-index: 1550;
  background: getColor(bg, slider);
  .slick-dots {
    position: relative;
    bottom: 140px;
  }
  .slick-track {
    height: 100%;
    max-height: 100%;
    .slick-slide {
      height: 100vh;
      max-height: 100vh;
      opacity: 0.7;
      width: 100vw;
      max-width: 100vw;

      &.slick-active {
        opacity: 1;
      }
      img {
        max-width: 100vw;
        max-height: 95vh;
        margin: 0 auto;
        cursor: pointer;
      }
    }
  }
  .popup-table {
    width: 100%;
    max-width: 100%;
    height: 100%;
    position: relative;
    .popup-cell {
      height: 100%;
      max-height: 100%;
      .popup-content {
        height: 100vh;
        max-height: 100vh;
        overflow: hidden;
        text-align: center;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        max-width: 100%;
        position: relative;
        .popup-content-slider {
          max-width: 100% !important;
          height: 100vh;
          vertical-align: middle;
          .item {
            display: table !important;
            .inside {
              display: table-cell !important;
              vertical-align: middle;

              .video {
                @include centerer;
                .videoWrapper {

                  width: 70vw;
                  height: 70vh;
                  @include MQ(mL) {
                    width: 100vw;
                    max-width: 100vw;
                  }
                }

              }
            }
          }
        }
      }
      .slack-list {
        height: 100% !important;
        .slick-slide {
          img {
            margin: 0 auto;
          }
        }
      }
      .popup-captions, ul.slick-dots {
        position: absolute;
        bottom: 0;
        width: 5000px;
        text-align: left;
        padding: 0;
        left: 0;
        z-index: 555;
        color: white;
        height: 110px;
        list-style-type: none;
        transition: 0.1s;
        @include MQ(mL) {
          height: 70px;
        }
        &:hover {
          li {
            margin-top: 0;
          }
          & + .thumb-caption {
            bottom: 130px;
          }
        }
        & + .thumb-caption {
          transition: 0.2s;
          position: absolute;
          color: white;
          left: 10px;
          bottom: 30px;
          height: 20px;
          text-align: left;
          z-index: 552;
          @include MQ(mL) {
            display: none;
          }
        }
        li {
          transition: 0.2s;
          margin-top: 100px;
          list-style-type: none;
          width: auto;
          height: 100px;
          width: 170px;
          text-align: center;
          @include MQ(mL) {
            margin-top: 0;
            max-height: 70px;
            width: 120px;
          }
          button {
            position: relative;
            height: 100px;
            width: auto;
          }
          img {
            opacity: 0.7;
            max-height: 100px;
            border: 2px solid transparent;
            max-width: 100%;
            @include MQ(mL) {
              max-height: 70px;
            }
            &:hover {
              opacity: 1;
            }
          }
          &.active, &.slick-active {
            img {
              opacity: 1;
              border: 2px solid white;
            }
          }
        }
      }
      button {
        position: absolute;
        top: 0;
        padding: 30px;
        background: transparent;
        font-size: 30px;
        border: 0;
        color: white;
        cursor: pointer;
        z-index: 551;
        &:hover {
          background-color: rgba(black, .85);
        }
        &:focus {
          outline: 0;
        }
        &.slide-close {
          padding: 10px 30px;
          z-index: 552;
          right: 0;
        }
        &.slide-left, &.slick-prev {
          height: 100vh;
          left: 0;
        }
        &.slide-right, &.slick-next {
          height: 100vh;
          right: 0;
        }
        &.slide-left, &.slide-right {
          .fa {
            &:before {
              content: '\e659';
              font-family: 'sailogy3';
            }
          }
        }
        &.slide-left {
          .fa {
            transform: rotate(180deg);
          }
        }
      }
    }
  }
}
.cd-slick-carousel {
  .slick-track {
    // display: table;
    position: relative;
    background: getColor(bg, slider);
  }
  .slick-slide {
    float: none;
    // height: 100%;
    // display: table-cell;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    img {
      max-width: 100%;
      max-height: 100%;
      cursor: pointer;
    }
  }
  .popup-slider {
    .slick-slide {
      float: left;
    }
  }
}
.slick-dots {
  li {
    button {
      &:before {
        color: white !important;
      }
    }
  }
}
.slick-counter {
  transition-duration: 1s;
}
