body.spec-destinations {
  #features {
    @include animations(0.1s, 1s, bounceInLeft, forwards);
    color: white;
    position: absolute;
    left: 0;
    bottom: 0;
    padding: 0 15px;
    width: 100%;
    font-weight: 400;

    @include MQ(mL) {
      padding: 0;
    }
    @include MQ(mM) {
      padding: 0 0 30px 0;
    }

    .item {
      display: block;
      vertical-align: middle;
      height: 100px;
      padding-right: 0;
      margin-right: -15px;
      @include MQ(mL) {
        height: 85px;
      }
      section {
        display: table;
        height: 100%;
        vertical-align: middle;
        aside {
          font: {
            size: 18px;
            weight: 500;
          }
          height: 100%;
          display: table-cell;
          vertical-align: middle;
          word-wrap: break-word;
          line-height: 20px;
          @include MQ(mL) {
            font-size: 15px;
            line-height: 17px;
          }
          .icon {
            font-size: 42px;
            margin: 0 15px;
            @include MQ(mL) {
              font-size: 40px;
              margin: 0 10px;
            }
          }
        }
      }
    }
  }

  #explain-holder {
    background-color: getColor(bg, light);
  }

  #explain {
    display: table;
    section {
      padding-top: 30px;
      padding-bottom: 30px;
      @include MQ(M) {
        display: table-cell;
        float: none;
        padding-top: 45px;
        padding-bottom: 30px;
      }
      .title {
        line-height: 110%;
        display: block;
        text-align: center;
        margin: 20px 0 25px;
        padding: 0;
        color: getColor(info, darker);
        font: {
          size: 28px;
          weight: 100;
        }
      }
      p, a {
        color: getColor(neutral, medium);
      }
      a {
        font-weight: 600;
        display: block;
        font-size: 15px;
        padding: 5px 0;
        &:hover {
          opacity: .85;
        }
      }
      .title-icon {
        text-align: center;
        display: block;
        font-size: 50px;
        margin: 0 auto 10px;
      }
      &:last-child {
        background: getColor(etc, specDestInfo);
      }
    }
  }
}
