// Sticky comparsion list

.compare-list {
  position: fixed;
  right: -1px;
  top: 229px;
  z-index: 255;
  padding: 1px 1px 1px 40px;
  color: getColor(txt, lightest);
  font-size: 14px;
  background-color: getColor(info, base);
  border: 1px solid getColor(neutral, search);

  &, * { transition: none, 0s } // clear all transitions for this blocks

  &.collapsed {
    right: -282px;
    transition: right, 0.2s;
    background-color: getColor(neutral, search);
    .compare-list-body ul { display: none }
  }
  &.expanded {
    // right: -1px;
    transition: right, 0.2s;
  }

  h5 {
    @include rotate(-90deg);
    position: absolute;
    left: -63px;
    top: 67px;
    cursor: pointer;
    white-space: nowrap;
    font-size: 14px;
    line-height: 1;
    i { margin-left: 2px; vertical-align: bottom; }
    color: getColor(txt, lightest);
    height: 15px;
    width: 165px;
    text-align: center;
  }
  .compare-list-body {
    display: block;
    width: 280px;
    min-height: 170px;
    padding: 0 10px 0 12px;
    font-size: 12px;
    border-left: 1px solid getColor(info, regular);
    ul {
      display: block;
      margin: 0;
      padding: 0;
      list-style: none none;
    }
    li {
      display: block;
      position: relative;
      padding: 8px 35px 8px 3px;
      overflow: hidden; 
      line-height: 1.2;
      &:hover {
        background-color: getColor(info, regular);
      }
      a {
        color: getColor(txt, lightest);
      }
      p {
        margin: 0;
        padding: 0;
        font-weight: 600;
        color: getColor(txt, lightest);
      }
      img {
        width: 65px;
        float: left;
        margin: 0 10px 3px 0;
      }
      .close {
        position: absolute;
        right: 7px;
        @include vCenterBlock;
        cursor: pointer;
        font-size: 23px;
        color: getColor(txt, lightest);
        line-height: 1;
        opacity: 1;
        text-shadow: none;
        &:hover { color: getColor(neutral, bgEven) }
      }
    }
    .btn {
      width: 100%;
      margin: 8px 0;
      font-size: 13px;
      color: getColor(neutral, color);
      background-color: getColor(txt, lightest);
      &:hover { background-color: getColor(neutral, bg); }
    }
  }

}
