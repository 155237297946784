body.cabin-charter {
  .jumbotron {
    .icon-main {
      @include MQ(mM) {
        margin-top: 20px;
      }
    }
    #jumbo-right-side {
      .share-list {
        margin-bottom: 0;
      }
      article {
        .h3 {
          padding: 20px 0;
        }
      }
    }
    @include MQ(mM) {
      * {
        text-align: center;
      }
    }

    &:not(.imgix-fluid) {
      background-image: url('#{$assets_path}/img/bg/cabin-charter/xl.jpg');
      @include MQ(mL) {
        background-image: url('#{$assets_path}/img/bg/cabin-charter/l.jpg');
      }
      @include MQ(mM) {
        background-image: url('#{$assets_path}/img/bg/cabin-charter/m.jpg');
      }
      @include MQ(mS) {
        background-image: url('#{$assets_path}/img/bg/cabin-charter/s.jpg');
      }
    }
  }
  section {
    @include MQ(mM) {
      &#filters {
        background: getColor(neutral, bg);
      }
    }
    &.article .container {
      padding: 20px 12px;
      p, ul, ol, table td, table th {
        font-size: 18px;
        line-height: 29px;
        color: #7b7b7b
      }
      ul {
        margin-left: 15px;
        list-style: inside disc;
      }
      ol {
        margin-left: 15px;
        list-style: inside decimal;
      }
    }
  }
}
body.boat-experience {
  .jumbotron {

    background: {
      position: top center;
    }

    &:not(.imgix-fluid) {

      background-image: url('#{$assets_path}/img/bg/boat-experience/xl.jpg');
      @include MQ(mL) {
        background-image: url('#{$assets_path}/img/bg/boat-experience/l.jpg');
      }
      @include MQ(mM) {
        background-image: url('#{$assets_path}/img/bg/boat-experience/m.jpg');
      }
      @include MQ(mS) {
        background-image: url('#{$assets_path}/img/bg/boat-experience/s.jpg');
      }
    }
  }
}
