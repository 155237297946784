.alert {
  border-radius: 0;
  color: getColor(txt, darkest);
  padding: 15px;
  border: 0;
  font-size: 14px;
  > p {
    margin-top: 0;
  }
  button.close {
    font-weight: 200;
  }
  &.bigger {
    padding: 15px;
    font-size: 22px;
  }
  h3 {
    font-size: 22px;
    color: black;
  }
  i {
    font-size: 32px;
    color: getColor(success, dark);
  }
  p {
    font-size: 18px;
  }
  h3, p {
    font-weight: 300;
  }
}
.alert-success {
  background-color: getColor(alert, success);
  color: black;
  p, .close {
    color: lighten(black, 35%);
  }
}
.alert-warning {
  background-color: getColor(alert, warning);
  color: black;
  p, .close {
    color: lighten(black, 35%);
  }
}
.alert-translate {
  // position: relative;
  padding: 15px;
  // margin: 10px 0;
  // font-size: 18px;
  // p {
    // max-width: 80%;
    // margin:0;
    // font-size: 18px !important;
  // }
  //a {
    // position: absolute;
    // right: 15px;
    // @include center(y);
  //}
}
.alert-danger {
  background-color: lighten(getColor(alert, error), 30%);
  color: darken(getColor(alert, error), 20%);
  p, .close {
    color: darken(getColor(alert, error), 20%);
  }
}
