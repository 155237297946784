body.infopage {
  background: getColor(neutral, bgEven);
  .jumbotron {
    &.jumbotron-infopage {
      .container {
        position: relative;
      }
      padding-top: 90px;
      height: 310px;
      background: {
        position: center top;
        size: cover;
      }
      background-image: url('#{$assets_path}/img/bg/infopage/xl.jpg');
      @include MQ(mL) {
        background-image: url('#{$assets_path}/img/bg/infopage/l.jpg');
      }
      @include MQ(mM) {
        background-image: url('#{$assets_path}/img/bg/infopage/m.jpg');
      }
      @include MQ(mS) {
        background-image: url('#{$assets_path}/img/bg/infopage/s.jpg');
      }
      @include MQ(mL) {
        height: auto;
        padding-top: 80px;
      }
      @include MQ(mM) {
        padding-top: 15px;
      }
    }
    h1 {
      color: white;
      line-height: 105%;
      padding-left: 15px;
      @include MQ(mXL) {
        font-size: 50px;
      }
      @include MQ(mL) {
        font-size: 40px;
      }
      @include MQ(mM) {
        font-size: 30px;
      }
    }
  }
  #content {
    background: getColor(neutral, bgEven);
    article {
      background: white;
      margin-top: 0;
      margin-bottom: 0;
      // h1, .h1 {
        // margin-top: 0;
        // margin-bottom: 15px;
      // }
      section {
        // padding: 10px 15px;
        margin-bottom: 10px;
      }
    }
  }
}
