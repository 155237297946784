[role=tabpanel] > .nav {
  &.nav-justified {
    li {
      a {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
  & > li {
    margin-bottom: -1px;
    padding: 0;
    background-color: getColor(neutral, bg);
    vertical-align: middle;
    a {
      display: block;
      border: 0 !important;
      border-radius: 0 !important;
      margin-right: 0;
      color: getColor(txt, dark);
      font-size: 21px;
      @include MQ(mL) {
        font-size: 18px;
        padding: 7px 0;
      }
      &:hover {
        color: getColor(txt, lightest);
        background-color: getColor(info, base);
      }
    }
    i { color: getColor(txt, dark); }
    &.active i, &.active:hover i {
      color: getColor(txt, lightest);
    }
    &.active a {
      color: getColor(txt, lightest);
      background: getColor(info, dark);
      &:hover {
        background: getColor(info, base);
      }
    }
  }
}
.details-tab {
  border-bottom: 0;
  > li {
    text-align: center;
    font-size: 24px;
    margin-bottom: 0;
    background-color: getColor(neutral, bg);
    color: getColor(info, base);
    h2 {
      margin: 0;
      display: inline-block;
      width: 100%;
      vertical-align: middle;
    }
    a {
      font-size: 16px;
      padding: 20px 10px;
      background: transparent;
      color: getColor(info, base);
      border-color: transparent !important;
      &:hover {
        border-color: transparent !important;
        color: getColor(txt, lightest);
        background-color: getColor(info, base);
      }
    }
    &:hover {
      background-color: getColor(info, base);
    }
    &.active {
      box-shadow: 0 0 0;
      border-color: transparent !important;
      background-color: getColor(bg, light);
      a {
        border-color: transparent !important;
        border-radius: 0;
        &:hover {
          color: getColor(info, base);
          background-color: getColor(bg, light);
        }
      }
    }
  }
}
.details-tab-content {
  padding: 15px 0;
  @include clearfix;
  .tab-pane {
    @include clearfix;
  }
  p {
    margin: 10px 0;
    font-size: 16px;
  }
  h5 {
    font-size: 16px;
    font-weight: 400;
    margin: 10px 0;
  }
  .bottom-btn {
    margin-top: 15px;
  }
}
.navbar-collapse {
  border-top: 0;
}
[role=tabpanel] {
  overflow: hidden;
  & > .nav.dark-tabs { 
    overflow: hidden;
    li {
      white-space: nowrap;
      background: getColor(bg, dark);
      a {
        transition-duration: .1s;
        color: getColor(txt, lightest);
        margin: 0;
        line-height: 28px;
        padding: 10px 0;
        &:focus {
          background: getColor(info, dark);
          color: getColor(txt, lightest);
        }
      }
      &.active {
        background: (getColor(info, base), .75);
      }
    }
  }
}
